<template>
  <v-hover
    v-slot="{ hover }"
    close-delay="100"
  >
    <tr :class="item_class(item, hover)">
    <template>
      <td
        v-for="header in headers"
        :key="`${header.value} ${new Date()}`"
        :set="(value = item_value(item, header.value))"
      >
        <template v-if="header.currency">
          <div class="d-flex justify-end">
            {{ ( parseFloat(value) || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") }}
          </div>
        </template>

        <template v-else-if="header.enum">
          {{ header.enum[String(value)] || value }}
        </template>

        <template v-else-if="header.value == 'tool'">
          <slot></slot>
        </template>

        <template v-else>{{ value }}</template>
      </td>
    </template>
  </tr>
  </v-hover>

</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array,
      default: () => [],
    },
    item_class: {
      type: Function,
      default: () => () => '',
    }
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    item_value(item, header) {
      const arr = header.split('.')
      let value = ''
      if (arr.length>2) value = header.split('.').reduce((o, i) => o[i], item)
      else value = item[header]

      return value
    }
  },
  computed: {},
};
</script>

<style>
</style>
