<template>
  <div>
    <div class="d-flex justify-end pt-2" @click="() => { if(!disable) isMenu = true }">
      สิทธิ์การใช้งาน
      <v-spacer></v-spacer>
      <v-icon v-if="!disabled">mdi-plus</v-icon>
    </div>
    <v-sheet rounded class="grey lighten-4" min-height="37">
        <div v-for="(v, k) in permissions" :key="k">
          <div>{{ $t(`permission.${k}`) }}</div>
          <v-chip-group column class="px-2">
            <v-chip v-for="id in v" :key="`show-${id}`"
              color="primary"
            >
              <v-icon v-if="tmp.tmpPermissions[id].type === 'menu'">mdi-blur</v-icon>
              {{ tmp.tmpPermissions[id].description }}
            </v-chip>
          </v-chip-group>
        </div>
    </v-sheet>
    <v-dialog v-model="isMenu" :close-on-content-click="false" width="80%" max-height="80%">
      <v-card>
        <v-card-title>สิทธิ์การใช้งาน</v-card-title>
        <v-card-text>
          <div class="box" v-for="(v, k) in tmp.tmpGroups" :key="k">
            <div>{{ $t(`permission.${k}`) }}</div>
            <v-chip-group column class="px-2">
              <v-chip v-for="id in v" :key="`input-${id}`" @click="toggle(k, String(id))"
                :color="permissionIds.includes(String(id)) ? 'primary' : 'gray lighten-5'"
              >
                <v-icon v-if="tmp.tmpPermissions[id].type === 'menu'">mdi-blur</v-icon>
                {{ tmp.tmpPermissions[id].description }}
              </v-chip>
            </v-chip-group>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import tmp from '../../configs/permissions.js'
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tmp: tmp,
      isMenu: false,
      permissions: {},
      permissionIds: []
    }
  },
  mounted() {

  },
  methods: {
    toggle(group, id) {
      if (!this.permissions[group]) this.$set(this.permissions, group, [])

      if (this.permissions[group].indexOf(id) === -1) {
        this.permissionIds.push(id)
        this.$set(this.permissions, group, this.tmp.tmpGroups[group].filter(v => this.permissionIds.includes(String(v))).map(v => String(v)))
      } else {
        this.permissions[group].splice(this.permissions[group].indexOf(id), 1)
        if (this.permissions[group].length === 0) {
          delete this.permissions[group]
        }
        this.permissionIds.splice(this.permissionIds.indexOf(id), 1)
      }

      this.$emit('change', Object.keys(this.permissions).map(k => `${k}:${this.permissions[k].join(',')}`))
    }
  },
  watch: {
    value() {
      const p = this.value.reduce((r, n) => {
        r.obj[n.split(':')[0]] = n.split(':')[1].split(',')
        r.arr.push(...n.split(':')[1].split(','))
        return r
      }, {obj:{}, arr:[]})
      this.permissions = p.obj
      this.permissionIds = p.arr
    }
  }
}
</script>

<style scope>
.box {
  padding: 10px;
  border: 1px solid #000;
}
</style>
