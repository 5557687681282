import { call } from '../plugins/axios'
export default {
  namespaced: true,
  state: () => ({
    statements: [],
    statementCount: 0
  }),
  getters: {
    statements: (state) => state.statements,
    statementCount: (state) => state.statementCount
  },
  mutations: {
    setStatements: (state, value) => state.statements = value,
    setStatementCount: (state, value) => state.statementCount = value
  },
  actions: {
    getStatements: async ({ commit, dispatch}, data) => {
      return await call('statement/list', data)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else {
            if (data.rows) commit('setStatements', data.rows)
            if (data.count) commit('setStatementCount', data.count)
          }

          return data
        })
        .catch(() => { })
    },
    setComment: async ({ dispatch }, data) => {
      return await call('statement/set-comment', data)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })

          return data
        })
    }
  }
}
