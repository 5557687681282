var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{staticClass:"flex-grow-1",attrs:{"title":_vm.$t(_vm.waiting ? 'deposit.waiting' : 'deposit.text_all'),"breadcrumbs":_vm.breadcrumbs,"loading":_vm.isLoading,"headers":_vm.headers,"options":_vm.options,"items":_vm.deposits,"total":_vm.total,"filter":_vm.filter},on:{"update:options":[function($event){_vm.options=$event},_vm.onSearch]},scopedSlots:_vm._u([{key:"item.m.ufa_user",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'?user=' + item.m.ufa_user || ''}},[_vm._v(_vm._s(item.m.ufa_user || ""))])]}},{key:"item.b.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.b.name || "")+" ")]}},{key:"item.d.amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(item.d.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") || "0.00")+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(!item.d.active)?_c('v-chip',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.$t("deposit.status_cancel")))]):(item.d.complete)?_c('v-chip',{attrs:{"color":"green"}},[_vm._v(_vm._s(_vm.$t("deposit.status_success")))]):(item.d.confirm && !item.complete)?_c('v-chip',{attrs:{"color":"yellow"}},[_vm._v(_vm._s(_vm.$t("deposit.status_waiting_checking")))]):_c('v-chip',[_vm._v(_vm._s(_vm.$t("deposit.status_waiting")))])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.comment)+" - "+_vm._s(item.description)+" ")]}},{key:"item.d.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date(item.d.created_at) || "-")+" ")]}},{key:"item.d.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date(item.d.updated_at) || "-")+" ")]}},{key:"item.tool",fn:function(ref){
var item = ref.item;
return [_c('group-tool',{attrs:{"value":item.d},on:{"change":_vm.onSearch}})]}},{key:"outside",fn:function(){return [_c('div',{staticClass:"text-center mt-2"},[_vm._v(" ** รายการฝากจะแสดงเมื่อครบกำหนด 1 นาทีหลังจากลูกค้าทำการแจ้งฝาก **"),_c('br'),_vm._v(" ** รายการฝากประเภท \"ฝากด่วน\" admin ไม่สามารถแก้ไขได้ **"),_c('br')])]},proxy:true}],null,true)},[_c('template',{slot:"btn-right"}),_c('template',{slot:"filters"},[_c('v-text-field',{staticClass:"mx-1",attrs:{"label":_vm.$t('member.ufa_user')},model:{value:(_vm.filter['m.ufa_user']),callback:function ($$v) {_vm.$set(_vm.filter, 'm.ufa_user', $$v)},expression:"filter['m.ufa_user']"}}),_c('v-text-field',{staticClass:"mx-1",attrs:{"type":"number","label":_vm.$t('deposit.amount')},model:{value:(_vm.filter['d.amount']),callback:function ($$v) {_vm.$set(_vm.filter, 'd.amount', $$v)},expression:"filter['d.amount']"}}),_c('v-text-field',{staticClass:"mx-1",attrs:{"label":_vm.$t('bank.text')},model:{value:(_vm.filter['b.name']),callback:function ($$v) {_vm.$set(_vm.filter, 'b.name', $$v)},expression:"filter['b.name']"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }