export default {
  required: () => { return value => value===0 || !!value || 'Required.' },
  counter: () => { return value => value.length <= 20 || 'Max 20 characters' },
  email: () => { return value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'Invalid e-mail.'
  }},
  integer: (min=Number.MIN_SAFE_INTEGER, max=Number.MAX_SAFE_INTEGER) => {
    return value => [
      Number.isInteger(parseInt(String(value))) || 'Invalid',
      value >= min || 'Min '+min,
      value <= max || 'Max '+max
   ].find(r => typeof r === 'string') || true
  },
  number: (min=Number.MIN_SAFE_INTEGER, max=Number.MAX_SAFE_INTEGER) => {
    return value => [
      !isNaN(value) || 'Invalid',
      value >= min || 'Min '+min,
      value <= max || 'Max '+max
   ].find(r => typeof r === 'string') || true
  },
  text: (min=0, max=false) => { return value => {
    value = String(value)
    if (min && value.trim().length < min) return 'Text min ' + min
    if (max && value.trim().length > max) return 'Text max ' + max
    return true
  }},
  nullable: (next) => { return value => {
    value = String(value)
    if (value===null || value.length === 0) return true
    if (typeof next === 'function') return next(value)
    return true
  }},
  numberText: (min=0, max) => { return value => {
    value = String(value)
    if (min && value.trim().length < min) return 'Text min ' + min
    if (max && value.trim().length > max) return 'Text max ' + max
    // eslint-disable-next-line
    if (!value.match(/^([0-9]+\@*)$/g)) return 'Text invalid '
    return true
  }},
  password: (min=0, max) => { return value => {
    value = String(value)
    if (!value.match(/[a-zA-Z0-9]+/g)) return 'Text support a-z A-Z 0-9'
    if (min && value.length < min) return 'Text min ' + min
    if (max && value.length > max) return 'Text max ' + min
    return true
  }}
}
