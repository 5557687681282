<template>
  <v-card>
    <v-card-title> {{ $t(`common.${action == 'create'? 'create': 'edit'}`) }} {{ $t('bank.text') }} </v-card-title>
    <v-card-text>
      <v-form ref="form" :disabled="isLoading">
        <input-or-text
         v-model="bank.bank_name"
          :items="bank_options"
          :label="$t('bank.bank_name')"
          :error-message="errors.bank_name"
          :rules="rules.bank_name"
          v-bind="input_attrs"
        />
        <input-or-text
         v-model="bank.name"
          :label="$t('bank.name')"
          :error-messages="errors.name"
          :rules="rules.name"
          v-bind="input_attrs"
        />
        <input-or-text
         v-model="bank.number"
          :label="$t('bank.number')"
          :error-messages="errors.number"
          :rules="rules.number"
          v-bind="input_attrs"
        />
        <input-or-text
         v-model="bank.amount_maximum"
          :label="$t('bank.amount_maximum')"
          :error-messages="errors.amount_maximum"
          :rules="rules.amount_maximum"
          v-bind="input_attrs"
        />
        <v-checkbox
         v-model="bank.withdraw_only"
          :label="$t('bank.withdraw_only')"
          :true-value="1"
          :false-value="0"
        ></v-checkbox>
        <v-checkbox
         v-model="bank.active"
          :label="$t('bank.active')"
          :true-value="1"
          :false-value="0"
        ></v-checkbox>
      </v-form>
      <div v-if="canUpdate" class="d-flex justify-end">
        <v-btn color="primary" @click="submit" :loading="isLoading"> submit </v-btn>
      </div>

      {{ action }}
    </v-card-text>
  </v-card>
</template>

<script>
import InputOrText from "../../components/common/InputOrText.vue";
import bank_options from "../../configs/banks"
import validate from "../../helper/validates"
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    InputOrText,
  },
  props: {
    value: {
      type: [Object],
      default: () => null,
    },
    action: {
      type: String,
      default: "create",
    }
  },
  data() {
    return {
      isLoading: false,
      input_attrs: {
        outlined: true,
        dense: true,
        input: true,
      },
      bank: {},
      errors: {},
      bank_options: bank_options,
      rules: {
        bank_name: [
          validate.required(),
        ],
        name: [
          validate.required(),
        ],
        number: [
          validate.required(),
        ],
        amount_maximum: [
          validate.required(),
        ],
      },
    };
  },
  created() {},
  mounted() {
    //this.reset()
  },
  methods: {
    ...mapActions('bank', ['create_bank', 'update_bank']),
    async submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true

        const action = this.action == 'create' ? this.create_bank(this.bank) : this.update_bank(this.bank)

        await action
          .then((data)=>{
            if (data.error) this.errors = data.errors
            else {
              this.$emit('close', true)
            }
          })
        this.isLoading = false
      }
    }
  },
  computed: {
    ...mapGetters('user', ['profile']),
    canUpdate () {
      return this.profile.chkAction(['bank_action_all', 'bank_action_update'])
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        const def = {
          bank_name: "",
          name: "",
          number: "",
          amount_maximum: "",
          withdraw_only: 0,
          active: 0,
        }
        this.$set(this, 'bank', value == null? def : value)
      },
    }
  }
};
</script>

<style>
</style>
