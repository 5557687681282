var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"title":_vm.$t(("withdraw.waiting_" + _vm.waiting)),"breadcrumbs":_vm.breadcrumbs,"loading":_vm.isLoading,"headers":_vm.headers,"options":_vm.options,"items":_vm.withdraws,"total":_vm.total,"filter":_vm.filter},on:{"update:options":[function($event){_vm.options=$event},_vm.onSearch]},scopedSlots:_vm._u([{key:"item.member.ufa_user",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'?user=' + item.member.ufa_user}},[_vm._v(_vm._s(item.member.ufa_user))])]}},{key:"item.member.bank_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.member.bank_name)+" ")]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fee || 0)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(item.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") || "0.00")+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount - item.fee)+" ")]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.comment || "-")+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.complete)?_c('v-chip',{attrs:{"color":"green"}},[_vm._v(_vm._s(_vm.$t("withdraw.status_success")))]):(item.confirm && !item.complete)?_c('v-chip',{attrs:{"color":"yellow"}},[_vm._v(_vm._s(_vm.$t("withdraw.status_waiting_tranfer")))]):(!item.active)?_c('v-chip',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.$t("withdraw.status_cancel")))]):_c('v-chip',[_vm._v(_vm._s(_vm.$t("withdraw.status_waiting")))])]}},{key:"item.tool",fn:function(ref){
var item = ref.item;
return [_c('group-tool',{attrs:{"value":item,"waiting":_vm.waiting},on:{"change":_vm.onSearch}})]}}],null,true)},[_c('template',{slot:"btn-right"}),_c('template',{slot:"filters"},[_c('v-text-field',{staticClass:"mx-1",attrs:{"label":_vm.$t('member.ufa_user')},model:{value:(_vm.filter['member.ufa_user']),callback:function ($$v) {_vm.$set(_vm.filter, 'member.ufa_user', $$v)},expression:"filter['member.ufa_user']"}}),_c('v-text-field',{staticClass:"mx-1",attrs:{"type":"number","label":_vm.$t('withdraw.amount')},model:{value:(_vm.filter['withdrawals.amount']),callback:function ($$v) {_vm.$set(_vm.filter, 'withdrawals.amount', $$v)},expression:"filter['withdrawals.amount']"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }