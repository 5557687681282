<template>
  <table-page
    :title="$t('lottery.text')"
    :breadcrumbs="breadcrumbs"
    :loading="isLoading"
    :headers="headers"
    :items="list"
    :total="total"
    :options.sync="options"
    :filter="filter"
    @update:options="onSearch"
  >
    <v-dialog v-model="dialog" width="70%">
      <lottery-page :actionType="dialogType" @success="closeAndReload()"></lottery-page>
    </v-dialog>
    <template v-slot:[`item.pending`]="{ item }">
      <span v-if="item.pending">กำลังดำเนินการ</span>
      <span v-else-if="!item.active">เสร็จสิ้น</span>
      <span v-else>รอ</span>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-btn @click="dialogShow(item)" icon>
        <v-icon>mdi-eye</v-icon>
      </v-btn>
      <v-btn v-if="canUpdate(item)" @click="dialogUpdate(item)" icon>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
  </table-page>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import LotteryPage from './LotteryPage';
export default {
  components: {
    LotteryPage,
  },
  data () {
    return {
      isLoading: false,
      breadcrumbs: [{
        text: this.$t('lottery.text')
      }, {
        text: this.$t('common.list')
      }],
      options: {},
      filter: {},
      headers: [
        { text: 'ชื่อ', value: 'name' },
        { text: 'ราคา', value: 'price' },
        { text: 'รางวัล', value: 'reword' },
        { text: 'เริ่มต้น', value: 'start_at', type: 'date' },
        { text: 'สิ้นสุด', value: 'exp_at', type: 'date' },
        { text: 'สร้างเมื่อ',  value: 'created_at', type: 'date' },
        { text: 'สถานะ', value: 'pending' },
        { text: 'จัดการ', value: 'action' }
      ],
      dialog: false,
      dialogType: 'show'
    }
  },
  methods: {
    ...mapActions('event_lottery', ['getList',  'setCurrent']),
    async onSearch () {
      this.isLoading = true
      this.getList({...this.options, sortBy:['id'], sortDesc: [true]});
      this.isLoading = false
    },
    dialogShow (item) {
      this.setCurrent(item);
      this.dialogType = 'show';
      this.dialog = true;
    },
    dialogUpdate (item) {
      this.setCurrent(item);
      this.dialogType = 'update';
      this.dialog = true;
    },
    canUpdate (item) {
      return item.active && this.profile.chkAction('lottery_action_update');
    }
  },
  computed: {
    ...mapGetters('event_lottery', ['list', 'total']),
    ...mapGetters('user', ['profile']),
  }
}
</script>
