
import locales from './locales'
import theme from './theme'

export default {
  product: {
    name: '-',
    version: '-'
  },

  locales,
  theme,
}
