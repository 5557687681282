<template>
  <v-dialog v-model="dialog" :width="isLoading ? '300px' : '70%'">
    <v-card v-if="isLoading">
      <v-card-title class="text-h5 grey lighten-2">
        {{ $t("deposit.text") }}
      </v-card-title>
      <v-card-text class="d-flex justify-center py-2" style="height: 100px">
        <v-progress-circular :size="70" :width="7" color="warning" indeterminate
          >loading</v-progress-circular
        >
      </v-card-text>
    </v-card>

    <v-card v-else>
      <v-card-title class="text-h5" :class="current.active ? 'grey lighten-2' : 'red lighten-4'">
        {{ $t("deposit.text") }}
        <v-spacer></v-spacer>
        <div class="d-flex flex-column">
          <div style="font-size: 0.7em">
            {{ $t("deposit.created_at") }}
            <span style="font-size: 1em">
              {{ $date(current.created_at) }}
            </span>
          </div>
          <div style="font-size: 0.7em">
            {{ $t("deposit.updated_at") }}
            <span style="font-size: 1em">
              {{ $date(current.updated_at) }}
            </span>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="row">
          <div v-if="current.image" class="col-12 col-md-3">
            <a v-if="current.image" :href="current.image" class="my-2" target="block">
            <v-img
              :src="current.image"
              max-height="350px"
              contain
              lazy-src="https://picsum.photos/id/11/10/6"
            />
            </a>
          </div>
          <div class="col">
            <div class="row mt-2">
              <div class="col-12 col-md-6 py-0">
                <input-or-text
                  :value="current.type || ''"
                  :label="$t('deposit.type')"
                />
              </div>
              <div class="col-12 col-md-3 py-0">
                <input-or-text
                  :value="current.id || ''"
                  :label="$t('deposit.id')"
                />
              </div>
              <div class="col-12 col-md-3 py-0">
                <input-or-text
                  :value="$date(current.locked_at)"
                  :label="$t('deposit.lockedAt')"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-7 py-0">
                <input-or-text
                  :value="current.member ? current.member.ufa_user || '' : ''"
                  :label="$t('member.ufa_user')"
                />
              </div>
              <div class="col-12 col-md-5 py-0">
                <input-or-text
                  :value="current.member ? current.member.tel || '' : ''"
                  :label="$t('member.tel')"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-3 py-0">
                <input-or-text
                  :value="memberBankText"
                  :label="$t('bank.text')"
                />
              </div>
              <div class="col-12 col-md-3 py-0">
                <input-or-text
                  :value="bankText"
                  :label="$t('deposit.deposit_bank')"
                />
              </div>
              <div class="col-12 col-md-3 py-0">
                <input-or-text
                  :value="current.amount || ''"
                  :label="$t('deposit.amount')"
                  text_class="red--text"
                />
              </div>
              <div class="col-12 col-md-3 py-0 d-flex align-center">
                <input-or-text
                  :label="$t('common.date')"
                  :value="`${current.date || ''} ${current.time || ''}`"
                  class="flex-grow-1 mr-2"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-2 py-0">
                <input-or-text
                  :label="$t('deposit.confirm')"
                  :value="current.confirm || 0"
                />
              </div>
              <div class="col-12 col-md-4 py-0">
                <input-or-text
                  :label="$t('deposit.confirmBy')"
                  :value="current.confirmBy ? current.confirmBy.name || '' : ''"
                />
              </div>

              <div class="col-12 col-md-2 py-0">
                <input-or-text
                  :label="$t('deposit.complete')"
                  :value="current.complete || 0"
                />
              </div>
              <div class="col-12 col-md-4 py-0">
                <input-or-text
                  :label="$t('deposit.completeBy')"
                  :value="
                    current.completeBy ? current.completeBy.name || '' : ''
                  "
                />
              </div>
            </div>

            <div v-if="current.complete" class="row">
              <div class="col-12 col-md-3 py-0">
                <input-or-text
                  :label="$t('deposit.before')"
                  :value="current.before || 0"
                />
              </div>
              <div class="col-12 col-md-9 py-0">
                <input-or-text
                  :label="$t('deposit.after')"
                  :value="current.after || 0"
                />
              </div>
            </div>

            <input-or-text
              :label="$t('deposit.comment')"
              :value-height="70"
              :value="
                (current.comment || '') + ' ' + (current.description || '')
              "
              class="my-4"
            />

            <div
              v-if="is_edit && canAction && !current.complete"
              class="my-2 d-flex flex-column flex-md-row justify-end"
            >
              <div v-if="canSetCancel && current.active" class="my-1 mx-1">
                <button-cancel
                  :action="cancelDeposit"
                  :disabled="!current.active || actionLoading"
                  :value="current.id"

                  @submit="afterCancel"
                />
              </div>
              <v-btn
                v-if="canSetActive && !current.active"
                :disabled="!!current.active || actionLoading"
                :loading="loading.active"
                @click="active"
                class="my-1 mx-1"
                >{{ $t("deposit.active") }}</v-btn
              >
              <v-btn
                v-if="canSetUpload"
                :disabled="
                  !!current.active || !!current.can_upload || actionLoading
                "
                :loading="loading.canUpload"
                class="my-1 mx-1"
                @click="canUpload"
                >{{ $t("deposit.can_upload") }}</v-btn
              >

              <v-btn
                v-if="canSetComplete"
                @click="complete"
                :loading="loading.complete"
                :disabled="!!current.complete || actionLoading"
                color="green"
                class="my-1 mx-1"
                >{{ $t("deposit.complete") }}</v-btn
              >
            </div>
          </div>
        </div>
        <div class="d-flex" style="overflow-x:scroll">
        <v-card v-for="slip in current.oldSlip" :key="`slip${slip.id}`">
          <v-img :src="slip.image" max-width="150px"></v-img>
          <v-card-text>
            <div>{{ slip.comment }}</div>
            <div class="text-center">{{ thaiDate(slip.created_at) }}</div>
          </v-card-text>
        </v-card>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ButtonCancel from "../common/ButtonCancel.vue";
import InputOrText from "../common/InputOrText.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    InputOrText,
    ButtonCancel,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    deposit_id: {
      type: Number,
      default: 0,
    },
    is_edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      loading: {
        active: false,
        canUpload: false,
      },
      isChange: false,

    };
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions("deposit", [
      "getDeposit",
      "clearCurrent",
      "completeDeposit",
      "cancelDeposit",
      "activeDeposit",
      "canUploadDeposit",
    ]),
    thaiDate(date){
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    async complete() {
      if (window.confirm(this.$t("message.deposit_confirm"))) {
        this.loading.complete = true;
        await this.completeDeposit(this.deposit_id).then((data) => {
          if (!data.error) {
            this.isChange = true;
            this.dialog = false;
          }
        });
        this.loading.complete = false;
      }
    },
    async afterCancel() {
      this.isChange = true;
      this.dialog = false;
    },
    async active() {
      this.loading.active = true;
      await this.activeDeposit(this.deposit_id).then((data) => {
        if (!data.error) this.isChange = true;
      });
      this.loading.active = false;
    },
    async canUpload() {
      this.loading.canUpload = true;
      await this.canUploadDeposit(this.deposit_id).then((data) => {
        if (!data.error) this.isChange = true;
      });
      this.loading.canUpload = false;
    },
  },
  computed: {
    ...mapGetters("deposit", ["current"]),
    ...mapGetters("user", ["profile"]),
    canAction(){
      console.log(this.canSetActive , this.canSetCancel , this.canSetUpload , this.canSetComplete)
      return this.canSetActive || this.canSetCancel || this.canSetUpload || this.canSetComplete;
    },
    canSetActive() {
      if (!this.profile) return false;
      return this.profile.chkAction([
        "deposit_action_all",
        "deposit_action_set_active",
      ]);
    },
    canSetCancel() {
      if (!this.profile) return false;
      return this.profile.chkAction([
        "deposit_action_all",
        "deposit_action_set_cancel",
      ]);
    },
    canSetUpload() {
      if (!this.profile) return false;
      return this.profile.chkAction([
        "deposit_action_all",
        "deposit_action_set_can_upload",
      ]);
    },
    canSetComplete() {
      if (!this.profile) return false;
      return this.profile.chkAction([
        "deposit_action_all",
        "deposit_action_set_complete",
      ]);
    },
    memberBankText() {
      if (!this.current.member) return "";
      return `${this.current.member.bank_name || ""} ${
        this.current.member.bank_number || ""
      }`;
    },
    bankText() {
      if (!this.current.bank) return "";
      return `${this.current.bank.name || ""} ${
        this.current.bank.number || ""
      }`;
    },
    actionLoading() {
      return Object.keys(this.loading).findIndex((k) => this.loading[k]) !== -1;
    },
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (value) this.isChange = false;
        if(!value && this.isChange) this.$emit("current_change");
        this.$emit("input", value);
      },
    },
  },
  watch: {
    async value(val) {
      if (val && this.deposit_id !== 0) {
        this.isLoading = true;
        await this.getDeposit(this.deposit_id);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>
