export default {
  "tmpPermissions": {
    "1": {
      "id": 1,
      "key": "menu_member",
      "parent_id": 29,
      "description": "เห็นเมนูสมาชิก",
      "role": "all",
      "type": "menu",
      "group": "member",
      "order": null
    },
    "2": {
      "id": 2,
      "key": "menu_deposit_waiting",
      "parent_id": 24,
      "description": "เห็นเมนูฝาก ( รอ )",
      "role": "all",
      "type": "menu",
      "group": "deposit",
      "order": null
    },
    "3": {
      "id": 3,
      "key": "menu_deposit_all",
      "parent_id": 24,
      "description": "เห็นเมนูฝาก ( ทั้งหมด )",
      "role": "all",
      "type": "menu",
      "group": "deposit",
      "order": null
    },
    "4": {
      "id": 4,
      "key": "menu_withdraw_confirm_waiting",
      "parent_id": 42,
      "description": "เห็นเมนูถอน ( รอตรวจสอบ )",
      "role": "all",
      "type": "menu",
      "group": "withdraw",
      "order": null
    },
    "5": {
      "id": 5,
      "key": "menu_withdraw_complete_waiting",
      "parent_id": 42,
      "description": "เห็นเมนูถอน ( รอโอนเงิน )",
      "role": "all",
      "type": "menu",
      "group": "withdraw",
      "order": null
    },
    "6": {
      "id": 6,
      "key": "menu_withdraw_all",
      "parent_id": 42,
      "description": "เห็นเมนูถอน ( ทั้งหมด )",
      "role": "all",
      "type": "menu",
      "group": "withdraw",
      "order": null
    },
    "7": {
      "id": 7,
      "key": "menu_user",
      "parent_id": 38,
      "description": "เห็นเมนูผู้ดูแลระบบ",
      "role": "all",
      "type": "menu",
      "group": "user",
      "order": null
    },
    "8": {
      "id": 8,
      "key": "menu_bank",
      "parent_id": 15,
      "description": "เห็นเมนู  ประวัติธนาคาร",
      "role": "owner",
      "type": "menu",
      "group": "bank",
      "order": null
    },
    "9": {
      "id": 9,
      "key": "menu_bank_deposit",
      "parent_id": 18,
      "description": "เห็นเมนู ธนาคาร ฝากเงิน",
      "role": "owner",
      "type": "menu",
      "group": "bank_deposit",
      "order": null
    },
    "10": {
      "id": 10,
      "key": "menu_config",
      "parent_id": 22,
      "description": "เห็นเมนู config",
      "role": "owner",
      "type": "menu",
      "group": "config",
      "order": null
    },
    "11": {
      "id": 11,
      "key": "menu_member_game",
      "parent_id": 29,
      "description": "เห็นเมนู เกมส์",
      "role": "all",
      "type": "menu",
      "group": "member",
      "order": null
    },
    "12": {
      "id": 12,
      "key": "menu_statement",
      "parent_id": 36,
      "description": "เห็นเมนู ประวัติธนาคาร",
      "role": "all",
      "type": "menu",
      "group": "statement",
      "order": null
    },
    "13": {
      "id": 13,
      "key": "menu_sms_waiting",
      "parent_id": 32,
      "description": "เห็นเมนู sms ( รอ )",
      "role": "all",
      "type": "menu",
      "group": "sms",
      "order": null
    },
    "14": {
      "id": 14,
      "key": "menu_sms",
      "parent_id": 32,
      "description": "เห็นเมนู sms ( ทั้งมหด )",
      "role": "all",
      "type": "menu",
      "group": "sms",
      "order": null
    },
    "15": {
      "id": 15,
      "key": "bank_action_all",
      "parent_id": 15,
      "description": "ธนาคาร ( จัดการได้ทั้งหมด )",
      "role": "all",
      "type": "action",
      "group": "bank",
      "order": null
    },
    "16": {
      "id": 16,
      "key": "bank_action_create",
      "parent_id": 15,
      "description": "ธนาคาร ( สร้าง )",
      "role": "all",
      "type": "action",
      "group": "bank",
      "order": null
    },
    "17": {
      "id": 17,
      "key": "bank_action_update",
      "parent_id": 15,
      "description": "ธนาคาร ( อัปเดจ )",
      "role": "all",
      "type": "action",
      "group": "bank",
      "order": null
    },
    "18": {
      "id": 18,
      "key": "bank_deposit_action_all",
      "parent_id": 18,
      "description": "ธนาคาร ฝากเงิน ( จัดการได้ทั้งหมด )",
      "role": "all",
      "type": "action",
      "group": "bank_deposit",
      "order": null
    },
    "19": {
      "id": 19,
      "key": "bank_deposit_action_create",
      "parent_id": 18,
      "description": "ธนาคาร ฝากเงิน ( สร้าง)",
      "role": "all",
      "type": "action",
      "group": "bank_deposit",
      "order": null
    },
    "20": {
      "id": 20,
      "key": "bank_deposit_action_update",
      "parent_id": 18,
      "description": "ธนาคาร ฝากเงิน ( อัปเดจ )",
      "role": "all",
      "type": "action",
      "group": "bank_deposit",
      "order": null
    },
    "21": {
      "id": 21,
      "key": "bank_deposit_action_toggle_active",
      "parent_id": 18,
      "description": "ธนาคาร ฝากเงิน ( ปรับสถานะ )",
      "role": "all",
      "type": "action",
      "group": "bank_deposit",
      "order": null
    },
    "22": {
      "id": 22,
      "key": "config_action_all",
      "parent_id": 22,
      "description": "config ( จัดการได้ทั้งหมด )",
      "role": "all",
      "type": "action",
      "group": "config",
      "order": null
    },
    "23": {
      "id": 23,
      "key": "config_action_update",
      "parent_id": 22,
      "description": "config ( อัปเดจ )",
      "role": "all",
      "type": "action",
      "group": "config",
      "order": null
    },
    "24": {
      "id": 24,
      "key": "deposit_action_all",
      "parent_id": 24,
      "description": "ฝากเงิน ( จัดการได้ทั้งหมด )",
      "role": "all",
      "type": "action",
      "group": "deposit",
      "order": null
    },
    "25": {
      "id": 25,
      "key": "deposit_action_set_active",
      "parent_id": 24,
      "description": "ฝากเงิน ( ปรับสถานะ )",
      "role": "all",
      "type": "action",
      "group": "deposit",
      "order": null
    },
    "26": {
      "id": 26,
      "key": "deposit_action_set_can_upload",
      "parent_id": 24,
      "description": "ฝากเงิน ( can upload )",
      "role": "all",
      "type": "action",
      "group": "deposit",
      "order": null
    },
    "27": {
      "id": 27,
      "key": "deposit_action_set_complete",
      "parent_id": 24,
      "description": "ฝากเงิน ( ปรับสำเร็จ )",
      "role": "all",
      "type": "action",
      "group": "deposit",
      "order": null
    },
    "28": {
      "id": 28,
      "key": "deposit_action_set_cancel",
      "parent_id": 24,
      "description": "ฝากเงิน ( ปรับยกเลิก )",
      "role": "all",
      "type": "action",
      "group": "deposit",
      "order": null
    },
    "29": {
      "id": 29,
      "key": "member_action_all",
      "parent_id": 29,
      "description": "สมาชิก ( จัดการได้ทั้งหมด )",
      "role": "all",
      "type": "action",
      "group": "member",
      "order": null
    },
    "30": {
      "id": 30,
      "key": "member_action_update",
      "parent_id": 29,
      "description": "สมาชิก ( อัปเดจ )",
      "role": "all",
      "type": "action",
      "group": "member",
      "order": null
    },
    "31": {
      "id": 31,
      "key": "member_action_toggle_active",
      "parent_id": 29,
      "description": "สมาชิก ( ปรับสถานะ )",
      "role": "all",
      "type": "action",
      "group": "member",
      "order": null
    },
    "32": {
      "id": 32,
      "key": "sms_action_all",
      "parent_id": 32,
      "description": "sms ( จัดการได้ทั้งหมด )",
      "role": "all",
      "type": "action",
      "group": "sms",
      "order": null
    },
    "33": {
      "id": 33,
      "key": "sms_action_comment",
      "parent_id": 32,
      "description": "sms ( คอมเม้นต์ )",
      "role": "all",
      "type": "action",
      "group": "sms",
      "order": null
    },
    "34": {
      "id": 34,
      "key": "menu_all",
      "parent_id": null,
      "description": "เห็นเมนูทั้งหมด",
      "role": "all",
      "type": "menu",
      "group": "all",
      "order": null
    },
    "35": {
      "id": 35,
      "key": "action_all",
      "parent_id": null,
      "description": "จัดการได้ทั้งหมด",
      "role": "all",
      "type": "action",
      "group": "all",
      "order": null
    },
    "36": {
      "id": 36,
      "key": "statement_action_all",
      "parent_id": 36,
      "description": "ประวัติธนาคาร ( จัดการได้ทั้งหมด )",
      "role": "all",
      "type": "action",
      "group": "statement",
      "order": null
    },
    "37": {
      "id": 37,
      "key": "statement_action_comment",
      "parent_id": 36,
      "description": "ประวัติธนาคาร ( คอมเม้นต์ )",
      "role": "all",
      "type": "action",
      "group": "statement",
      "order": null
    },
    "38": {
      "id": 38,
      "key": "user_action_all",
      "parent_id": 38,
      "description": "ผู้ดูแลระบบ ( จัดการได้ทั้งหมด )",
      "role": "all",
      "type": "action",
      "group": "user",
      "order": null
    },
    "39": {
      "id": 39,
      "key": "user_action_create",
      "parent_id": 38,
      "description": "ผู้ดูแลระบบ ( สร้าง )",
      "role": "all",
      "type": "action",
      "group": "user",
      "order": null
    },
    "40": {
      "id": 40,
      "key": "user_action_update",
      "parent_id": 38,
      "description": "ผู้ดูแลระบบ ( อัปเดจ )",
      "role": "all",
      "type": "action",
      "group": "user",
      "order": null
    },
    "41": {
      "id": 41,
      "key": "user_action_toggle_active",
      "parent_id": 38,
      "description": "ผู้ดูแลระบบ ( ปรับสถานะ )",
      "role": "all",
      "type": "action",
      "group": "user",
      "order": null
    },
    "42": {
      "id": 42,
      "key": "withdraw_action_all",
      "parent_id": 42,
      "description": "ถอนเงิน ( จัดการได้ทั้งหมด )",
      "role": "all",
      "type": "action",
      "group": "withdraw",
      "order": null
    },
    "43": {
      "id": 43,
      "key": "withdraw_action_toggle_active",
      "parent_id": 42,
      "description": "ถอนเงิน (  ปรับสถานะ  )",
      "role": "all",
      "type": "action",
      "group": "withdraw",
      "order": null
    },
    "44": {
      "id": 44,
      "key": "withdraw_action_comfirmed",
      "parent_id": 42,
      "description": "ถอนเงิน ( ตรวจสอบ & ตัดเคดิด )",
      "role": "all",
      "type": "action",
      "group": "withdraw",
      "order": null
    },
    "45": {
      "id": 45,
      "key": "withdraw_action_set_confirm",
      "parent_id": 42,
      "description": "ถอนเงิน ( ตรวจสอบ )",
      "role": "all",
      "type": "action",
      "group": "withdraw",
      "order": null
    },
    "46": {
      "id": 46,
      "key": "withdraw_action_completed",
      "parent_id": 42,
      "description": "ถอนเงิน ( เสร็จสิ้น )",
      "role": "all",
      "type": "action",
      "group": "withdraw",
      "order": null
    },
    "47": {
      "id": 47,
      "key": "withdraw_action_transfer_and_complete",
      "parent_id": 42,
      "description": "ถอนเงิน ( เสร็จสิ้น & ระบบโอนอัตโนมัติ )",
      "role": "all",
      "type": "action",
      "group": "withdraw",
      "order": null
    },
    "48": {
      "id": 48,
      "key": "member_action_winlost",
      "parent_id": 29,
      "description": "สมาชิก  (ชนะ แพ้)",
      "role": "all",
      "type": "action",
      "group": "member",
      "order": null
    },
    "49": {
      "id": 49,
      "key": "member_action_casino_setting",
      "parent_id": 29,
      "description": "สมาชิก (ตั้งค่าคาสิโน)",
      "role": "all",
      "type": "action",
      "group": "member",
      "order": null
    },
    "50": {
      "id": 50,
      "key": "menu_statement_deposit_all",
      "parent_id": 54,
      "description": "เห็นเมนู รายการฝาก ทั้งหมด (internet banking)",
      "role": "all",
      "type": "menu",
      "group": "statement_deposit",
      "order": null
    },
    "51": {
      "id": 51,
      "key": "menu_statement_withdraw_all",
      "parent_id": 55,
      "description": "เห็นเมนู รายการถอน ทั้งหมด  (internet banking)",
      "role": "all",
      "type": "menu",
      "group": "statement_withdraw",
      "order": null
    },
    "52": {
      "id": 52,
      "key": "menu_statement_deposit_free",
      "parent_id": 54,
      "description": "เห็นเมนู รายการฝาก  (internet banking)",
      "role": "all",
      "type": "menu",
      "group": "statement_deposit",
      "order": null
    },
    "53": {
      "id": 53,
      "key": "menu_statement_withdraw_free",
      "parent_id": 55,
      "description": "เห็นเมนู รายการถอน  (internet banking)",
      "role": "all",
      "type": "menu",
      "group": "statement_withdraw",
      "order": null
    },
    "54": {
      "id": 54,
      "key": "statement_deposit_action_all",
      "parent_id": 54,
      "description": "รายการฝาก  (internet banking) ( จัดการได้ทั้งหมด )",
      "role": "all",
      "type": "action",
      "group": "statement_deposit",
      "order": null
    },
    "55": {
      "id": 55,
      "key": "statement_withdraw_action_all",
      "parent_id": 55,
      "description": "รายการถอน  (internet banking) ( จัดการได้ทั้งหมด )",
      "role": "all",
      "type": "action",
      "group": "statement_withdraw",
      "order": null
    },
    "56": {
      "id": 56,
      "key": "sms_deposit_action_all",
      "parent_id": 56,
      "description": "sms ฝาก  ( จัดการได้ทั้งหมด )",
      "role": "all",
      "type": "action",
      "group": "sms",
      "order": null
    },
    "57": {
      "id": 57,
      "key": "menu_coupon_all",
      "parent_id": 58,
      "description": "เห็นเมนู รายการคูปอง ทั้งหมด",
      "role": "all",
      "type": "menu",
      "group": "coupon",
      "order": null
    },
    "58": {
      "id": 58,
      "key": "coupon_action_all",
      "parent_id": 58,
      "description": "คูปอง ( จัดการได้ทั้งหมด )",
      "role": "all",
      "type": "action",
      "group": "coupon",
      "order": null
    },
    "59": {
      "id": 59,
      "key": "coupon_action_create",
      "parent_id": 58,
      "description": "คูปอง ( สร้าง )",
      "role": "all",
      "type": "action",
      "group": "coupon",
      "order": null
    },
    "60": {
      "id": 60,
      "key": "coupon_action_update",
      "parent_id": 58,
      "description": "คูปอง ( อัปเดจ )",
      "role": "all",
      "type": "action",
      "group": "coupon",
      "order": null
    },
    "61": {
      "id": 61,
      "key": "menu_coupon_member_all",
      "parent_id": 62,
      "description": "เห็นเมนู รายการคูปองสมาชิก ทั้งหมด",
      "role": "all",
      "type": "menu",
      "group": "coupon_member",
      "order": null
    },
    "62": {
      "id": 62,
      "key": "coupon_member_action_all",
      "parent_id": 62,
      "description": "คูปองสมาชิก ( จัดการได้ทั้งหมด )",
      "role": "all",
      "type": "action",
      "group": "coupon_member",
      "order": null
    },
    "63": {
      "id": 63,
      "key": "menu_member_transfer_history",
      "parent_id": null,
      "description": "เห็นแมนูโอนยอด",
      "role": "all",
      "type": "menu",
      "group": "all",
      "order": null
    },
    "64": {
      "id": 64,
      "key": "manual_action_credit",
      "parent_id": 29,
      "description": "สมาชิก (เพิ่ม ลด เคดิต)",
      "role": "all",
      "type": "action",
      "group": "member",
      "order": null
    },
    "65": {
      "id": 65,
      "key": "menu_winlost",
      "parent_id": null,
      "description": "เห็นเมนูกายการ แพ้ ชนะ",
      "role": "all",
      "type": "menu",
      "group": "all",
      "order": null
    },
    "66": {
      "id": 66,
      "key": "ip_unlock",
      "parent_id": null,
      "description": "ไม่จำกัด ip ในการเข้า",
      "role": "all",
      "type": "action",
      "group": "all",
      "order": null
    },
    "67": {
      "id": 67,
      "key": "menu_lottery",
      "parent_id": null,
      "description": "เห็นเมนู  กิจกรรม ทายหวย",
      "role": "all",
      "type": "menu",
      "group": "event_lottery",
      "order": null
    },
    "68": {
      "id": 68,
      "key": "menu_sport",
      "parent_id": null,
      "description": "เห็นเมนู กิจกรรม ทายผลบอล",
      "role": "all",
      "type": "menu",
      "group": "event_sport",
      "order": null
    },
    "69": {
      "id": 69,
      "key": "lottery_action_update",
      "parent_id": null,
      "description": "หวย  ( อัปเดต )",
      "role": "all",
      "type": "action",
      "group": "event_lottery",
      "order": null
    },
    "70": {
      "id": 70,
      "key": "sport_action_create",
      "parent_id": null,
      "description": "บอล ( สร้าง )",
      "role": "all",
      "type": "action",
      "group": "event_sport",
      "order": null
    },
    "71": {
      "id": 71,
      "key": "sport_action_update",
      "parent_id": null,
      "description": "บอล  ( อัปเดต )",
      "role": "all",
      "type": "action",
      "group": "event_sport",
      "order": null
    }
  },
  "tmpGroups": {
    "all": [
      34,
      63,
      65,
      35,
      66
    ],
    "event_lottery": [
      67,
      69
    ],
    "event_sport": [
      68,
      70,
      71
    ],
    "bank": [
      8,
      15,
      16,
      17
    ],
    "bank_deposit": [
      9,
      18,
      19,
      20,
      21
    ],
    "config": [
      10,
      22,
      23
    ],
    "deposit": [
      2,
      3,
      24,
      25,
      26,
      27,
      28
    ],
    "member": [
      1,
      11,
      29,
      30,
      31,
      48,
      49,
      64
    ],
    "sms": [
      13,
      14,
      32,
      33,
      56
    ],
    "statement": [
      12,
      36,
      37
    ],
    "user": [
      7,
      38,
      39,
      40,
      41
    ],
    "withdraw": [
      4,
      5,
      6,
      42,
      43,
      44,
      45,
      46,
      47
    ],
    "statement_deposit": [
      50,
      52,
      54
    ],
    "statement_withdraw": [
      51,
      53,
      55
    ],
    "coupon": [
      57,
      58,
      59,
      60
    ],
    "coupon_member": [
      61,
      62
    ]
  }
}