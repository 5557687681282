<template>
  <page
    :title="$t(waiting ? 'deposit.waiting' : 'deposit.text_all')"
    :breadcrumbs="breadcrumbs"
    :loading="isLoading"
    :headers="headers"
    :options.sync="options"
    :items="deposits"
    :total="total"
    :filter="filter"
    @update:options="onSearch"
    class="flex-grow-1"
  >
    <template slot="btn-right"></template>

    <template slot="filters">
      <v-text-field
        v-model="filter['m.ufa_user']"
        :label="$t('member.ufa_user')"
        class="mx-1"
      ></v-text-field>
      <v-text-field
        v-model="filter['d.amount']"
        type="number"
        :label="$t('deposit.amount')"
        class="mx-1"
      ></v-text-field>
      <v-text-field
        v-model="filter['b.name']"
        :label="$t('bank.text')"
        class="mx-1"
      ></v-text-field>
    </template>

    <template v-slot:[`item.m.ufa_user`]="{ item }">
      <a :href="'?user=' + item.m.ufa_user || ''">{{
        item.m.ufa_user || ""
      }}</a>
    </template>
    <template v-slot:[`item.b.name`]="{ item }">
      {{ item.b.name || "" }}
    </template>
    <template v-slot:[`item.d.amount`]="{ item }">
      <div class="d-flex justify-end">
        {{
          item.d.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") || "0.00"
        }}
      </div>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-chip v-if="!item.d.active" color="red">{{
        $t("deposit.status_cancel")
      }}</v-chip>
      <v-chip v-else-if="item.d.complete" color="green">{{
        $t("deposit.status_success")
      }}</v-chip>
      <v-chip v-else-if="item.d.confirm && !item.complete" color="yellow">{{
        $t("deposit.status_waiting_checking")
      }}</v-chip>

      <v-chip v-else>{{ $t("deposit.status_waiting") }}</v-chip>
    </template>
    <template v-slot:[`item.comment`]="{ item }">
      {{ item.comment }} - {{ item.description }}
    </template>
    <template v-slot:[`item.d.created_at`]="{ item }">
      {{ $date(item.d.created_at) || "-" }}
    </template>
    <template v-slot:[`item.d.updated_at`]="{ item }">
      {{ $date(item.d.updated_at) || "-" }}
    </template>
    <template v-slot:[`item.tool`]="{ item }">
      <group-tool :value="item.d" @change="onSearch" />
    </template>

    <template v-slot:outside>
      <div class="text-center mt-2">
        ** รายการฝากจะแสดงเมื่อครบกำหนด 1 นาทีหลังจากลูกค้าทำการแจ้งฝาก **<br />
        ** รายการฝากประเภท "ฝากด่วน" admin ไม่สามารถแก้ไขได้ **<br />
      </div>
    </template>
  </page>
</template>

<script>
import Page from "../../layouts/PageLayout.vue";
import { mapActions, mapGetters } from "vuex";
import GroupTool from "../../components/deposit/GroupTool.vue";
export default {
  components: {
    GroupTool,
    Page,
  },
  data() {
    return {
      //page init
      isLoading: false,
      breadcrumbs: [
        {
          text: this.$t("deposit.text"),
        },
        {
          text: this.$t("common.list"),
        },
      ],

      loading: {
        cancel: false,
      },
      dialog: false,
      current: 0,
      isEdit: false,
      waiting: true,
      options: {},
      headers: [
        { text: this.$t("deposit.id"), value: "d.id", sortable: false },
        { text: this.$t("member.ufa_user"), value: "m.ufa_user" },
        { text: this.$t("deposit.bank"), value: "b.name" },
        { text: this.$t("deposit.amount"), value: "d.amount" },
        { text: this.$t("common.date"), value: "d.date" },
        { text: this.$t("common.time"), value: "d.time" },
        { text: this.$t("deposit.type"), value: "d.type" },
        { text: this.$t("deposit.confirmBy"), value: "uf.name" },
        { text: this.$t("deposit.completeBy"), value: "uc.name" },
        {
          text: this.$t("deposit.comment"),
          value: "d.comment",
          sortable: false,
        },
        { text: this.$t("deposit.created_at"), value: "d.created_at" },
        { text: this.$t("deposit.updated_at"), value: "d.updated_at" },
        { text: this.$t("deposit.status"), value: "status", sortable: false },
        { text: this.$t("common.tool"), value: "tool", sortable: false },
      ],
      //change filter
      // please edit deposit controller filter validation
      filter: {
        "m.ufa_user": "",
        "d.amount": "",
        "b.name": "",
      },
    };
  },
  computed: {
    ...mapGetters("deposit", ["deposits", "total"]),
  },
  watch: {
    ["$route.name"]() {
      this.clearMemberDeposits()
      this.options.sortBy = [];
      this.waiting = this.$route.meta.waiting;
      this.onSearch();
    },
  },
  created() {},
  beforeMount() {},
  mounted() {
    this.waiting = this.$route.meta.waiting;
    if (this.$route.query.user)
      this.filter["m.ufa_user"] = this.$route.query.user;
  },
  unmounted() {
    this.clearMemberDeposits();
  },
  methods: {
    ...mapActions("deposit", ["load", "clearMemberDeposits"]),
    async onSearch() {
      let sort = {};
      if (!this.options.sortBy || this.options.sortBy.length == 0) {
        sort["sortBy"] = ["d.id"];
        sort["sortDesc"] = [true];
      }
      this.isLoading = true;
      await this.load({
        waiting: this.waiting,
        ...this.options,
        ...sort,
        filter: this.filter,
      });
      this.isLoading = false;
    },
    goto_show(item) {
      this.$router.push({
        name: "deposit-member",
        params: { id: item.member_id },
      });
    },
  },
};
</script>
