import { call } from '../plugins/axios'
export default {
  namespaced: true,
  state: () => ({
    groupLists: [],
    groupTotal: 0,
    lists: [],
    total: 0,
  }),
  getters: {
    lists: (state) => state.lists,
    total: (state) => state.total,
    groupLists: (state) => state.groupLists,
    groupTotal: (state) => state.groupTotal,
  },
  mutations: {
    setLists: (state, value) => state.lists = value,
    setTotal: (state, value) => state.total = value,
    setGroupLists: (state, value) => state.groupLists = value,
    setGroupTotal: (state, value) => state.groupTotal = value,
  },
  actions: {
    loadGroupList: async ({commit, dispatch}, data) => {
      return await call('winlost/list', data)
        .then(({data}) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          commit('setGroupLists', data.list || [])
          commit('setGroupTotal', data.total_page || 0)

          return data
        })
        .catch((error) => dispatch('app/showError', { error }, { root: true }))
    },
    clearGroup: ({commit}) => {
      commit('setGroupLists', [])
      commit('setGroupTotal', 0)
    },
    loadList: async ({commit, dispatch}, { ufaUser, page = 1 }) => {
      return await call('winlost/byMember', { account: ufaUser, page})
        .then(({data}) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          commit('setLists', data.list || [])
          commit('setTotal', data.total_page || 0)

          return data
        })
        .catch((error) => dispatch('app/showError', { error }, { root: true }))
    },
    clear: ({commit}) => {
      commit('setLists', [])
      commit('setTotal', 0)
    }
  }
}
