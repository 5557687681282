<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex align-center py-3">
      <div>
        <div class="display-1">{{ title }}</div>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0 py-2"></v-breadcrumbs>
      </div>
      <v-spacer></v-spacer>
      <slot name="btn-right"/>
    </div>

    <v-card>
      <v-row v-if="!!this.$slots.filters" class="pa-2 align-center">
        <v-col class="d-flex align-center justify-space-between">
          <slot name="filters"/>
          <v-btn @click="reset" :disabled="loading" color="red" text>{{ $t('form.reset') }}</v-btn>
          <v-btn :loading="loading" :disabled="loading" @click="search">{{ $t('form.filter') }}</v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        set2="index = ((options.page-1) * options.itemsPerPage)+1"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        :hide-default-footer="hideDefaultFooter"
      >
        <template slot="item" slot-scope="myprops">
          <tr>
            <td v-for="header in headers" :key="`${ header.value } ${ new Date() }`">
              <slot :name="`item.${header.value}`" :item="myprops.item" :set="v = header.value.split('.').reduce((r,n)=>{ r = r[n]; return r }, myprops.item)">
                {{ value(header, v) }}
              </slot>
            </td>
          </tr>
        </template>
      </v-data-table>

      <slot></slot>
    </v-card>
    <slot name="outside"></slot>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    breadcrumbs: {
      type: Array,
      default: ()=>([])
    },
    headers: {
      type: Array,
      default: () => ([])
    },
    items: {
      type: Array,
      default: () =>([])
    },
    total: {
      type: Number,
      default: 0
    },
    filter: {
      type: Object,
      default: ()=>({})
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => ([10, 20, 30, 40, 50])
    }
  },
  data: ()=>({
    options: {},
    row: 0
  }),
  created() {},
  mounted() {},
  methods: {
    search () {
      this.$set(this, 'options', { ...this.options, filter: JSON.parse(JSON.stringify(this.filter)) })
      this.$emit('search', true)
    },
    reset () {
      Object.keys(this.filter).forEach((index)=>{
        this.filter[index] = ''
      })
      this.$set(this, 'options', { ...this.options, filter: JSON.parse(JSON.stringify(this.filter)) })
      this.$emit('search', false)
    },
    value (head, value) {
      if (String(value) == 'null') {
        return '-'
      }
      if (head.type == 'date') {
        return moment(value).format('DD/MM/YYYY HH:mm')
      }
      return value
    }
  },
  watch: {
    options: {
      handler () {
        this.$emit('update:options', this.options)
      },
      deep: true
    },
  },
  computed: {}
}
</script>

<style>

</style>
