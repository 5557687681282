<template>
  <div>
    <input id="input" class="hide">
    <date-picker v-model="date" :editable="true" :popover="false" :show="show" :min="minDate" :max="maxDate"
      @close="$emit('close')"
      @change="onChange"
      format="YYYY-MM-DD HH:mm"
      auto-submit custom-input="#input" locale="th"
      type="datetime">
      <template #submit-btn="{ canSubmit, color, submit }">
        <button type="button" :disabled="!canSubmit" :style="{ color }" @click="submit" v-text="'ตกลง'" />
      </template>
      <template #cancel-btn="{ vm, color }">
        <button type="button" :style="{ color }" @click="vm.visible = false" v-text="'ยกเลิก'" />
      </template>
      <template #now-btn="{ color, goToday }">
        <button type="button" :style="{ color }" @click="goToday" v-text="'เวลาปัจจุบัน'" />
      </template>
      <template v-slot:header-year="{ selectedDate }">
        พ.ศ. {{ selectedDate.xYear() + 543 }}
      </template>
      <template v-slot:year-item="{ year }">
        {{ year.year() + 543 }}
      </template>
    </date-picker>
  </div>

</template>

<script>
import moment from 'moment';
export default {
  props: {
    value: {
      type: [String, Date, null],
      default: "",
    },
    show: {
      type: [Boolean, null],
      default: false,
    },
    minDate: {
      type: [String, null],
      default: '',
    },
    maxDate: {
      type: [String, null],
      default: '',
    },
  },
  data() {
    return {
      date: "",
    };
  },
  computed: {},
  created() { },
  mounted() { },
  methods: {
    onChange() {
      if (this.date !== '') this.$emit('input', moment(String(this.date)).toDate());
    }
  },
  watch: {
    show() {
      this.date = this.value;
    },
    date(val) {
      if (!val) this.$emit('date_th', '')
      else this.$emit('date_th', moment(this.date).add(543, 'years').format('DD/MM/YYYY HH:mm'));
    },
  },
};
</script>

<style scoped>
input.hide {
  display: none;
}
</style>
