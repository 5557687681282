import Vue from 'vue'

import LabelText from '../components/common/LabelText'
Vue.component('s-label-text', LabelText)

import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
Vue.component('date-picker', VuePersianDatetimePicker);

import TablePage from '../layouts/PageLayout.vue'
Vue.component('table-page', TablePage)

Vue.prototype.$date = (dateText) => {
  const d = (dateText ? new Date(dateText) : new Date()).toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' }).split(/\/|, | |:/)
  const dateString = `
  ${('00' + d[0]).slice(-2)}/${('00' + d[1]).slice(-2)}/${d[2]} ${('00' + d[3]).slice(-2)}:${('00' + d[4]).slice(-2)}`
  return dateString
}

Vue.prototype.$createReset = ($this, field, data) => {
  if (!$this["$$reset"]) $this.$$reset = {}
  if (typeof data == "object") {
    $this.$$reset[field] = () => {
      $this.$set($this, field, JSON.parse(JSON.stringify(data)));
    };
    return { [field]: JSON.parse(JSON.stringify(data)) };
  } else
    $this.$$reset[field] = () => {
      $this.$set($this, field, data);
    };
  return { [field]: data };
}


