import { call } from '../plugins/axios'

export default {
  namespaced: true,
  state: () => ({
    histories: {},
  }),
  getters: {
    histories: (state) => state.histories,
  },
  mutations: {
    setHistories: (state, value) => state.histories = value,
  },
  actions: {
    getMemberTransferHistory: async ({ commit, dispatch }, memberId ) => {
      return await call('history/transfer_member', { text: memberId })
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else {
            commit('setHistories', data.histories || {})
          }
          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
          return { error: 500 }
        })
    },
    moveCredit: async ({ commit, dispatch }, memberId) => {
      return await call('history/transfer_member', { text: memberId, move: 1 })
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else {
            commit('setHistories', data.histories || {})
          }
          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
          return { error: 500 }
        })
    },
    clear: ({commit}) => {
      commit('setHistories', {})
    }
  }
}
