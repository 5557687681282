<template>
  <page-blank-layout :title="title" :breadcrumbs="breadcrumbs">
    <template slot="btn-right">
      <btn-popup-add
        v-if="canCreate"
        :can_choice_agent="true"
        color="primary"
        text="agent"
      ></btn-popup-add>
    </template>

    <v-card>
      <v-card-text>
        <div class="d-flex">
          <input-or-text
            v-model="agent_filter.bank_id"
            clearable
            :label="$t('bank.text')"
            :items="bank_options"
            input
            outlined
            dense
            hide-details
          />
          <input-or-text
            v-model="agent_filter.name"
            clearable
            :label="$t('bank_deposit.agent_id')"
            input
            outlined
            dense
            hide-details
            class="flex-grow-1 mx-2"
          />

          <v-btn color="primary" @click="search" :loading="isLoading"
            >ค้นหา</v-btn
          >
        </div>
        <div class="d-flex mt-2">
          <div class="d-flex mx-2">
            <div
              class="red rounded-circle mr-2"
              style="width: 20px; height: 20px"
            ></div>
            บัญชีถูกยกเลิก
          </div>
          <div class="d-flex mx-2">
            <div
              class="orange rounded-circle mr-2"
              style="width: 20px; height: 20px"
            ></div>
            เงือนไขถูกยกเลิก
          </div>
          <div class="d-flex mx-2">
            <div
              class="yellow rounded-circle mr-2"
              style="width: 20px; height: 20px"
            ></div>
            ยอดเงินสูงสุด
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="my-2" :loading="isLoading">
      <v-card-title>
        <span>เงื่อนไขพื้นฐาน</span>
        <v-spacer></v-spacer>
        <btn-popup-add v-if="canCreate" icon />
      </v-card-title>
      <v-data-table
        :headers="bank_deposit_agent_headers"
        :items="bank_deposits_global"
      >
        <template slot="item" slot-scope="{ item }">
          <data-table-item :item="item" :headers="bank_deposit_agent_headers">
            <template v-slot:[`item.bank.bank_name`]>
              {{ getBankName(item.bank.bank_name) }}
            </template>

            <div class="d-flex">
              <v-btn
                v-if="canEdit"
                :disabled="!item.bank.active"
                icon
                @click="openEdit(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                v-if="canToggleActive"
                :disabled="!item.bank.active"
                :loading="isToggleActiveLoading"
                icon
                @click="toggleActive(item)"
              >
                <v-icon>mdi-{{ item.active ? "cancel" : "check" }}</v-icon>
              </v-btn>
            </div>
          </data-table-item>
        </template>
      </v-data-table>
    </v-card>

    <div class="mb-3 grey--text">
      เงื่อนไขพื้นฐาน
      ใช้ในกรณีที่ไม่มีการกำหนดเงื่อนไขเจาะจงในการรับชำระเงินจากธนาคาร
    </div>

    <v-card
      class="my-2"
      v-for="items in bank_deposits_agent"
      :key="`agent_${items[0].agent_id}`"
    >
      <v-card-title :set="(name = user_name(items[0].agent_id))">
        <span>เงือนไขสำหรับ #{{ items[0].agent_id }} {{ name }}</span>
        <v-spacer></v-spacer>
        <btn-popup-add
          :agent_id="items[0].agent_id"
          :agent_name="name"
          :can_choice_agent="false"
          icon
        />
      </v-card-title>
      <v-data-table :headers="bank_deposit_agent_headers" :items="items">
        <template slot="item" slot-scope="{ item }">
          <data-table-item :item="item" :headers="bank_deposit_agent_headers">
            <template v-slot:[`item.bank.bank_name`]>
              {{ getBankName(item.bank.bank_name) }}
            </template>

            <div class="d-flex">
              <v-btn
                v-if="canEdit"
                :disabled="!item.bank.active"
                icon
                @click="openEdit(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                v-if="canToggleActive"
                :disabled="!item.bank.active"
                :loading="isToggleActiveLoading"
                icon
                @click="toggleActive(item)"
              >
                <v-icon>mdi-{{ item.active ? "cancel" : "check" }}</v-icon>
              </v-btn>
            </div>
          </data-table-item>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog" width="500">
      <bank-deposit-edit
        :value="bank_deposit_edit"
        @close="dialog = false"
      ></bank-deposit-edit>
    </v-dialog>
  </page-blank-layout>
</template>

<script>
import BankDepositEdit from "./BankDepositEdit.vue";
import PageBlankLayout from "../../layouts/PageBlankLayout.vue";
import bankNames from "../../configs/banks";
import { mapActions, mapGetters } from "vuex";
import BtnPopupAdd from "../../components/bank_deposit/BtnPopupAdd.vue";
import DataTableItem from "../../components/bank_deposit/DataTableItem.vue";
import InputOrText from "../../components/common/InputOrText.vue";
//import GroupTool from "../../components/bank_deposit/GroupTool.vue"
export default {
  components: {
    BankDepositEdit,
    BtnPopupAdd,
    DataTableItem,
    InputOrText,
    PageBlankLayout,
  },
  data() {
    return {
      isLoading: false,
      isToggleActiveLoading: false,
      dialog: false,
      title: this.$t("bank_deposit.text"),
      breadcrumbs: [{ text: "main" }, { text: "list" }],
      bank_deposit_agent_headers: [
        { text: this.$t("bank_deposit.id"), value: "id", sortable: false },
        { text: this.$t("bank.bank_name"), value: "bank.bank_name" },
        { text: this.$t("bank.name"), value: "bank.name" },
        { text: this.$t("bank.number"), value: "bank.number" },

        { text: this.$t("common.order"), value: "order" },
        {
          text: this.$t("bank_deposit.amount_counter"),
          value: "amount_counter",
          currency: true,
        },
        {
          text: this.$t("bank_deposit.amount_counter_maximum"),
          value: "amount_counter_maximum",
          currency: true,
        },
        {
          text: this.$t("bank_deposit.first_deposit"),
          value: "first_deposit",
          enum: { 0: "ไม่ใช้", 1: "ใช้" },
        },
        {
          text: this.$t("bank_deposit.cdm_mode"),
          value: "cdm_mode",
          enum: { 0: "ไม่ใช้", 1: "ใช้" },
        },
        {
          text: this.$t("bank_deposit.active"),
          value: "active",
          enum: { 0: "ไม่ใช้", 1: "ใช้" },
        },
        { text: this.$t("tool"), value: "tool", sortable: false },
      ],
      agent_filter: {
        bank_id: null,
        name: "",
      },
      bank_deposit_edit: {},

      // bank_deposits_global: []
    };
  },
  computed: {
    ...mapGetters("bank", ["banks", "bank_deposits"]),
    ...mapGetters("user", ["users", "profile"]),
    canCreate() {
      if (!this.profile) return false;
      return this.profile.chkAction([
        "bank_deposit_action_all",
        "bank_deposit_action_create",
      ]);
    },
    canEdit() {
      if (!this.profile) return false;
      return this.profile.chkAction([
        "bank_deposit_action_all",
        "bank_deposit_action_update",
      ]);
    },
    canToggleActive() {
      if (!this.profile) return false;
      return this.profile.chkAction([
        "bank_deposit_action_all",
        "bank_deposit_action_toggle_active",
      ]);
    },
    bank_deposits_global() {
      if (!this.bank_deposits || this.bank_deposits.length === 0) return [];

      return this.bank_deposits.filter(
        (r) => r.cdm_mode == 0 && (r.agent_id == 0 || r.agent_id == null)
      );
    },
    bank_deposits_agent() {
      if (!this.bank_deposits || this.bank_deposits.length === 0) return [];

      const keyArray = {};
      const r = [];
      for (const acc of this.bank_deposits) {
        if (acc.agent_id == 0 || acc.agent_id == null) continue;
        if (typeof keyArray[String(acc.agent_id)] === "undefined") {
          keyArray[String(acc.agent_id)] = r.length;
          r.push([]);
        }
        const index = keyArray[String(acc.agent_id)];

        r[index].push(acc);
      }

      return r.map((row) =>
        row.sort((a, b) => {
          if (a.cdm_mode == a.cdm_mode) {
            if (a.first_deposit == b.first_deposit) {
              return a.order - b.order;
            }
            return a.first_deposit - b.first_deposit;
          }
          return b.cdm_mode - a.cdm_mode;
        })
      );
    },
    bank_options() {
      if (!this.banks || this.banks.length === 0) return [];

      return this.banks
        .filter((r) => r.withdraw_only === 0)
        .map((bank) => ({
          text: `${bank.bank_name} - ${bank.name} - ${bank.number}`,
          value: bank.id,
        }));
    },
  },
  created() {},
  async mounted() {
    this.isLoading = true;
    await this.get_bank_deposits({});
    if (!this.$store.getters["user/users"].length) await this.load({});
    if (!this.$store.getters["bank/banks"].length) await this.get_banks({});
    this.isLoading = false;
  },
  methods: {
    ...mapActions("bank", ["get_banks", "get_bank_deposits", 'bank_deposit_active']),
    ...mapActions("user", ["load"]),
    async toggleActive(bank_deposit) {
      this.isToggleActiveLoading = true;
      await this.bank_deposit_active(bank_deposit.id);
      this.isToggleActiveLoading = false;
    },
    user_name(id) {
      if (!this.users) return "";

      const user = this.users.find((user) => user.id == id);
      return user ? user.name : "";
    },
    async search() {
      this.isLoading = true;
      await this.get_bank_deposits(this.agent_filter);
      this.isLoading = false;
    },
    openEdit(item) {
      this.bank_deposit_edit = item;
      this.dialog = true;
    },
    getBankName(value) {
      const index = bankNames.findIndex((item) => item.value == value);
      if (parseInt(index) >= 0) return bankNames[index].text;
      else value;
    },
  },
};
</script>
<style>
</style>
