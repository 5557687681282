import { call } from '../plugins/axios'
export default {
  namespaced: true,
  state: () => ({
    sms: [],
    count: 0
  }),
  getters: {
    sms: state => state.sms,
    count: state => state.count
  },
  mutations: {
    setSMS: (state, value) => state.sms = value,
    setSMSCount: (state, value) => state.count = value,
    updateSMS: (state, value) => {
      const index = state.sms.findIndex(item => item.id === value.id)
      if (index > -1) Object.assign(state.sms[index], value)
    }
  },
  actions: {
    getSMS: async ({ commit, dispatch}, data) => {
      return await call('sms/list', data)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else {
            if (data.rows) commit('setSMS', data.rows)
            if (data.count) commit('setSMSCount', data.count)
          }

          return data
        })
        .catch(() => { })
    },
    setComment: async ({ commit, dispatch}, data) => {
      return await call('sms/comment', data)
        .then(({data}) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          if (data.change) commit('updateSMS', data.change)

          return data
        })
        .catch(() => { })
    }
  }
}
