<template>
  <div class="d-flex flex-row">
    <v-btn icon  @click="goto_show" :title="$t('message.history')">
      <v-icon color="primary">mdi-eye</v-icon>
    </v-btn>
    <v-btn v-if="isMenuDeposit" icon :to="{ path: `deposits?user=${this.value.ufa_user}` }" :title="$t('message.history')">
      <v-icon color="primary">mdi-cash-plus</v-icon>
    </v-btn>
    <v-btn v-if="isMenuWithdraw" icon :to="{ path: `withdraws?user=${this.value.ufa_user}` }" :title="$t('message.show')">
      <v-icon color="primary">mdi-cash-minus</v-icon>
    </v-btn>
    <v-btn v-if="canEdit" icon @click="goto_edit" :title="$t('message.edit')">
      <v-icon color="primary">mdi-account-edit</v-icon>
    </v-btn>
    <v-btn v-if="canToggleActive" icon :loading="loading.history" @click="setActive" :title=" active ? 'unban': 'ban'" class="ml-2">
      <v-icon color="primary">mdi-account-{{ active ? 'check': 'cancel' }}</v-icon>
    </v-btn>
    <v-btn v-if="canWinLost" @click="openDialog = !openDialog" icon>
      <v-icon color="primary">mdi-chess-queen</v-icon>
    </v-btn>

    <v-dialog v-if="openDialog" v-model="openDialog" width="70%" >
      <v-card>
        <v-card-text height="70%">
          <member-win-lost-page :member_id="value.ufa_user"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MemberWinLostPage from '../../pages/members/MemberWinLostPage.vue'
export default {
  components: {
    MemberWinLostPage
  },
  data: () => ({
    openDialog: false,
    loading: {
      history: false
    }
  }),
  props: {
    value: {
      type: Object,
      default: ()=>({})
    }
  },
  methods: {
    ...mapActions('app', ['showError']),
    ...mapActions('member', ['load', 'toggle_active']),
    async setActive () {
      this.loading.history = true
      await this.toggle_active(this.value.id).catch((error) => {
        if (error && error.response && error.response.data.message['error'])
          this.showError({ error: { message: error.response.data.message['error'] } })
        else this.showError({ error: { messag: 'Systen fails' } })
      })
      this.loading.history = false
    },

    goto_edit () {
      this.$router.push({ name: 'member-edit', params: { id: this.value.id } })
    },
    goto_show () {
      this.$router.push({ name: 'member-show', params: { id: this.value.id } })
    },
    goto_deposit() {
      this.$router.push({ name: 'deposits', params: { id: this.value.id } })
    },
    goto_withdraw() {
      this.$router.push({ name: 'withdraws', params: { id: this.value.id } })
    },
  },
  computed: {
    ...mapGetters('user', ['profile']),
    canToggleActive () {
      if (!this.profile) return false
      return this.profile.chkAction(['member_action_all', 'member_action_toggle_active'])
    },
    canEdit () {
      if (!this.profile) return false
      return this.profile.chkAction(['member_action_all', 'member_action_update'])
    },
    canWinLost () {
      if (!this.profile) return false
      return this.profile.chkAction(['member_action_all', 'member_action_win_lost'])
    },
    isMenuWithdraw () {
      if (!this.profile) return false
      return this.profile.chkMenu('menu_withdraw')
    },
    isMenuDeposit () {
      if (!this.profile) return false
      return this.profile.chkMenu('menu_deposit')
    },
    active () {
      if (!this.value || !this.value.active) return false
      else return this.value.active
    }
  }
}
</script>

<style>

</style>
