import { call } from '../plugins/axios'

// import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    banks: [],
    bank_deposits: []
  }),
  getters: {
    banks: (state) => state.banks,
    bank_deposits: (state) => state.bank_deposits,
    bank_options: (state) => state.banks.map(b => {
      return {
        text: `${b.bank_name} ${b.name} ${b.number} จำนวนเงินล่าสุด: ${b.balance.toFixed(2)} บาท [วันนี้โอนไปแล้ว ${b.amount.toFixed(2)}]`,
        value: b.id
      }
    })
  },
  mutations: {
    setBanks: (state, value) => state.banks = value,
    addBank: (state, value) => state.banks.push(value),
    updateBank: (state, value) => {
      const index = state.banks.findIndex(bank => bank.id === value.id)
      state.banks[index] = Object.assign(state.banks[index], value)
    },

    setBankDeposits: (state, value) => state.bank_deposits = value,
    addBankDeposit: (state, value) => state.bank_deposits.push(value),
    updateBankDeposit: (state, value) => {
      const index = state.bank_deposits.findIndex(item => item.id === value.id)
      if (parseInt(index) >= 0) {
        Object.assign(state.bank_deposits[index], value)
      }
      else state.bank_deposits.push(value)

    }
  },
  actions: {
    async get_banks({ commit }, data) {
      return await call('bank/list', data)
        .then(({data}) => {
          if (data.error) commit('setBanks', [])
          else commit('setBanks', data.banks)

          return data
        })
    },
    async create_bank({ commit, dispatch }, data) {
      return await call('bank/create', data)
        .then(({data}) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          if (data.bank) commit('addBank', data.bank)

          return data
        })
    },
    async update_bank({ commit, dispatch }, data) {
      return await call('bank/update', data)
        .then(({data}) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          if (data.bank) commit('updateBank', data.bank)
          if (data.change) commit('updateBank', data.change)

          return data
        })
    },

    async get_bank_deposits({ commit }, data) {
      return await call('bank-deposit/list', data)
        .then(({data}) => {
          if (data.error) commit('setBankDeposits', [])
          else commit('setBankDeposits', data.bank_deposits)

          return data
        })
    },
    async bank_deposit_active({ commit, dispatch }, id) {
      return await call('bank-deposit/toggle-active', id)
        .then(({data}) => {
          if (data.error) return dispatch('app/showError', { error: { message: data.message } }, { root: true })
          if (data.bank_deposit) commit('updateBankDeposit', data.bank_deposit)
          if (data.change) commit('updateBankDeposit', data.change)

          return data
        })
    },
    async create_bank_deposit({commit}, data) {
      return await call('bank-deposit/create', data)
        .then(({data}) => {
          if (data.error) commit('app/showError', { error: { message: data.message } }, { root: true })
          if (data.bank_deposit) commit('addBankDeposit', data.bank_deposit)

          return data
        })
    },
    async update_bank_deposit({commit}, data) {
      return await call('bank-deposit/update', data)
      .then(({data}) => {
        if (data.error) commit('app/showError', { error: { message: data.message } }, { root: true })
        if (data.bank_deposit) commit('updateBankDeposit', data.bank_deposit)
        if (data.change) commit('updateBankDeposit', data.change)

        return data
      })
    }
  }
}
