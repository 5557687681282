<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" :icon="icon" :color="color">
        {{text}} <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        เพิ่มเงือนไข #{{ agent_id }} {{ agent_name || ''}}
      </v-card-title>
      <v-card-text class="py-3">
        <v-form ref="form" :disabled="isLoading">
          <input-or-text
            v-model="bank_deposit.bank_id"
            :label="$t('bank.text')"
            :items="bank_options"
            :rules="[v => !!v || 'bank_id is required']"
            input
          />
          <input-or-text
            v-if="can_choice_agent"
            v-model="bank_deposit.agent_id"
            :label="$t('user.name')"
            :items="agent_options"
            :rules="[v => !!v || 'bank_id is required']"
            input
          />
          <input-or-text
            v-model="bank_deposit.order"
            :label="$t('common.order')"
            type="number"
            input
          />
          <input-or-text
            v-model="bank_deposit.amount_counter_maximum"
            :label="$t('bank_deposit.amount_counter_maximum')"
            type="number"
            input
          />
          <input-or-text
            v-model="bank_deposit.first_deposit"
            :label="$t('bank_deposit.first_deposit')"
            :false-value="0"
            :true-value="1"
            hide-details
            input
            checkbox
          />
          <input-or-text
            v-model="bank_deposit.cdm_mode"
            :label="$t('bank_deposit.cdm_mode')"
            :false-value="0"
            :true-value="1"
            hide-details
            input
            checkbox
          />
          <input-or-text
            v-model="bank_deposit.active"
            :label="$t('bank_deposit.active')"
            :false-value="0"
            :true-value="1"
            hide-details
            input
            checkbox
          />
          <div class="d-flex justify-end">
            <v-btn class="primary" @click="submit" :loading="isLoading">submit</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InputOrText from "../common/InputOrText.vue"
import { mapActions, mapGetters } from "vuex"
export default {
  components: {
    InputOrText,
  },
  props: {
    agent_id: {
      type: [Number,null],
      default: null,
    },
    agent_name: {
      type: [String, null],
      default: null,
    },
    can_choice_agent: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      bank_deposit: {
        agent_id: this.agent_id,
        order: 1,
        amount_counter_maximum: 500000.00,
        first_deposit: 0,
        active: 1,
        cdm_mode: 0
      },
      errors: {}
    };
  },
  created() {},
  async mounted() {

  },
  methods: {
    ...mapActions('user', ['load']),
    ...mapActions('bank', ['get_banks', 'create_bank_deposit']),
    async submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true
        await this.create_bank_deposit({
          agent_id: this.agent_id,
          ...this.bank_deposit
        })
          .then((data)=>{
            if (data.error) this.errors = data.errors
            else this.dialog = false
          })
        this.isLoading = false
      }
    }
  },
  computed: {
    ...mapGetters('user', ['users']),
    ...mapGetters('bank', ['banks']),
    agent_options() {
      if (!this.users) return []
      return this.users.map(r => ({
        text: r.name,
        value: r.id,
      }));
    },
    bank_options() {
      if (!this.banks) return []
      return this.banks.filter(r=>r.active == 1 && r.withdraw_only == 0).map(r => ({
        text: `${r.bank_name} ${r.name} ${r.number}`,
        value: r.id,
      }))
    }
  },
  watch: {
    async dialog(val) {
      if (val) {
        this.isLoading = true
        if (!this.$store.getters['user/total']) await this.load({})
        if (!this.$store.getters['bank/banks'].length) await this.get_banks({})
        this.isLoading = false
      }
    },
  },
};
</script>

<style>
</style>
