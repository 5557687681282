<template>
  <page-blank-layout :title="title">
    <template v-slot:btn-right>
      <v-btn icon @click="refresh" :loading="isLoading">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-simple-table :loading="isLoading">
          <template v-slot:default>
            <thead>
              <tr class="text-center">
                <th class="text-center black--text">บัญชี</th>
                <th class="text-center black--text">สกุลเงิน</th>
                <th class="text-center black--text">จำนวนเงิน</th>
                <th class="text-center black--text">สมาชิก</th>
                <th class="text-center green black--text">เอเย่นต์</th>
                <th class="text-center black--text">บริษัท</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isLoading">
                <td colspan="19" class="text-center">
                  <v-progress-linear indeterminate></v-progress-linear>
                </td>
              </tr>
              <tr v-else v-for="(item) in list" :key="item[0]">
                <td v-for="(td, i) in item"
                  :key="item[0] + '-' + i" class="text-right">
                  <span v-if="i === 0">
                    <v-btn @click="showDetail(td)" text>
                      {{ td }}
                    </v-btn>
                  </span>
                  <span v-else v-html="td"></span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-dialog v-if="openDialog" v-model="openDialog" width="70%">
      <v-card>
        <v-card-text height="70%">
          <out-stand-page :ufa_user="ufa_user" />
        </v-card-text>
      </v-card>
    </v-dialog>

  </page-blank-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PageBlankLayout from '../../layouts/PageBlankLayout.vue'
import OutStandPage from './OutStandPage.vue'
export default {
  components: {
    PageBlankLayout,
    OutStandPage,
  },
  data() {
    return {
      title: this.$t('winlost.text'),
      ufa_user: '',
      openDialog: false,
      isLoading: false,
      startDatePickerShow: false,
      endDatePickerShow: false,
      startDateTh: '',
      endDateTh: '',
      filter: {}
    }
  },
  created() { },
  async mounted() {
    this.isLoading = true
    await this.onSearch({})
    this.isLoading = false
  },
  methods: {
    ...mapActions('outstand', ['getList']),
    showDetail(account) {
      this.ufa_user = account.replace(/\*/g, '')
      this.openDialog = true
    },
    async onSearch(){
      this.isLoading = true
      await this.getList(this.filter)
      this.isLoading = false
    },
    async refresh() {
      this.isLoading = true
      await this.getList()
      this.isLoading = false
    }
  },
  computed: {
    ...mapGetters('outstand', ['list']),
    ...mapGetters('user', ['profile'])
  },
  watch: {
    startDateTh(val) {
      if (!val) this.filter['start_date'] = ''
    },
    endDateTh(val) {
      if (!val) this.filter['end_date'] = ''
    }
  }
}
</script>

<style>
</style>
