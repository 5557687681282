<template>
  <page
    :title="$t('member.text')"
    :breadcrumbs="breadcrumbs"
    :loading="isLoading"
    :headers="headers"
    :options.sync="options"
    :items="members"
    :total="total"
    :filter="filter"
    @update:options="onSearch"
  >
      <template slot="btn-right"></template>
      <template slot="filters">
        <v-text-field v-model='filter.bank_number' class="mx-1" :placeholder="$t('member.bank_number')" hide-details></v-text-field>
        <v-text-field v-model='filter.ufa_user' class="mx-1" :placeholder="$t('member.name')" hide-details></v-text-field>
        <v-text-field v-model='filter.tel' class="mx-1" :placeholder="$t('member.tel')" hide-details></v-text-field>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        {{ $t('active.'+item.active) }}
      </template>
      <template v-slot:[`item.tool`]="{ item }">
        <group-tool :value="item"/>
      </template>
  </page>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Page from '../../layouts/PageLayout.vue'
import GroupTool from '../../components/member/GroupTool.vue'
import options from '../../configs/options'

export default {
  components: {
    Page, GroupTool
  },
  data () {
    return {
      //page init
      isLoading: false,
      breadcrumbs: [{
        text: this.$t('member.text')
      }, {
        text: this.$t('common.list')
      }],
      options: {},
      headers: [
        { text: this.$t('member.id'), value:'id', sortable: false },
        { text: this.$t('member.bank_name'), value: 'bank_name' },
        { text: this.$t('member.bank_number'), value: 'bank_number' },
        { text: this.$t('member.ufa_user'), value: 'ufa_user' },
        { text: this.$t('member.tel'), value: 'tel' },
        { text: this.$t('member.otp'), value: 'otp' },
        { text: this.$t('member.vip'), value: 'vip' },
        { text: this.$t('member.active'), value: 'active' },
        { text: this.$t('common.tool'), value: 'tool', sortable: false },
      ],
      items: [],
      filter: {
        ufa_user: '',
        tel: '',
        bank_number: '',
      },
      active_options: options.active
    }
  },
  created() {},
  mounted() {},
  beforeMount() {},
  methods: {
    ...mapActions('member', ['load', 'toggle_active']),
    ...mapActions('app', ['showError']),
    async onSearch(){
      this.isLoading = true
      await this.load(JSON.parse(JSON.stringify(this.options)))
      this.isLoading = false
    }
  },
  computed: {
    ...mapGetters('member', ['members', 'total'])
  }
}
</script>
