<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex align-center py-3">
      <div>
        <div class="display-1">
          {{ title }}
        </div>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0 py-2"></v-breadcrumbs>
      </div>
      <v-spacer></v-spacer>
      <slot name="btn-right"/>
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    breadcrumbs: {
      type: Array,
      default: ()=>([])
    }
  },
  data: ()=>({}),
  created() {},
  mounted() {},
  methods: {},
  watch: {},
  computed: {}
}
</script>

<style>

</style>
