<template>
  <page-blank-layout :title="title" :breadcrumbs="breadcrumbs">
    <template slot="btn-right">
      <v-btn color="primary" v-if="canCreate" @click="open_create">
        bank<v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-data-table
      :headers="headers"
      :items="banks"
      :loading="isLoading"
    >
      <template v-slot:item="{ item }">
        <data-table-item :item="item" :headers="headers" :item_class="item_class">
          <div class="d-flex">
            <v-btn icon v-if="canEdit" @click="open_edit(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <btn-active :item="item"></btn-active>
          </div>
        </data-table-item>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialog"
      width="500"
    >
        <bank-from v-model="dialog_data" :action="dialog_action" @close="dialog=false"></bank-from>
    </v-dialog>

  </page-blank-layout>
</template>
<script>
import PageBlankLayout from "../../layouts/PageBlankLayout.vue";
import DataTableItem from "../../components/common/DataTableItem.vue";
import BtnActive from "../../components/bank/BtnActive.vue";
import BankFrom from './BankForm.vue'
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    PageBlankLayout,
    DataTableItem,
    BankFrom,
    BtnActive,
  },
  data() {
    return {
      //page init
      dialog: false,
      dialog_data: {active: 1},
      dialog_action: 'create',
      title: this.$t('bank.text'),
      isLoading: false,
      breadcrumbs: [{ text: "main" }, { text: "list" }],
      headers: [
      { text: this.$t("bank.id"), value: "id", sortable: false },
      { text: this.$t("bank.withdraw_only"), value: "withdraw_only", enum: {'0': this.$t('common.no'), '1': this.$t('common.yes')} },
      { text: this.$t("bank.bank_name"), value: "bank_name" },
      { text: this.$t("bank.name"), value: "name" },
      { text: this.$t("bank.number"), value: "number" },
      { text: this.$t("bank.amount"), value: "amount", currency: true },
      { text: this.$t("bank.amount_maximum"), value: "amount_maximum", currency: true },
      { text: this.$t("bank.amount_current"), value: "amount_current", currency: true },
      { text: this.$t("bank.active"), value: "active", enum: {'0': this.$t('active.0'), '1': this.$t('active.1')} },
      { text: this.$t("common.tool"), value: "tool" },
    ]
    };
  },
  created() {},
  async mounted() {
    this.isLoading = true;
    await this.get_banks({});
    this.isLoading = false;
  },
  beforeMount() {},
  methods: {
    ...mapActions("bank", ["get_banks"]),

    open_create() {
      this.$set(this, 'dialog_data', null)
      this.dialog_action = 'create'
      this.dialog = true
    },
    open_edit(item) {

      this.$set(this, 'dialog_data', JSON.parse(JSON.stringify(item)))
      this.dialog_action = 'edit'
      this.dialog = true
    },
    item_class(item, hover) {
      if (!item) return ''
      if (!item.active) return `blue-grey ${hover ? 'lighten-3' : 'grey--text lighten-5'} `
      return ''
    }
  },
  computed: {
    ...mapGetters("bank", ["banks"]),
    ...mapGetters('user', ['profile']),
    canCreate () {
      return  this.profile.chkAction(['bank_action_all','bank_action_create'])
    },
    canEdit () {
      return this.profile.chkAction(['bank_action_all','bank_action_update'])
    }
  },
};
</script>
