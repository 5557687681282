import { call } from '../plugins/axios'
export default {
  namespaced: true,
  state: () => ({
    coupon_members: [],
    total: 0
  }),
  getters: {
    coupon_members: state => state.coupon_members,
    total: state => state.total
  },
  mutations: {
    setCouponMembers: (state, value) => state.coupon_members = value,
    setTotal: (state, value) => state.total = value
  },
  actions: {
    getCouponMembers: async ({ commit, dispatch }, data) => {
      return await call('coupon-member/list', data)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else {
            commit('setCouponMembers', data.rows || [])
            commit('setTotal', data.count || 0)
          }
          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
          return { error: 500 }
        })
    }
  }
}
