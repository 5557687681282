<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex align-center py-3">
      <div>
        <div class="display-1">{{ $t("member.text") }}</div>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0 py-2"></v-breadcrumbs>
      </div>
      <v-spacer></v-spacer>
      <div v-if="show_type === 'input'">
        <v-btn @click="reset" color="red" text>reset</v-btn>
        <v-btn @click="submit" :loading="isLoading" color="primary">{{
        formTypeText
        }}</v-btn>
      </div>
    </div>

    <v-form ref="form">
      <div class="d-flex flex-column flex-md-row align-stretch">
        <v-card :loading="isLoading" :disabled="isLoading" class="ma-2 flex-grow-1">
          <v-card-title>
            info
            <v-spacer />
            <v-btn @click="openDialog = true" icon>
              <v-icon color="primary">mdi-chess-queen</v-icon>
            </v-btn>
            <v-btn v-if="canCasinoSetting" @click="openCasinoDialog = true" icon>
              <v-icon color="primary">mdi-slot-machine-outline</v-icon>
            </v-btn>
            <v-btn v-if="canManualCreate" @click="openManualDialog = true" icon>
              <v-icon color="primary">mdi-currency-usd</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="row py-0 my-0">
              <div class="col-12 col-md-3 py-0 my-0">
                <input-or-text :show="show_type" v-model="member.ufa_user" :disabled="form_type !== 'create'"
                  :rules="[validate.required()]" :label="$t('member.ufa_user')" :error-messages="errors.user" />
              </div>
              <div class="col-12 col-md-9 py-0 my-0">
                <input-or-text :show="show_type" v-model="password" :rules="[validate.nullable(validate.text(6))]"
                  :label="$t('member.password')" :error-messages="errors.password">
                </input-or-text>
                <div v-if="member.password" class="text-end grey--text text-caption">รหัสผ่านเก่า {{ member.password || '-' }}</div>
              </div>
            </div>
            <div class="row py-0 my-0">
              <div class="col py-0 my-0">
                <input-or-text :show="show_type" v-model="member.tel" :rules="[validate.required()]"
                  :label="$t('member.tel') + ' *'" :error-messages="errors.tel" />
              </div>
              <div class="col py-0 my-0">
                <input-or-text :show="show_type" v-model="member.otp" :rules="[validate.text(0, 6)]"
                  :label="$t('member.otp')" :error-messages="errors.otp" />
              </div>
            </div>
            <input-or-text :show="show_type" v-model="member.line" :rules="[validate.text(0, 191)]"
              :label="$t('member.line')" :error-messages="errors.line" />

            <input-or-text :show="show_type" v-model="member.reference" :label="$t('member.reference')"
              :error-messages="errors.reference" />
          </v-card-text>
        </v-card>
        <v-card :loading="isLoading" :disabled="isLoading" class="ma-2 flex-grow-1">
          <v-card-title>bank</v-card-title>
          <v-card-text>
            <input-or-text :show="show_type" v-model="member.bank_name" :items="banks"
              :label="$t('member.bank_name') + ' *'" :error-messages="errors.bank_name" />
            <input-or-text :show="show_type" v-model="member.bank_user" :rules="[validate.required()]"
              :label="$t('member.bank_user') + ' *'" :error-messages="errors.bank_user" />
            <div class="row py-0 my-0">
              <div class="col py-0 my-0">
                <input-or-text :show="show_type" v-model="member.bank_number" :rules="[validate.required()]"
                  :label="$t('member.bank_number') + ' *'" :error-messages="errors.bank_number" />
              </div>
              <div class="col py-0 my-0">
                <input-or-text :show="show_type" v-model="member.bank_deposit_id" :label="$t('member.bank_deposit_id')"
                  :error-messages="errors.bank_deposit_id" />
              </div>
              <div class="col py-0 my-0">
                <input-or-text :value="member.first_deposit_id" :label="$t('member.first_deposit_id')" />
              </div>
            </div>
            <div class="row py-0 my-0">
              <div class="col py-0 my-0">
                <input-or-text :show="show_type" v-model="member.deposit_remark_th"
                  :label="$t('member.deposit_remark_th')" :error-messages="errors.deposit_remark_th" />
              </div>
              <div class="col py-0 my-0">
                <input-or-text :show="show_type" v-model="member.deposit_remark_en"
                  :label="$t('member.deposit_remark_en')" :error-messages="errors.deposit_remark_en" />
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-form>

    <v-dialog v-if="openDialog" v-model="openDialog" width="70%">
      <v-card>
        <v-card-text height="70%">
          <member-win-lost-page :member_id="current.ufa_user" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <casino-dialog v-if="canCasinoSetting" v-model="openCasinoDialog" :ufa_user="member.ufa_user || ''"></casino-dialog>

    <div class="d-flex my-2 justify-end">
      <v-btn v-if="show_type === 'input'" @click="reset" color="red" text>reset</v-btn>
      <v-btn v-if="show_type === 'input'" @click="submit" :loading="isLoading" color="primary">{{
      formTypeText
      }}</v-btn>
    </div>

    <v-dialog v-if="openManualDialog" v-model="openManualDialog" width="70%">
      <member-manual-page :member_id="current.ufa_user" @complete="openManualDialog = false"/>
    </v-dialog>
  </div>
</template>

<script>
import validate from "../../helper/validates.js";
import banks from "../../configs/banks.js";
import InputOrText from "../../components/common/InputOrText.vue";
import MemberWinLostPage from "./MemberWinLostPage.vue";
import CasinoDialog from "../../components/casino/CasinoDialog.vue";
import MemberManualPage from "./MemberManualPage.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    MemberManualPage,
    InputOrText,
    MemberWinLostPage,
    CasinoDialog
  },
  data() {
    return {
      openManualDialog: false,
      openCasinoDialog: false,
      openDialog: false,
      //page init
      password: "",
      isLoading: false,
      breadcrumbs: [
        {
          text: this.$t("member.text"),
        },
        {
          text: this.$t("common.list"),
          disabled: false,
          href: "/members",
        },
        {
          text: this.$t("member.text"),
        },
      ],

      //form type
      show_type: "text",
      validate: validate,
      form_type: "update",
      errors: {},
      member: {},
      banks: banks,
    };
  },
  created() {},
  async mounted() {
    this.form_type = this.$route.meta.type;

    if (["create", "update"].includes(this.form_type)) this.show_type = "input";
    else this.show_type = "text";

    if (this.$route.params.id) {
      this.isLoading = true;
      await this.get_member(this.$route.params.id)
        .then(() => {
          this.$set(this, "member", JSON.parse(JSON.stringify(this.current)));
        })
        .catch(() => {
          this.$router.push({ name: "member-list" });
        });
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions("app", ["showError", "showSuccess"]),
    ...mapActions("member", [
      "get_member",
      "create_member",
      "update_member",
      "clear_current_member",
    ]),

    reset() {
      this.$set(this, "error", {});
      this.$set(this, "member", JSON.parse(JSON.stringify(this.current)));
    },
    async submit() {
      if (!this.$refs.form.validate())
        return this.showError({
          error: { messag: this.$t("message.not_validate") },
        });

      let action = null;
      if (this.form_type === "create") action = this.create_member;
      else if (this.form_type === "update") action = this.update_member;

      if (action === null) return;

      this.isLoading = true;
      await action({ ...this.member, password: this.password }).then(() => {
        this.showSuccess(this.$t(`message.${this.form_type}_success`));
        //this.$router.push({ name: 'member-list' })
        //this.clear_current_member()
      });
      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters("member", ["current"]),
    ...mapGetters("user", ["profile"]),
    canCasinoSetting() {
      if(!this.profile) return false
      return this.profile.chkAction([
        'member_action_all',
        'member_action_casino_setting',
      ])
    },
    canManualCreate() {
      if(!this.profile) return false
      return this.profile.chkAction([
        'manual_action_credit',
      ])
    },
    formTypeText() {
      if (!this.form_type) return "";
      return `${
        this.form_type.charAt(0).toUpperCase() + this.form_type.slice(1)
      } Member`;
    },
  },
};
</script>

<style>
</style>
