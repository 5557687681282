<template>
  <page-blank-layout :title="title" :breadcrumbs="breadcrumbs">
    <v-card>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-for="config in configs" :key="config.key">
                <td>{{ config.key }}</td>
                <td>
                  <v-text-field
                    v-if="!config.choice || config.choice.indexOf(',') === -1"
                    type="text"
                    v-model="config.value"
                  ></v-text-field>
                  <v-select
                    v-else
                    v-model="config.value"
                    :items="
                      config.choice
                        ? config.choice
                            .split(',')
                            .map((k) => ({ value: k, text: k }))
                        : []
                    "
                  ></v-select>
                </td>
                <td>
                  <v-btn @click="update(config.key, config.value)" :loading="isLoading">Update</v-btn>
                </td>
                <td>{{ config.description }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </page-blank-layout>
</template>

<script>
import PageBlankLayout from "../../layouts/PageBlankLayout.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { PageBlankLayout },
  data() {
    return {
      isLoading: false,
      title: "title",
      breadcrumbs: [{ text: "main" }, { text: "list" }],
    };
  },
  created() {},
  mounted() {
    this.getConfigs()
  },
  methods: {
    ...mapActions('config', ['getConfigs', 'updateConfigs']),
    async update(key, value) {
      this.isLoading = true;
      await this.updateConfigs({key, value});
      this.isLoading = false;
    }
  },
  computed: {
    ...mapGetters('config', ['configs'])
  },
};
</script>

<style>
</style>
