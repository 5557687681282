<template>
  <page
    :title="$t('user.text')"
    :breadcrumbs="breadcrumbs"
    :loading="isLoading"
    :headers="headers"
    :options.sync="options"
    :items="users"
    :total="total"
    :filter="filter"
    @update:options="onSearch"
  >
      <template slot="btn-right">
        <v-btn v-if="canCreate" color="primary" :to="{name: 'user-create' }">{{ $t('common.create') }}</v-btn>
      </template>
      <template slot="filters">
        <v-text-field v-model='filter.name' class="mx-1" :placeholder="$t('user.name')" hide-details></v-text-field>
        <v-text-field v-model='filter.username' class="mx-1" :placeholder="$t('user.username')" hide-details></v-text-field>
        <v-text-field v-model='filter.role' :item="role_options" class="mx-1" :placeholder="$t('user.role')" hide-details></v-text-field>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        {{ $t('active.'+item.active) }}
      </template>
      <template v-slot:[`item.tool`]="{ item }">
        <group-tool :value="item"/>
      </template>
  </page>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
//import options from '../../configs/options'

import Page from '../../layouts/PageLayout.vue'
import GroupTool from '../../components/user/GroupTool.vue'

export default {
  components: {
    Page, GroupTool
  },
  data() {
    return {
      isLoading: false,
      breadcrumbs: [
        { text: this.$t('user.text') },
        { text: this.$t('common.list') }
      ],
      options: {},
      headers: [
        { text: this.$t('user.id'), value:'id', sortable: false },
        { text: this.$t('user.name'), value: 'name' },
        { text: this.$t('user.username'), value: 'username' },
        { text: this.$t('user.role'), value: 'role'},
        { text: this.$t('user.active'), value: 'active' },
        { text: this.$t('common.tool'), value: 'tool', sortable: false },
      ],
      role_options: [
        //'owner','admin','agent','guest'
        { text: 'owner', value: 'owner' },
        { text: 'admin', value: 'admin' },
        { text: 'agent', value: 'agent' },
      ],
      filter: {
        name: '',
        username: '',
        role: ''
      },
    }

    //active_options: options.active
  },
  created() {},
  mounted() {},
  beforeMount() {},
  methods: {
    ...mapActions('user', ['load']),
    async onSearch () {
      this.isLoading = true
      await this.load(this.options)
      this.isLoading = false
    },
    goto_create () {
      this.$router.push({ name: 'user-create' })
    }
  },
  computed: {
    ...mapGetters('user', ['profile', 'users', 'total']),
    canCreate () {
      if (!this.profile) return false
      return this.profile.chkAction(['user_action_all', 'user_action_create'])
    }
  }
}
</script>
