import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import AppModule from './app.store.js'
import AuthModule from './auth.store.js'
import MemberModule from './member.store.js'
import DepositModule from './deposit.store.js'
import WithdrawModule from './withdraw.store.js'
import UserModule from './user.store.js'
import BankModule from './bank.store.js'
import StatementModule from './statement.store.js'
import ConfigModule from './config.store.js'
import SMSModule from './sms.store.js'
import WinlostModule from './winlost.store.js'
import CouponModule from './coupon.store.js'
import CouponMemberModule from './coupon_member.store.js'
import HistoryModule from './history.store.js'
import OutstandModule from './outstand.store.js'
import EventSportModule from './sport.store.js'
import EventLotteryModule from './lottery.store.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app: AppModule,
    auth: AuthModule,
    member: MemberModule,
    deposit: DepositModule,
    withdraw: WithdrawModule,
    user: UserModule,
    bank: BankModule,
    statement: StatementModule,
    config: ConfigModule,
    sms: SMSModule,
    winlost: WinlostModule,
    coupon: CouponModule,
    coupon_member: CouponMemberModule,
    history: HistoryModule,
    outstand: OutstandModule,
    event_sport : EventSportModule,
    event_lottery : EventLotteryModule
  },
  plugins: [
    createPersistedState({
      key: 'ak',
      paths: ['auth.token', 'app.local', 'auth.hash']
    })
  ]
})

export default store
