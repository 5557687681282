import { call } from '../plugins/axios'
export default {
  namespaced: true,
  state: () => ({
    list: [],
    detail: [],
  }),
  getters: {
    list: state => state.list,
    detail: state => state.detail,
  },
  mutations: {
    setList(state, payload) {
      state.list = payload
    },
    setDetail(state, payload) {
      state.detail = payload
    }
  },
  actions: {
    getList: async ({ commit, dispatch }) => {
      return await call('/outstand/list', null)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else {
            commit('setList', data.data || [])
          }
          return data
        })
    },
    getDetail: async ({ commit, dispatch}, member) => {
      return await call('/outstand/detail', { ufa_user: member })
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else {
            commit('setDetail', data.data || [])
          }
          return data
        })
    }
  }
}
