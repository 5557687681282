<template>
  <page :title="$t('transfer_history.text')" :breadcrumbs="breadcrumbs" :loading="isLoading">
    <v-card>
      <v-card-text>
        <v-form ref="form" class="d-flex align-center">
          <v-text-field
            v-model="txtSearch"
            :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
            @keyup.stop="txtSearch = txtSearch.toUpperCase().trim()"
            class="mx-1"
          />
          <v-btn @click="onReset" text>{{ $t('form.reset') }}</v-btn>
          <v-btn @click="onSubmit" :loading="isLoading">{{ $t('form.submit') }}</v-btn>
        </v-form>
        <member-transfer v-if="!isLoading && histories.member"></member-transfer>
      </v-card-text>
    </v-card>
  </page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Page from '../../layouts/PageBlankLayout.vue'
import MemberTransfer from './MemberTransferPage.vue'
export default {
  components: {
    Page, MemberTransfer
  },
  data() {
    return {
      isFirst: true,
      isLoading: false,
      breadcrumbs: [
        {
          text: this.$t('transfer_history.text'),
        },
        { text: this.$t('common.list') },
      ],
      txtSearch: ''
    }
  },
  created() { },
  mounted() { },
  methods: {
    ...mapActions('history', ['getMemberTransferHistory', 'clear']),
    onSubmit() {
      if (!this.$refs.form.validate()) return
      this.isLoading = true
      this.clear()
      this.getMemberTransferHistory(this.txtSearch)
        .finally(() => {
          this.isLoading = false
        })
    },
    onReset() {
      this.txtSearch = ''
      this.clear()
    }
  },
  computed: {
    ...mapGetters('history', ['histories']),
  }
}
</script>

<style>
</style>
