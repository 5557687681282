import { call } from '../plugins/axios'
export default {
  namespaced: true,
  state: () => ({
    coupons: [],
    total: 0,
    current: {}
  }),
  getters: {
    coupons: (state) => state.coupons,
    total: (state) => state.total,
    current: (state) => state.current,
  },
  mutations: {
    setCoupons: (state, value) => state.coupons = value,
    setTotal: (state, value) => state.total = value,
    setCurrent: (state, value) => state.current = value,
    updateCoupon: (state, value) => {
      const index = state.coupons.findIndex(item => item.id === value.id)
      if (index !== -1) {
        console.log(index)
        Object.assign(state.coupons[index], value)
      }
      if (state.current.id === value.id) state.current = value
    }
  },
  actions: {
    getCoupons: async ({ commit, dispatch }, data) => {
      return await call('coupon/list', data)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else {
            commit('setCoupons', data.rows || [])
            commit('setTotal', data.count || 0)
          }
          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
          return { error: 500 }
        })
    },
    createCoupon: async ({ dispatch}, data) => {
      return await call('coupon/create', data)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
          return { error: 500 }
        })
    },
    updateCoupon: async ({ commit, dispatch}, data) => {
      return await call('coupon/update', data)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          if (data.change) commit('updateCoupon', data.change)
          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
          return { error: 500 }
        })
    },
    setCurrentCoupon: async ({ commit }, data) => {
      commit('setCurrent', data)
    }
  }
}
