import Vue from 'vue'
import axios from 'axios'
import { auth } from './firebase'


Vue.prototype.$axios = axios

const errorHandler = (err, dispatch) => {
  if (dispatch && err.error && err.message['error'])
    if (err.message['error'])
      dispatch('app/showError', { error: { message: err.message['error'] } }, { root: true })
    else
      dispatch('app/showError', { error: { message: 'message.system_fail' } }, { root: true })
  return err
}

const api = axios.create({
  baseURL: ['8082', '8084'].includes(location.port)? 'http://localhost:8088/admin/': 'https://ak55bet-e3959.as.r.appspot.com/admin/',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Access-Control-Allow-Origin': '*'
  }
})
 api.interceptors.request.use(async (config) => {
  if (auth.currentUser) {
    await auth.currentUser.getIdToken(true)
    .then(token => {
      config.headers.common['Authorization'] = `bearer ${ token }`
    })
  }

  return config
}, null)
api.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error && error.response) return Promise.reject(error.response.data)
  return Promise.reject(error)
})

const call = (url, data) => {
  return api.post(url, { data })
    .then((res) => {
      if (res.data.error) throw res.data
      return res
    })
}

const get = (url) => api.get(url)

export { axios, call, get, errorHandler, api }
export default axios
