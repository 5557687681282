import tmp from '../configs/permissions'

export const tmpPermissions = tmp.tmpPermissions
export const tmpGroups = tmp.tmpGroups

export class Profile {
  permissions = {}
  userPermissions = []

  constructor ({permissions, ...user}) {
    Object.keys(user).forEach(key => this[key] = user[key])
    if(permissions) {
      permissions.forEach(p => {
        const group = p.split(':')[0]
        const permission = p.split(':')[1].split(',')
        this.permissions[group] = permission
      })
      this.userPermissions = this._getPermissions()
    }
  }

  clearAndUpdate({permissions, ...user}) {
    Object.keys(user).forEach(key => this[key] = user[key])
    this.permissions = {}
    this.userPermissions = []

    if(permissions) {
      permissions.forEach(p => {
        const group = p.split(':')[0]
        const permission = p.split(':')[1].split(',')
        this.permissions[group] = permission
      })
      this.userPermissions = this._getPermissions()
    }

  }

  add (id) {
    const index = Object.values(tmpGroups).findIndex(p => p.includes(id))
    if (index < 0) return
    const group = Object.keys(tmpGroups)[index]
    if (!this.permissions[group]) this.permissions[group] = []
    if (this.permissions[group].includes(id)) return
    this.permissions[group].push(id)
    this.userPermissions = this._getPermissions()
  }

  remove (id) {
    const index = Object.values(tmpGroups).findIndex(p => p.includes(id))
    if (index < 0) return
    const group = Object.keys(tmpGroups)[index]
    this.permissions[group] = this.permissions[group].filter(p => p !== id)
    this.userPermissions = this._getPermissions()
  }

  getArray () {
    return Object.keys(this.permissions).reduce((acc, cur) => {
      if (this.permissions[cur].length > 0) {
        acc.push(`${cur}:${this.permissions[cur].join(',')}`)
      }
      return acc
    }, [])
  }

  _getPermissions () {
    const permissions = []
    Object.keys(this.permissions).forEach(k => {
      this.permissions[k].forEach(p => {
        permissions.push(tmpPermissions[p].key)
      })
    })
    return permissions
  }

  getRawPermissions() {
    return Object.keys(tmpGroups).reduce((acc, cur) => {
      acc[cur] = []
      tmpGroups[cur].forEach(p => {
        acc[cur].push({
          ...tmpPermissions[p],
          active: (this.permissions[cur] || []).includes(p)
        })
      })
      if (acc[cur].length) acc[cur].sort((a, b) => a.order - b.order)
      return acc
    }, {})
  }

  chkAction(rules) {
    if (!this.userPermissions.length) this.userPermissions = this._getPermissions()

    let chkRules = rules
    if (!Array.isArray(rules)) chkRules = [rules]

    chkRules = ['action_all', ...chkRules]

    return chkRules.filter(cr => {
      return this.userPermissions.includes(cr)
    }).length > 0
  }

  chkMenu(rules) {
    if (!this.userPermissions.length) this.userPermissions = this._getPermissions()

    let chkRules = rules
    if (!Array.isArray(rules)) chkRules = [rules]

    chkRules = ['menu_all', ...chkRules]

    return chkRules.filter(cr => {
      return this.userPermissions.includes(cr)
    }).length > 0
  }

  getPermissions() {
    if (!this.userPermissions.length) this.userPermissions = this._getPermissions()

    return this.userPermissions || []
  }
}
