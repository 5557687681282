<template>
  <v-card>
    <v-card-text>
      <div class="d-flex align-center my-1">
        profile
        <v-sheet rounded class="grey lighten-4 mx-5 px-2 py-1" min-height="37">
          {{ profile.name }} ( {{ profile.role }} )
        </v-sheet>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      permissions: ['menu_all', 'menu_bank', 'no_permission']
    }
  },
  created() { },
  mounted() { },
  methods: {},
  computed: {
    ...mapGetters('user', ['profile'])
  }
}
</script>

<style>
</style>
