<template>
  <page
    :title="$t(`withdraw.waiting_${waiting}`)"
    :breadcrumbs="breadcrumbs"
    :loading="isLoading"
    :headers="headers"
    :options.sync="options"
    :items="withdraws"
    :total="total"
    :filter="filter"
    @update:options="onSearch"
  >
    <template slot="btn-right"></template>
    <template slot="filters">
      <v-text-field
        v-model="filter['member.ufa_user']"
        :label="$t('member.ufa_user')"
        class="mx-1"
      ></v-text-field>
      <v-text-field
        v-model="filter['withdrawals.amount']"
        type="number"
        :label="$t('withdraw.amount')"
        class="mx-1"
      ></v-text-field>
    </template>
    <template v-slot:[`item.member.ufa_user`]="{ item }">
      <a :href="'?user=' + item.member.ufa_user">{{ item.member.ufa_user }}</a>
    </template>
    <template v-slot:[`item.member.bank_name`]="{ item }">
      {{ item.member.bank_name }}
    </template>
    <template v-slot:[`item.fee`]="{ item }">
      {{ item.fee || 0 }}
    </template>
    <template v-slot:[`item.amount`]="{ item }">
      <div class="d-flex justify-end">
        {{
          item.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") || "0.00"
        }}
      </div>
    </template>
    <template v-slot:[`item.total`]="{ item }">
      {{ item.amount - item.fee }}
    </template>
    <template v-slot:[`item.comment`]="{ item }">
      {{ item.comment || "-" }}
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <v-chip v-if="item.complete" color="green">{{
        $t("withdraw.status_success")
      }}</v-chip>
      <v-chip v-else-if="item.confirm && !item.complete" color="yellow">{{
        $t("withdraw.status_waiting_tranfer")
      }}</v-chip>
      <v-chip v-else-if="!item.active" color="red">{{
        $t("withdraw.status_cancel")
      }}</v-chip>
      <v-chip v-else>{{ $t("withdraw.status_waiting") }}</v-chip>
    </template>
    <template v-slot:[`item.tool`]="{ item }">
      <group-tool
        :value="item"
        :waiting="waiting"
        @change="onSearch"
      />
    </template>
  </page>
</template>
<script>
import { mapActions, mapGetters } from "vuex"

import GroupTool from '../../components/withdraw/GroupTool.vue'
import Page from "../../layouts/PageLayout.vue"
export default {
  components: {
    Page,
    GroupTool
  },
  data() {
    return {
      //page init
      isLoading: false,
      dialog: false,
      breadcrumbs: [
        { text: this.$t("withdraw.text") },
        { text: this.$t("common.list") },
      ],
      options: {},
      headers: [
        { text: this.$t("withdraw.id"), value: "id", sortable: false },
        { text: this.$t("member.ufa_user"), value: "member.ufa_user" },
        { text: this.$t("bank.text"), value: "member.bank_name" },
        { text: this.$t("withdraw.amount"), value: "amount" },
        { text: this.$t("withdraw.fee"), value: "fee" },
        { text: this.$t("withdraw.total"), value: "total" },
        { text: this.$t("withdraw.comment"), value: "comment" },
        { text: this.$t("withdraw.created_at"), value: "created_at" },
        { text: this.$t("withdraw.status"), value: "status", sortable: false },
        { text: this.$t("withdraw.active"), value: "active", sortable: false },
        { text: this.$t("common.tool"), value: "tool" },
      ],
      waiting: "all",
      current: 0,
      isEdit: false,
      filter: {
        "member.ufa_user": "",
        amount: "",
      },
    }
  },
  created() {},
  mounted() {
    if (this.$route.meta.waiting) this.waiting = this.$route.meta.waiting
    if (this.$route.query.user)
      this.filter["member.ufa_user"] = this.$route.query.user
  },
  beforeMount() {},
  methods: {
    ...mapActions("withdraw", ["load", "toggleActive"]),
    async onSearch() {
      let sort = {}
      if (!this.options.sortBy || this.options.sortBy.length == 0) {
        sort["sortBy"] = ["id"]
        sort["sortDesc"] = [true]
      }
      this.isLoading = true
      await this.load({
        ...this.options,
        ...sort,
        filter: this.filter,
        waiting: this.waiting,
      })
      this.isLoading = false
    },
    async active(id) {
      this.isLoading = true
      await this.toggleActive(id)
      this.isLoading = false
    },
    openDialog(id, isEdit) {
      this.isEdit = isEdit;
      this.current = id;
      this.dialog = true;
    },
  },
  computed: {
    ...mapGetters("withdraw", ["withdraws", "total"]),
  },
  watch: {
    $route() {
      if (this.$route.meta.waiting) this.waiting = this.$route.meta.waiting
      if (this.$route.query.user)
        this.filter["member.ufa_user"] = this.$route.query.user
      this.options = { page: 1 }
      this.onSearch()
    },
  }
}
</script>
