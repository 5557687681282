<template>
  <page-blank-layout :title="`Member Win/Lost ( ${member_id} )`">
    <v-simple-table fixed-header
      :loading="isLoading"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th style="width:2%"></th>
            <th style="width:13%">เวลาการดำเนินการ</th>
            <th style="width:28%;text-align:right">เลือก</th>
            <th style="width:5%;text-align:right">อัตราต่อรอง</th>
            <th style="width:5%;text-align:right">การเดิมพัน</th>
            <th style="width:5%;text-align:right">ชนะ/แพ้</th>
            <th style="width:6%;text-align:center">สถานะ</th>

            <th style='width:7%;text-align:right'>เอเย่นต์ / คอมมิชชั่น</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="8">
              <v-progress-linear
                indeterminate
                color="yellow darken-2"
              ></v-progress-linear>
            </td>
          </tr>
          <tr v-for="item in lists" :key="item[0]">
            <td v-html="item[0]"></td>
            <td v-html="item[1]"></td>
            <td v-html="item[2]"></td>
            <td v-html="item[3]"></td>
            <td v-html="item[4]"></td>
            <td v-html="item[5]"></td>
            <td v-html="item[6]"></td>
            <td v-html="item[7]"></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-pagination v-model="page" :length="total"></v-pagination>
  </page-blank-layout>
</template>

<script>
import PageBlankLayout from '../../layouts/PageBlankLayout.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: { PageBlankLayout },
  props: {
    member_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      page: 1
    }
  },
  computed: {
    ...mapGetters('winlost', ['lists', 'total'])
  },
  watch: {
    async page(value) {
      if (value) {
        this.$emit('loading', true)
        this.isLoading = true
        await this.loadList({ ufaUser: this.member_id, page: value })
        this.$emit('loading', false)
        this.isLoading = false
      }
    }
  },
  created() { },
  async mounted() {
    this.page = 1
    this.clear()
    this.$emit('loading', true)
    this.isLoading = true
    await this.loadList({ ufaUser: this.member_id, page: this.page })
    this.$emit('loading', false)
    this.isLoading = false
  },
  methods: {
    ...mapActions('winlost', ['loadList', 'clear']),
  },
}
</script>

<style>
</style>
