import Vue from 'vue'
import Router from 'vue-router'
import { check } from '../plugins/firebase'

//import MemberRoutes from './member.router'

Vue.use(Router)

//console.log(UsersRoutes)

import routes from '../configs/navs.js'

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: routes.filter(r => r.path)
})

const checkAuth = async () => {
  const vuex = router.app.$store
  if (vuex.getters['auth/authenticated']) {
    return true
  }
  return await check()
  .then(async (user) => {
    if (!user) return false

    if (!vuex.getters['user/profile']['role']) {
      const sleep = (time) => new Promise((resolve) => {
        setTimeout(resolve, time)
      })
      for(let i=0; i<1000; i++) {
        await sleep(100)
        if (vuex.getters['user/profile']['role']) {
          break
        }
      }
    }

    vuex.commit('auth/setAuthenticated', !!user, { root: true })
    return true
  })
  .catch(() => false)
}
/**
 * Before each route update
 */

router.beforeEach(async (to, from, next) => {
  if (to.name == 'login') return next()

  if (to.meta.where) {
    let pass = true
    Object.keys(to.meta.where).forEach((key) => {
      if (pass && !!to.params[key]) pass = to.meta.where[key](to.params[key])
    })
    if (!pass) return next({ name: 'error' })
  }

  const isAuth = await checkAuth()
  const vuex = router.app.$store
  const user = vuex.getters['user/profile']
  const config = vuex.getters['app/config']
  const ip = vuex.getters['auth/ip']

  if (to.meta.auth && !isAuth) {
    router.app.$store.dispatch('app/showError', { error: { message: 'message.not_visit' } }, { root: true })
    if (to.name != 'login') return next({ name: 'login' })
    return
  }

  if (to.meta.role && !to.meta.role.includes(user.role || '')) {
    if (from.name === null) return next({ name: 'home' })
    return
  }

  console.log(user.getPermissions())

  if (config.access_ip && !user.getPermissions().includes('ip_unlock')) {
    if (!config.access_ip.split(',').includes(ip)) {
      router.app.$store.dispatch('app/showError', { error: { message: 'ไม่สามารถเข้าใช้งานได้ กรุณาตรวจสอบ ip' } }, { root: true })
      return next({ name: 'login' })
    }
  }

  if (to.meta.permissions && user.getPermissions().includes('menu_all')) {
    return next()
  }
  else if (
    to.meta.permissions
    && to.meta.permissions.filter(p => user.getPermissions().includes(p)).length === 0
  ) {
    if (from.name === null) return next({ name: 'home' })
    return
  }

  return next()
})

  /**
   * After each route update
   */
  //router.afterEach(async (to, from) => {
    // if (to.name === 'logout') {
    //   const vuex = router.app.$store
    //   vuex.commit('auth/setToken', null, { root: true })
    //   vuex.commit('auth/setUser', {}, { root: true })
    //   vuex.commit('auth/authenticated', false, { root: true })
    //   router.push('login')
    // }
  //})

export default router
