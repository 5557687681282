<template>
  <div class="text-center">
    <v-dialog v-model="dialog" :width="isLoading ? '300px' : '70%'">
      <v-card v-if="isLoading">
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t("withdraw.text") }}
        </v-card-title>
        <v-card-text class="d-flex justify-center py-2" style="height: 100px">
          <v-progress-circular
            :size="70"
            :width="7"
            color="warning"
            indeterminate
            >loading</v-progress-circular
          >
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t("withdraw.text") }}
          <v-spacer></v-spacer>
          <div class="d-flex flex-column">
            <div style="font-size: 0.7em">
              {{ $t("withdraw.created_at") }}
              <span style="font-size: 1em">{{ current.created_at }}</span>
            </div>
            <div style="font-size: 0.7em">
              {{ $t("withdraw.updated_at") }}
              <span style="font-size: 1em">{{ current.updated_at }}</span>
            </div>
          </div>
        </v-card-title>

        <v-card-text class="py-2">
          <div class="row">
            <div class="col-12 py-0">
              <input-or-text
                :value="current.id || ''"
                :label="$t('withdraw.id')"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-7 py-0">
              <input-or-text
                :value="current.member ? current.member.ufa_user || '' : ''"
                :label="$t('member.user')"
              />
            </div>
            <div class="col-12 col-md-5 py-0">
              <input-or-text
                :value="current.member ? current.member.tel || '' : ''"
                :label="$t('member.tel')"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12 py-0">
              <input-or-text
                :value="
                  current.member
                    ? `${current.member.bank_name} ${current.member.bank_number}`
                    : ''
                "
                :label="$t('member.bank_name')"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-4 py-0">
              <input-or-text
                :value="
                  (current.amount || 0)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '0.00'
                "
                :label="$t('withdraw.amount')"
                text_class="red--text"
              />
            </div>
            <div class="col-12 col-md-4 py-0">
              <input-or-text
                :value="
                  (current.fee || 0)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '0.00'
                "
                :label="$t('withdraw.fee')"
              />
            </div>
            <div class="col-12 col-md-4 py-0">
              <input-or-text
                :value="
                  ((current.amount || 0) - (current.fee || 0))
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '0.00'
                "
                :label="$t('withdraw.total')"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-3 py-0">
              <input-or-text
                :value="current.confirm || ''"
                :label="$t('withdraw.confirm')"
              />
            </div>
            <div class="col-12 col-md-9 py-0">
              <input-or-text
                :value="
                  current.confirmBy ? current.confirmBy.username || '' : ''
                "
                :label="$t('withdraw.confirmBy')"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-3 py-0">
              <input-or-text
                :value="current.complete || ''"
                :label="$t('withdraw.complete')"
              />
            </div>
            <div class="col-12 col-md-9 py-0">
              <input-or-text
                :value="
                  current.completeBy ? current.completeBy.username || '' : ''
                "
                :label="$t('withdraw.completeBy')"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6 py-0">
              <input-or-text
                :value="current.before || ''"
                :label="$t('withdraw.before')"
              />
            </div>
            <div class="col-6 py-0">
              <input-or-text
                :value="current.after || ''"
                :label="$t('withdraw.after')"
              />
            </div>
          </div>
          <input-or-text
            :value="current.comment"
            :label="$t('deposit.comment')"
            :value-height="70"
          />
          <div
            v-if="is_edit"
            class="py-2 d-flex justify-end"
          >
            <button-cancel
              v-if="canToggleActive"
              :value="current.id"
              @submit="dialog = false"
              :action="cancelWithdraw"
            />
            <div v-if="(canConfirm || canSetConfirm) && waiting === 'confirm' && !disableConfirm"
              class="d-flex align-center mx-2"
            >
              <b>{{ $t("withdraw.confirm") }}</b>
              <v-btn
                v-if="canConfirm"
                :disabled="disableConfirm || actionLoading"
                :loading="loading.confirm"
                class="mx-1"
                color="red"
                dark
                @click="confirm"
              >
                {{ $t("common.auto") }}
              </v-btn>
              <v-btn
                v-if="canSetConfirm"
                :disabled="disableConfirm || actionLoading"
                :loading="loading.confirm"
                class="mx-1"
                color="green"
                dark
                @click="setConfirm"
              >
                {{ $t("common.byAdmin") }}
              </v-btn>
            </div>

            <div v-if="(canComplete || canTranferAndComplete) && waiting === 'complete' && !disableComplete"
              class="d-flex align-center mx-2"
            >
              <b>{{ $t("withdraw.complete") }}</b>
              <v-btn
                v-if="canTranferAndComplete"
                :disabled="actionLoading"
                :loading="loading.complete"
                class="mx-1"
                color="red"
                dark
                @click="dialog_bank = !dialog_bank"
              >
                {{ $t("common.auto") }}
              </v-btn>
              <v-btn
                v-if="canComplete"
                :disabled="actionLoading"
                :loading="loading.complete"
                class="mx-1"
                color="green"
                dark
                @click="complete"
              >
                {{ $t("common.byAdmin") }}
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_bank" width="50%">
      <v-card>
        <v-card-title>โอนเงินจากบัญชีธนาคาร</v-card-title>
        <v-card-text>
          <v-select v-model="withdraw_bank_id" :items="bank_options"></v-select>
          <div class="d-flex justify-end">
            <v-btn
              @click="tranfer"
              :loading="isTranferLoading"
              :disabled="!withdraw_bank_id"
              color="primary"
              >โอน</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ButtonCancel from "../common/ButtonCancel.vue";
import InputOrText from "../common/InputOrText.vue";
export default {
  components: {
    ButtonCancel,
    InputOrText,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    withdraw_id: {
      type: Number,
      default: 0,
    },
    is_edit: {
      type: Boolean,
      default: false,
    },
    waiting: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    isChange: false,
    isLoading: false,
    isTranferLoading: false,
    // dialog: false,
    dialog_bank: false,
    withdraw_bank_id: 0,
    id: 0,
    loading: {
      confirm: false,
      complete: false,
    },
  }),
  computed: {
    ...mapGetters("withdraw", ["current"]),
    ...mapGetters("user", ["profile"]),
    ...mapGetters("bank", ["bank_options"]),
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit("input", val)
      }
    },
    disableConfirm() {
      return (
        this.current.confirm == 1 ||
        this.current.complete == 1 ||
        this.current.active == 0
      );
    },
    disableComplete() {
      return (
        this.current.confirm == 0 ||
        this.current.complete == 1 ||
        this.current.active == 0
      );
    },
    actionLoading() {
      return (
        this.loading.confirm || this.loading.complete || this.loading.submit
      );
    },
    status() {
      if (!this.current) return "";
      if (!this.current.complete) return this.$t("withdraw.not_complete");
      else if (this.current.complete) return this.$t("withdraw.complete");
      else if (this.current.type != null && this.current.type == 1)
        return this.$t("withdraw.type1");
      else if (this.current.type != null && this.current.type != 1)
        return this.$t("withdraw.type2");
      else if (this.current.active) return this.$t("withdraw.cancel");
      else if (this.current.confirm) return this.$t("withdraw.confirm");
      else return this.$t("withdraw.waiting");
    },
    canEdit () {
      if (!this.profile) return false
      return this.profile.chkAction([
        'withdraw_action_all',
        'withdraw_action_update',
      ])
    },
    canComplete () {
      if (!this.profile) return false
      return this.profile.chkAction([
        'withdraw_action_all',
        'withdraw_action_completed',
      ])
    },
    canTranferAndComplete () {
      if (!this.profile) return false
      return this.profile.chkAction([
        'withdraw_action_all',
        'withdraw_action_transfer_and_complete',
      ])
    },
    canConfirm () {
      if (!this.profile) return false
      return this.profile.chkAction([
        'withdraw_action_all',
        'withdraw_action_comfirmed',
      ])
    },
    canSetConfirm () {
      if (!this.profile) return false
      return this.profile.chkAction([
        'withdraw_action_all',
        'withdraw_action_set_confirm',
      ])
    },
    canToggleActive () {
      if (!this.profile) return false
      return this.profile.chkAction([
        'withdraw_action_all',
        'withdraw_action_toggle_active',
      ])
    },
  },
  watch: {
    async dialog() {
      if (this.dialog) {
        this.isLoading = true;
        await this.getWithdraw(this.withdraw_id)
          .then((data) => {
            if (data.error) {
              this.dialog = false;
              this.$emit("input", false);
            }

            if (data.withdrawal) {
              this.get_banks({ amount: data.withdrawal.amount });
            }
          })
          .catch(() => {
            this.dialog = false;
            this.$emit("input", false);
          });
        this.isLoading = false;
      } else {
        this.clearCurrent();
        this.$emit("input", false);
        if (this.isChange) this.$emit("current_change");
      }
    }
  },
  created() {},
  mounted() {
    this.isChange = false;
  },
  methods: {
    ...mapActions("withdraw", [
      "getWithdraw",
      "clearCurrent",
      "confirmWithdraw",
      "setConfirmWithdraw",
      "completeWithdraw",
      "cancelWithdraw",
      "transferAndCompleteWithdraw",
    ]),
    ...mapActions("bank", ["get_banks"]),
    async confirm() {
      const pass = window.confirm(this.$t("message.withdraw_confirm"));
      if (pass) {
        this.loading.complete = true;
        await this.confirmWithdraw(this.current.id).then((data) => {
          if (!data.error) this.isChange = true;
          this.dialog = false;
          this.$emit("input", false);
        });
        this.loading.complete = false;
      }
    },
    async setConfirm() {
      const pass = window.confirm(this.$t("message.withdraw_confirm_message"));
      if (!pass) return

      this.loading.confirm = true;
      await this.setConfirmWithdraw(this.current.id).then((data) => {
        if (!data.error) this.isChange = true;
        this.dialog = false;
        this.$emit("input", false);
      });
      this.loading.confirm = false;
    },
    async complete() {
      const pass = window.confirm(this.$t("message.withdraw_complete_confirm_message"));
      if (!pass) return

      this.loading.complete = true;
      await this.completeWithdraw(this.current.id).then((data) => {
        if (!data.error) this.isChange = true;
        this.dialog = false;
        this.$emit("input", false);
      });
      this.loading.complete = false;
    },
    async tranfer() {
      this.isTranferLoading = true;
      await this.transferAndCompleteWithdraw({
        bank_id: this.withdraw_bank_id,
        id: this.current.id,
      }).finally(() => (this.dialog_bank = false));
      this.isTranferLoading = false;
    },
  },
};
</script>

<style>
</style>
