<template>
  <div>
    <v-card>
      <v-card-text>
        <v-form ref="form">
          <label-text label_text="ชื่อกิจกรรม" v-model="form.name" :input="actionType === 'create'"
            :error-messages="errors.name" />
          <v-row>
            <v-col @click="showStartDialog">
              <label-text label_text="เริ่มกิจกรรม" :value="start_at_th" :error-messages="errors.start_at"
                :input="actionType == 'create'" readonly />
            </v-col>
            <v-col @click="showExpDialog">
              <label-text label_text="สิ้นสุดกิจกรรม" :value="exp_at_th" :error-messages="errors.exp_at"
                :input="actionType == 'create'" readonly />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label-text type="number" label_text="ราคา" v-model="form.price" :error-messages="errors.price"
                :input="actionType === 'create'" />
            </v-col>
            <v-col>
              <label-text type="number" label_text="รางวัล" v-model="form.reword" :error-messages="errors.reword"
                :input="['create', 'update'].includes(actionType)"/>
            </v-col>
          </v-row>
        </v-form>
        <v-card>
          <v-card-text>
            <div class="d-flex justify-end">
              <v-btn v-if="actionType == 'create'" @click="addRow" text>
                เพิ่มรายการ
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
            <v-simple-table>
              <thead>
                <tr>
                  <th v-if="actionType == 'create'" class="text-left"></th>
                  <th class="text-center">
                    ทีมเย้า
                  </th>
                  <th class="text-center">
                    ทีมเยือน
                  </th>
                  <th v-if="actionType != 'create'" class="text-center">
                    คะแนน
                  </th>
                  <th v-if="actionType != 'create'" class="text-right">
                    ผลการแข่งขัน
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) of form.table" :key="index">
                  <td v-if="actionType == 'create'" class="text-left">
                    <v-btn @click="removeRow(index)" icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                  <td class="text-left">
                    <v-text-field v-if="actionType == 'create'" v-model="row.team1" hide-details outlined dense />
                    <span v-else>{{ row.team1 }}</span>
                  </td>
                  <td class="text-left">
                    <v-text-field v-if="actionType == 'create'" v-model="row.team2" hide-details outlined dense />
                    <span v-else>{{ row.team2 }}</span>
                  </td>
                  <td v-if="actionType != 'create'" width="150px">
                    <div v-if="actionType != 'show'" class="text-left d-flex align-center">
                      <v-select
                        v-model="row.score1"
                        :items="options.score || []"
                        items-
                        hide-details outlined dense
                      />
                      <span class="mx-1">-</span>
                      <v-select v-model="row.score2" :items="options.score || []" hide-details outlined dense />
                    </div>
                    <div v-else>
                      {{ row.score1 }} - {{ row.score2 }}
                    </div>

                  </td>
                  <td v-if="actionType != 'create'" width="350px" class="text-left">
                    <v-radio-group v-if="actionType != 'show'" v-model="row.result" row>
                      <v-radio label="ชนะ" value="win" />
                      <v-radio label="เสมอ" value="draw" />
                      <v-radio label="แพ้" value="lose" />
                      <v-radio label="ยกเลิก" value="cancel" />
                    </v-radio-group>
                    <div v-else> {{ row.result }} </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
        <div class="d-flex justify-end mt-2">
          <v-btn v-if="['create', 'update'].includes(actionType)" @click="submit" :loading="isLoading">บันทึก</v-btn>
        </div>
        {{ form.start_at }} {{ form.exp_at }}
      </v-card-text>
    </v-card>

    <TimePicker label_text="start_at" v-model="form.start_at" :show="showStart" @close="showStart = false" />
    <TimePicker label_text="exp_at" v-model="form.exp_at" :show="showExp" @close="showExp = false" />
  </div>
</template>

<script>
import LabelText from '../../../components/common/LabelText';
import TimePicker from '../../../components/common/DateTimePicker';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment'

const formDefault = {
  name: '',
  start_at: new Date().toString(),
  exp_at: new Date().toString(),
  price: '',
  reword: '',
  table: [],
}

export default {
  components: {
    LabelText,
    TimePicker
  },
  props: {
    actionType: {
      type: String,
      default: 'create'
    },
  },
  data() {
    return {
      isLoading: false,
      showStart: false,
      showExp: false,
      options: {
        score: Object.keys([...Array(20)]).map(i => ({
          text: i,
          value: String(i)
        })),
      },
      errors: {},
      form: {},
    };
  },
  mounted() {
    this.form = this.current ? this.currentToForm() : formDefault;
  },
  methods: {
    ...mapActions('event_sport', ['create', 'update', 'cancel']),
    addRow() {
      this.form.table.push({
        team1: '',
        team2: '',
        score1: '0',
        score2: '0',
        result: '',
      });
    },
    removeRow(index) {
      this.table.splice(index, 1);
    },
    async submit() {
      this.isLoading = true;
      const data = {
        ...this.form,
        table: this.form.table.map(t => ({
          team1: t.team1,
          team2: t.team2,
          score: (`${t.score1} - ${t.score2}`.trim()),
          result: t.result
        })),
      }
      const callFunc = this.actionType == 'create' ? this.create : this.update;
      const resData = await callFunc(data)
      if (!resData.error) this.$emit('success', resData);
      if (resData.errors) this.errors = resData.errors;
      this.isLoading = false;
    },
    async submitCancel() {
      this.isLoading = true;
      const resData = await this.cancel(this.current.id)
      if (!resData.error) this.$emit('success', resData);
      this.isLoading = false;
    },
    showStartDialog() {
      if (this.actionType == 'create') this.showStart = true;
    },
    showExpDialog() {
      if (this.actionType == 'create') this.showExp = true;
    },
    currentToForm() {
      return {
        ...this.current,
        table: this.current.table.map(t => ({
          team1: t.team1,
          team2: t.team2,
          score1: t.score.split('-')[0].trim(),
          score2: t.score.split('-')[1].trim(),
          result: t.result
        })),
      }
    }
  },
  computed: {
    ...mapGetters('event_sport', ['current']),
    start_at_th() {
      return moment(this.form.start_at).format('DD/MM/YYYY HH:mm');
    },
    exp_at_th() {
      return moment(this.form.exp_at).format('DD/MM/YYYY HH:mm');
    },
  },
  watch: {
    current(id) {
      this.$refs.form.resetValidation()
      this.errors = []
      let val = formDefault;

      if (id) val = this.currentToForm()
      this.$set(this, 'form', val);
    }
  },
}

</script>
