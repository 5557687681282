<template>
<div class="blue-grey lighten-5" style="position: absolute;bottom: 0;left: 0;right: 0;top: 0;">
  <v-navigation-drawer
    v-model="drawer"
    class="elevation-1 blue-grey lighten-4"
    app
  >
    <v-list dense>
      <v-subheader class="d-flex justify-center">
        ak55bet v.{{ version }}
      </v-subheader>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <template v-for="(item, i) in navigations">
          <v-list-item v-if="!item.children" :key="`${item.name}_${i}`" :to="item.path">
            <v-list-item-icon>
              <v-icon v-text="item.menu.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="$t(item.menu.text)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-else
            :value="item.children.map(i => i.name).includes($route.name)"
            :key="`${item.name}-${i}`"
            :prepend-icon="item.menu.icon"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ $t(item.menu.text) }}</v-list-item-title>
            </template>

            <v-list-item v-for="(sub, j) in item.children" :key="`${item.name}_${sub.name}_${j}`" :to="sub.path">
              <v-list-item-title class="pl-5">{{ $t(sub.menu.text) }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>

  <v-app-bar app>
    <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="primary"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-btn :to="{ name: 'home' }" text>{{ profile.name }} ( {{ profile.role }} )</v-btn>
    <v-btn @click="logout" icon dense>
      <v-icon color="primary">mdi-logout-variant</v-icon>
    </v-btn>
  </v-app-bar>

  <v-main>
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </v-main>

  <v-footer v-if="false" app class="d-flex justify-center">
    <span>
      Built with <v-icon small color="pink">mdi-heart</v-icon> <a class="text-decoration-none" >@J</a>
    </span>
  </v-footer>


</div>
</template>

<script>
const env = require('../../package.json')
import navs from '../configs/navs.js'

import { mapActions, mapGetters } from 'vuex'

export default {
  components: {

  },
  data() {
    return {
      version: env.version,
      drawer: true,
      language: [
        { text: 'Thailand', image: 'thai', value: 'th'},
        { text: 'English', image: 'english', value: 'en' },
      ],
      selectedItem: 1,
      items: navs
        .filter(n => n.menu)
        .map(n => {
          return {
            text: this.$t(n.menu.text),
            icon: n.menu.icon,
            goto: n.path,
            meta: n.meta
          }
        }),
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions('app', ['setLocale']),
    ...mapActions('auth', {'admin_logout': 'logout'}),
    async logout () {
      await this.admin_logout()
        .then(() => {
          this.$router.push({ name: 'login' })
        })
    },
    changeLanguage (text, image, locale) {
      //this.setLocale(locale)
      this.$i18n.locale = locale
    }
  },
  computed: {
    ...mapGetters('user', ['profile']),
    select_language () {
      const lang = this.language.find(r => r.value == this.$i18n.locale)
      if (!lang) return {}
      return lang
    },
    navigations () {
      if (!navs) return []
      const check = (l) => {
        if (!l.path) return false

        if (l.role && !this.profile || !this.profile.role) return false
        if (l.meta.role && !l.meta.role.includes(this.profile.role)) return false

        if (this.profile.getPermissions().includes('menu_all')) return true

        if (l.permissions && !this.profile || !this.profile.getPermissions()) return false
        if (l.meta.permissions && l.meta.permissions.filter(p => this.profile.getPermissions().includes(p)).length !== l.meta.permissions.length) return false

        return true
      }
      const m = navs.reduce((r, n) => {
        if (check(n) && n.menu) {
          if (r[n.menu.parent]) {
            if (!r[n.menu.parent].children) r[n.menu.parent].children = []
            r[n.menu.parent].children.push(n)
          } else {
            r[n.name] = n
          }
        }
        return r
      }, navs.filter(item => !item.path && item.menu).reduce((r, n) => {
        r[n.name] = n
        return r
      }, {}))

      const compare = ( a, b ) => {
        if ( a.order < b.order ){
          return -1;
        }
        if ( a.order > b.order ){
          return 1;
        }
        return 0;
      }

      return Object.values(m).filter(l => {
        if (l.path) return true
        if (!l.children || l.children.length === 0) return false
        return true
      }).sort(compare)
    }
  }
}
</script>

<style>
  .v-list-item--active * {
    color: rgb(95, 99, 101) !important;
  }
</style>
