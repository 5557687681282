<template>
  <div class="d-flex flex-row">
    <v-btn icon :title="$t('page.create')" @click="goto_show">
      <v-icon color="primary">mdi-eye</v-icon>
    </v-btn>
    <v-btn v-if="canEdit" icon :title="$t('page.edit')" @click="goto_edit">
      <v-icon color="primary">mdi-account-edit</v-icon>
    </v-btn>
    <v-btn v-if="canToggleActive" icon @click="setActive" :loading="loading.active" :title="value.active? 'ban': 'unban'" class="ml-2">
      <v-icon color="primary">mdi-account-{{ value.active? 'cancel': 'check' }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data :() => ({
    loading: {
      active: false
    },
  }),
  created() {},
  mounted() {},
  methods: {
    ...mapActions('user', ['load', 'toggle_active']),
    ...mapActions('app', ['showError']),
    async setActive () {
      this.loading.active = true
      await this.toggle_active(this.value.id).catch((error) => {
        if (error && error.response && error.response.data.message['error'])
          this.showError({ error: { message: error.response.data.message['error'] } })
        else this.showError({ error: { messag: 'Systen fails' } })
      })
      this.loading.active = false
    },
    goto_edit() { this.$router.push({name: 'user-edit', params: {id: this.value.id}}) },
    goto_show() { this.$router.push({name: 'user-show', params: {id: this.value.id}}) },
    goto_history() { alert('history') }
  },
  computed: {
    ...mapGetters('user', ['profile']),
    canEdit () {
      if (!this.profile) return false
      return this.profile.chkAction(['user_action_all', 'user_action_update'])
    },
    canToggleActive () {
      if (!this.profile) return false
      return this.profile.chkAction(['user_action_all', 'user_action_toggle_active'])
    }
  }
}
</script>

<style>

</style>
