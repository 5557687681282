<template>
  <div>
    <div class="d-flex">
      <v-btn
        @click="openDialog(false)"
        icon
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
      <v-btn
        v-if="canEdit"
        @click="openDialog(true)"
        icon
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        v-if="canToggleActive && waiting == 'all'"
        @click="active(value.id)"
        :color="value.active?'red': 'green'"
        :loading="isLoading"
        :disabled="!!value.complete"
        icon
      >
        <v-icon>mdi-{{ value.active ? "cancel" : "check" }}</v-icon>
      </v-btn>
    </div>
    <template>
      <withdraw-dialog
        v-model="dialog"
        :withdraw_id="value.id"
        :waiting="waiting"
        :is_edit="isEdit"
        @current_change="$emit('chnage')"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import WithdrawDialog from "./WithdrawDialog.vue";
export default {
  components: {
    WithdrawDialog,
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    waiting: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: false,
      isEdit: false,
      isLoading: false,
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions('withdraw', ['toggleActive']),
    openDialog(isEdit) {
      this.isEdit = isEdit
      this.dialog = true
    },
    async active (id) {
      this.isLoading = true
      await this.toggleActive(id)
      this.isLoading = false
    },
  },
  computed: {
    ...mapGetters('user', ['profile']),
    canEdit () {
      if (!this.profile) return false
      if (this.waiting === 'all') return false
      if (this.value.confirm != 1 && this.value.confirmBy && this.value.confirmBy.id === 1) return false

      return this.profile.chkAction([
        'withdraw_action_all',
        'withdraw_action_update',
      ])
    },
    canToggleActive () {
      if (!this.profile) return false
      if (this.profile.role == 'owner') return true
      if ((this.value.confirmBy && this.value.confirmBy.id === 1) && !(this.value.completeBy && this.value.completeBy.id)) return false

      return this.profile.chkAction([
        'withdraw_action_all',
        'withdraw_action_toggle_active',
      ])
    }
  }
}
</script>

<style>

</style>
