<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark v-bind="attrs" v-on="on" color="red" class="button" :disabled="!!disabled">
        {{ $t('common.cancel') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <div class="pt-5">
          <div>{{ $t('message.cancel_confirm') }}</div>
          <v-form ref="form">
            <v-text-field
              v-model="comment"
              :disabled="isLoading"
              :label="$t('common.comment')"
              :rules="[
                v => !!v || $t('message.required'),
              ]"
            />
            <div class="d-flex justify-end">
              <v-btn @click="submit" color="primary" :loading="isLoading">{{ $t('common.submit') }}</v-btn>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    action: {
      type: Function,
      require: true
    },
    value: {
      type: Number,
      require: true
    },
    disabled: {
      type: [Number, Boolean],
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      comment: '',
      errors: {}
    }
  },
  created() {},
  mounted() {},
  methods: {
    async submit () {
      if (!this.$refs.form.validate()) return

      this.isLoading = true
      await this.action({ id: this.value, comment: this.comment })
      .then((data)=>{
        if (data.error) this.errors = data.errors
        else {
          this.dialog = false
          this.$emit('submit', data)
        }
      })
      this.isLoading = false
    }
  },
  computed: {}
}
</script>

<style>

</style>
