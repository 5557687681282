<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $t('coupon.text') }}</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" :loading="isLoading">
        <s-label-text v-model="form['name']" :label_text="$t('coupon.name')" :input="edit" class="mx-1" />
        <div class="d-flex">
          <v-checkbox v-if="isCreate" v-model="form['gencode']" :value="1">random</v-checkbox>
          <s-label-text v-model="form['code']" :label_text="$t('coupon.code')" :rules="form['gencode']? [] : rules.code" :input="edit" class="mx-1" :disabled="!isCreate || !!form['gencode']" />
          <icon-copy-text v-if="!edit" :text="form['code']" color="grey" class="ml-1"/>
        </div>

        <s-label-text v-model="form['amount']" :label_text="$t('coupon.amount')" type="number" min="0" :input="edit" class="mx-1" />
        <s-label-text v-model="form['turnover']" :label_text="$t('coupon.turnover')" type="number" min="0" :input="edit" class="mx-1" />
        <div v-if="edit">
          <v-radio-group v-model="radioLimitedGroup">
            <div class="d-flex">
              <v-radio
                :value="0"
              /> unlimited
            </div>
            <div class="d-flex">
              <v-radio
                :value="1"
              />
              <s-label-text type="number" v-model="form['limited']" :label_text="$t('coupon.limited')" :input="edit" :disabled="radioLimitedGroup == 0"/>
            </div>
          </v-radio-group>
          <s-label-text v-model="current['used']" :label_text="$t('coupon.used')" :input="edit" class="mx-1" disabled/>
        </div>
        <div v-else class="d-flex justify-end">
          <div v-if="form['limited']"> ใช้แล้ว {{ form['used'] }} / จำนวนทั้งหมด {{ form['limited'] }}</div>
          <div v-else> ใช้แล้ว {{ form['used'] }} / ไม่จำกัดจำนวน </div>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="edit" color="primary" @click="submit" :loading="isLoading">
        {{ $t(isCreate ? 'common.create' : 'common.edit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import IconCopyText from '../../components/common/IconCopyText.vue'
import validate from '../../helper/validates'
export default {
  components: { IconCopyText },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ...this.$createReset(this, 'radioLimitedGroup', 1),
      ...this.$createReset(this, 'form', {
        name: '',
        code: '',
        amount: 0,
        turnover: 0,
        limited: 0,
        used: 0,
        gencode: 1
      }),
      rules: {
        name: [validate.required(), validate.text(1, 255)],
        code: [validate.required(), validate.text(19, 19)],
        amount: [validate.required(), validate.number(0)],
        turnover: [validate.required(), validate.number(0)],
      },
      isLoading: false,
      isCreate: false
    }
  },
  created() { },
  mounted() {
    this.reloadCurrent()
  },
  methods: {
    ...mapActions('coupon', ['createCoupon', 'updateCoupon']),
    async submit() {
      if (!this.$refs.form.validate()) return
      this.isLoading = true
      const data = JSON.parse(JSON.stringify(this.form))
      data.limited = this.radioLimitedGroup == 1 ? data.limited : 0
      if (this.isCreate) {
        await this.createCoupon(data)
          .then(() => {
            this.$emit('created')
          })
      } else {
        await this.updateCoupon(data)
      }
      this.isLoading = false
    },
    reloadCurrent() {
      this.$$reset['form']()
      this.isCreate = !this.current.id
      Object.assign(this.form, this.current)
      this.radioLimitedGroup = this.current.limited == 0 ? 0 : 1
    }
  },
  computed: {
    ...mapGetters('coupon', ['current']),
  },
  watch: {
    current: function () {
      this.reloadCurrent()
    }
  }
}
</script>

<style>
</style>
