<template>
  <v-card elevation="0" >
    <v-card-title>
      {{ histories.member.ufa_user || 'transfer history' }} ( {{ startDate }} - ปัจจุบัน  )
    </v-card-title>
    <v-card-text>
      <v-card class="my-2 grey lighten-2">
        <v-card-text class="d-flex">
          <div class="flex-grow-1 mr-5" style="border-right: 1px solid">
            <div>{{ $t('member.ufa_user') }}: {{ histories.member.ufa_user }}</div>
            <div>{{ $t('member.tel') }}: {{ histories.member.tel }}</div>
            <div>{{ $t('member.bank') }}: {{ histories.member.bank_name }} {{ histories.member.bank_user }} {{ histories.member.bank_number }}</div>
            <div>reference: {{ histories.member.reference }}</div>
          </div>
          <div>
            <div class="text-end text-h5 red--text">
              {{ $t('transfer_history.main_wallet') }}: {{ mainWallet }}
            </div>
            <div class="text-end text-h6">
              {{ $t('transfer_history.game_wallet') }}: {{ gameWallet.total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
            </div>
            <div class="text-end">
              <div v-for="game in gameWallet.games" :key="game.name">
                {{ game.name }}: {{ game.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
              </div>
            </div>
            <v-btn @click="onMoveCredit" :loading="isLoading" style="width:100%" :disabled="!gameWallet.total">ย้ายเครดิตไปยังกระเป๋าถอน</v-btn>
          </div>
        </v-card-text>
      </v-card>

      <v-expansion-panels v-model="panel" multiple class="mb-3">
        <v-expansion-panel v-for="(header, key) in headers" :key="key">
          <v-expansion-panel-header class="grey lighten-2">
            {{ $t(`transfer_history.${key}`) }} ( {{ (histories[key] || []).length }} )
          </v-expansion-panel-header>
          <v-expansion-panel-content class="grey lighten-2">
            <v-data-table :headers="header" :items="histories[key] || []" :hide-default-footer="true"
              :disable-sort="true" class="grey lighten-2">
              <template v-slot:[`item.DATE`]="{ item }">
                {{ dateTime(item.DATE) }}
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ dateTime(item.created_at) }}
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                {{ item.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
              </template>
              <template v-slot:[`item.before`]="{ item }">
                {{ item.before ? item.before.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '-' }}
              </template>
              <template v-slot:[`item.after`]="{ item }">
                {{ item.after ? item.after.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '-' }}
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <v-icon>mdi-{{ item.type === 'add' ? 'plus' : 'minus'  }}</v-icon>
              </template>
              <template v-slot:[`item.by`]="{ item }">
                {{ item.user.name  }} ({{ item.user.username }})
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      startDate: moment().add(-7).format('DD/MM/YYYY'),
      isLoading: false,
      panel: [0, 4, 5],
      headers: {
        wallet: [
          { text: this.$t('transfer_history.id'), value: 'REFERENCE NO' },
          { text: this.$t('transfer_history.type'), value: 'TYPE' },
          { text: this.$t('transfer_history.from'), value: 'FROM' },
          { text: this.$t('transfer_history.to'), value: 'TO' },
          { text: this.$t('transfer_history.amount'), value: 'AMOUNT' },
          { text: this.$t('transfer_history.created_at'), value: 'DATE' },
        ],
        deposit: [
          { text: this.$t('transfer_history.id'), value: 'id' },
          { text: this.$t('transfer_history.amount'), value: 'amount' },
          { text: this.$t('transfer_history.before'), value: 'before' },
          { text: this.$t('transfer_history.after'), value: 'after' },
          { text: this.$t('transfer_history.comment'), value: 'comment' },
          { text: this.$t('transfer_history.description'), value: 'description' },
          { text: this.$t('transfer_history.created_at'), value: 'created_at' },
        ],
        withdrawal: [
          { text: this.$t('transfer_history.id'), value: 'id' },
          { text: this.$t('transfer_history.amount'), value: 'amount' },
          { text: this.$t('transfer_history.before'), value: 'before' },
          { text: this.$t('transfer_history.after'), value: 'after' },
          { text: this.$t('transfer_history.comment'), value: 'comment' },
          { text: this.$t('transfer_history.description'), value: 'description' },
          { text: this.$t('transfer_history.created_at'), value: 'created_at' },
        ],
        manual: [
          { text: this.$t('transfer_history.id'), value: 'id' },
          { text: this.$t('transfer_history.type'), value: 'type' },
          { text: this.$t('transfer_history.amount'), value: 'amount' },
          { text: this.$t('transfer_history.before'), value: 'before' },
          { text: this.$t('transfer_history.after'), value: 'after' },
          { text: this.$t('transfer_history.by'), value: 'by'},
          { text: this.$t('transfer_history.comment'), value: 'comment' },
          { text: this.$t('transfer_history.description'), value: 'description' },
          { text: this.$t('transfer_history.created_at'), value: 'created_at' },
        ],
        unknown_deposit: [
          { text: this.$t('transfer_history.id'), value: 'REFERENCE NO' },
          { text: this.$t('transfer_history.amount'), value: 'AMOUNT' },
          { text: this.$t('transfer_history.created_at'), value: 'DATE'},
        ],
        unknown_withdraw: [
          { text: this.$t('transfer_history.id'), value: 'REFERENCE NO' },
          { text: this.$t('transfer_history.amount'), value: 'AMOUNT' },
          { text: this.$t('transfer_history.created_at'), value: 'DATE'},
        ],
      }
    }
  },
  created() { },
  mounted() {
  },
  methods: {
    ...mapActions('history', ['moveCredit']),
    dateTime(dateString) {
      return moment(dateString).format('DD/MM/YYYY HH:mm')
    },
    onMoveCredit() {
      this.isLoading = true
      this.moveCredit(this.histories.member.ufa_user).finally(() => {
        this.isLoading = false
      })
    }
  },
  computed: {
    ...mapGetters('history', ['histories']),
    mainWallet() {
      if (!this.histories.balance) return '-'
      return this.histories.balance['MAIN'].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    gameWallet() {
      if (!this.histories.balance) return { games: [], total: 0 }
      return Object.keys(this.histories.balance)
        .reduce((r, k) => {
          console.log(k, this.histories.balance[k])
          if (k !== 'MAIN' && this.histories.balance[k] > 0) {
            const tmp = {
              name: k,
              amount: this.histories.balance[k]
            }
            r.games.push(tmp)
            r.total += this.histories.balance[k]
          }
          return r
        }, { games: [], total: 0 })
    },
  },
  watch: {}
}
</script>

<style>
</style>
