<template>
  <div class="label-text">
    <div class="d-flex flex-column flex-md-row align-md-center my-2">
      <b :class="{'mb-xs-0': true, 'mb-md-5': showInputOrText == 'input' && $attrs['hide-defaults']!=='' }" :style="{ 'min-width': labelWidth +'px', 'max-width': $vuetify.breakpoint.smAndDown? '100%': labelWidth +'px'}">
        {{ $attrs['label_text'] || 'no label' }}
      </b>
      <div v-if="showInputOrText === 'input'" class="flex-grow-1 d-flex">

        <slot v-if="$slots['default']"></slot>

        <v-text-field v-else-if="!$attrs['items']" v-on="$listeners" v-bind="$attrs" :hide-details="$attrs['hide-defaults']==''" :background-color="color">
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
          <template v-slot:append>
            <slot name="append" />
          </template>
        </v-text-field>

        <v-select v-else v-on="$listeners" v-bind="$attrs" :hide-details="$attrs['hide-defaults']==''" :background-color="color">
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
        </v-select>

      </div>

      <div v-else class="flex-grow-1 d-flex align-center">
        <v-sheet
          rounded
          :class="[{'errorx': !!$attrs['error-messages']}, 'px-1', 'py-1', 'flex-grow-1']"
          :style="{'min-height': valueHeight + 'px'}"

          v-bind="$attrs"
          :color="color"
          light
        >
          <div class="d-flex mx-1 align-center">
            <slot></slot>
            <span v-if="!$slots['default']" class="flex-grow-1 noselect">{{ value_text }}</span><slot name="append" />
          </div>
        </v-sheet>
        <slot name="append-outer" />
      </div>
    </div>
  </div>


</template>

<script>
export default {
  inheritsAttrs: false,
  props: {
    labelWidth:{
      type: Number,
      default: 100
    },
    valueHeight:{
      type: Number,
      default: 36.6
    },
    show: {
      type: String,
      default: 'text'
    },
    input_type: {
      type: String,
      default: 'text'
    },
    text: {
      type: Boolean,
      default: false
    },
    input: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "#eeeeee"
    },
  },
  data() {
    return {
      file: null,
      date: null
    }
  },
  created() {},
  mounted() {},
  methods: {
    tssetst () {
      alert(1)
    }
  },
  computed: {
    value_text () {
      if (this.$attrs['items']) {
        let found = this.$attrs['items'].find(r => r.value === this.$attrs['value'])
        if (found) return found.text
        return ''
      }
      return this.$attrs['value']
    },
    showInputOrText () {
      if (this.text) return 'text'
      else if (this.input) return 'input'
      return this.show
    }
  }
}
</script>

<style lang="scss" scoped>
  .label-text .errorx {
    border: 2px solid var(--v-error-base) !important;
  }

</style>
<style>
  .label-text input {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
</style>
