import { call } from '../plugins/axios'

export default {
  namespaced: true,
  state: () => ({
    list: [],
    total: 0,
    current: null
  }),
  getters: {
    list: state => state.list,
    total: state => state.total,
    current: state => state.current
  },
  mutations: {
    setList: (state, data) => state.list = data,
    setTotal: (state, data) => state.total = data,
    setCurrent: (state, data) => state.current = data
  },
  actions: {
    async getList({ commit, dispatch }, data = {}) {
      return await call('event/lottery/list', data)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          if (data.rows) commit('setList', data.rows)
          if (data.count) commit('setTotal', data.count)

          return data
        }).catch((error) => {
          dispatch('app/showError', { error }, { root: true })
        }).then(() => {
          return {
            list: this.list,
            total: this.total
          }
        })
    },
    async updateLottery({ dispatch }, data) {
      return await call('event/lottery/update', data)
        .then(({ data }) => {
          if (!data.error) dispatch('app/showSuccess', { success: { message: 'อัพเดทข้อมูลสำเร็จ' } }, { root: true })
          return data
        }).catch((data) => {
          if (data.message) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else dispatch('app/showError', { error: { message: data.message || 'พบข้อผิดพลาดในระบบ' } }, { root: true })
          return data
        })
    },
    setCurrent ({ commit }, data) {
      commit('setCurrent', data)
    }
  }
}
