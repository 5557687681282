<template>
  <page
    :title="$t('coupon.text')"
    :breadcrumbs="breadcrumbs"
    :loading="isLoading"
    :headers="headers"
    :options.sync="options"
    :items="coupons"
    :total="total"
    :filter="filter"
    @update:options="onSearch"
    class="flex-grow-1"
  >
    <template slot="btn-right">
      <v-btn v-if="canCreate" color="primary" @click="openCreate()">
        สร้าง
      </v-btn>
    </template>

    <template slot="filters">
      <v-text-field
        v-model="filter['name']"
        :label="$t('coupon.name')"
        class="mx-1"
      />
      <v-text-field
        v-model="filter['code']"
        :label="$t('coupon.code')"
        class="mx-1"
      />
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <group-tool :value="item"></group-tool>
    </template>

    <template slot="outside">
      <v-dialog
        v-model="dialog"
        width="500px"
      >
        <coupon-form :edit="true" @created="reload"/>
      </v-dialog>
    </template>
  </page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Page from '../../layouts/PageLayout.vue'
import GroupTool from '../../components/coupon/GroupTool.vue'
import CouponForm from './CouponForm.vue'
export default {
  components: { Page, CouponForm, GroupTool },
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('coupon.text'),
        },
        {
          text: this.$t('common.list'),
        },
      ],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Code', value: 'code' },
        { text: 'Amount', value: 'amount' },
        { text: 'Turnover', value: 'turnover' },
        { text: 'Limited', value: 'limited' },
        { text: 'Used', value: 'used' },
        { text: 'Created', value: 'created_at', type: 'date' },
        { text: 'Updated', value: 'updated_at', type: 'date' },
        { text: 'active', value: 'active' },
        { text: '', value: 'actions', sortable: false },
      ],
      filter: {
        name: '',
        code: ''
      },
      options: {},
      isLoading: false,
      dialog: false
    }
  },
  created() {},
  mounted() {
    this.onSearch()
  },
  methods: {
    ...mapActions('coupon', ['getCoupons', 'setCurrentCoupon']),
    openCreate() {
      this.setCurrentCoupon({})
      this.dialog = true
    },
    onSearch(){
      this.isLoading = true
      this.getCoupons({
        ...this.options,
        filter: this.filter
      }).then(() => {
        this.isLoading = false
      })
    },
    reload() {
      this.dialog = false
      this.onSearch()
    }
  },
  computed: {
    ...mapGetters('coupon', ['coupons', 'total']),
    ...mapGetters('user', ['profile']),
    canCreate () {
      if (!this.profile) return false
      return this.profile.chkAction([
        'coupon_action_all',
        'coupon_action_create',
      ])
    }
  }
}
</script>

<style>

</style>
