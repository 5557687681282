import { call } from '../plugins/axios'
export default {
  namespaced: true,
  state: () => ({
    members: [],
    current: {},
    total: 0
  }),
  getters: {
    members: (state) => state.members,
    current: (state) => state.current,
    total: (state) => state.total
  },
  mutations: {
    setMembers: (state, value) => state.members = value,
    updateMember: (state, member) => {
      let index = state.members.findIndex(r => member.id === r.id)
      if (index >= 0) state.members[index] = Object.assign(state.members[index], member)
    },
    setMembersTotal: (state, value) => state.total = value,
    setCurrentMember: (state, value) => state.current = value
  },
  actions: {
    load: async ({ commit, dispatch }, search = {}) => {
      return await call('member/list', search)
        .then(({data})=>{
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          commit('setMembers', data.members || [])
          commit('setMembersTotal', data.total || 0)

          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
          return { error: 500 }
        })
    },
    toggle_active: async ({ commit, dispatch }, id) => {
      await call('member/toggle-active', id)
        .then(({data}) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          if (data.member) commit('updateMember', data.member )
          if (data.change) commit('updateMember', data.change )

          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
          return { error: 500 }
        })
    },
    get_member: async ({ commit, dispatch }, id) => {
      return await call('member/get',id)
        .then(({data})=>{
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          commit('setCurrentMember', data.member || {})

          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
          return { error: 500 }
        })
    },
    update_member: async ({ commit, dispatch }, member) => {
      return await call('member/update', member)
        .then(({data}) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          if (data.member) commit('updateMember', data.member)
          if (data.chnage) commit('updateMember', data.chnage)

          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
          return { error: 500 }
        })
    },
    clear_current_member: ({ commit }) => commit('setCurrentMember', {}),

    getFormCasino: async ({ dispatch }, ufa_user) => {
      return await call('member/get-form-casino', ufa_user)
        .then(({data}) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          return data
        })
    },
    submitFormCasino: async ({ dispatch }, form) => {
      return await call('member/submit-form-casino', form)
        .then(({data}) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          return data
        })
    },
    manual_credit: async ({ dispatch }, form) => {
      return await call('manual/manual-credit', form)
        .then(({data}) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else dispatch('app/showSuccess', data.message, { root: true })
          return data
        })
        .catch((error) => {
          console.log(error, error.response.data)
        })
    }
  }
}
