<template>
  <v-card>
    <v-card-title>

    </v-card-title>
    <v-card-text>
      <div class="text-center text-h5">{{ dateTh(current.start_at) }} - {{ dateTh(current.exp_at) }}</div>
      <v-form ref="form" :loading="isLoading">

        <v-row justify="center">
          <v-col cols="12" md="5">
            <div class="my-5 my-md-4">
              <div class="text-center">
                <span>รางวัลที่ 1</span>
                <v-text-field v-model="current.no1" :rules="rules(6)" placeholder="XXXXXX" counter outlined solo rounded
                  :disabled="actionType !== 'update'">
                </v-text-field>
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <div>
                <div class="text-center">
                  <span>รางวัลเลขหน้า 3 ตัว หมุน 1 รอบ</span>
                  <v-text-field v-model="current.front3c1" :rules="rules(3)" placeholder="XXX" counter outlined rounded
                    solo :disabled="actionType !== 'update'">
                  </v-text-field>
                </div>
                <div class="text-center">
                  <span>รางวัลเลขหน้า 3 ตัว หมุน 2 รอบ</span>
                  <v-text-field v-model="current.front3c2" :rules="rules(3)" placeholder="XXX" counter outlined rounded
                    solo :disabled="actionType !== 'update'">
                  </v-text-field>
                </div>
              </div>
              <div>
                <div class="text-center">
                  <span>รางวัลเลขหลัง 3 ตัว หมุน 1 รอบ</span>
                  <v-text-field v-model="current.back3c1" :rules="rules(3)" placeholder="XXX" counter outlined rounded
                    solo :disabled="actionType !== 'update'">
                  </v-text-field>
                </div>
                <div class="text-center">
                  <span>รางวัลเลขหลัง 3 ตัว หมุน 2 รอบ</span>
                  <v-text-field v-model="current.back3c2" :rules="rules(3)" placeholder="XXX" counter outlined rounded
                    solo :disabled="actionType !== 'update'">
                  </v-text-field>
                </div>
              </div>
            </div>
            <div class="d-md-flex justify-space-between my-md-4">
              <div class="text-center">
                <span>รางวัลเลขท้าย 2 ตัวล้าง</span>
                <v-text-field v-model="current.down2c" :rules="rules(2)" placeholder="XX" counter outlined rounded solo
                  :disabled="actionType !== 'update'">
                </v-text-field>
              </div>
              <div class="text-center">
                <span>วิ่งล้าง (เลขท้ายสองตัว)</span>
                <v-text-field v-model="current.run" :rules="rules(1)" placeholder="X" counter outlined rounded solo
                  :disabled="actionType !== 'update'">
                </v-text-field>
              </div>
            </div>
          </v-col>
        </v-row>
        <div v-if="actionType === 'update'" class="text-end">
          <v-btn @click="submit" :loading="isLoading">อัพเดท</v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
export default {
  props: {
    actionType: {
      type: String,
      default: 'create'
    }
  },
  data() {
    return {
      isLoading: false,
      guess: {
        no1: '',
        front3c: '',
        back3c: '',
        down2c: '',
        run: '',
      },
      rules: (len) => {
        return [
          v => !!v || 'กรุณากรอกข้อมูล',
          v => String(v).length == len || `กรุณากรอกข้อมูลให้ครบ ${len} ตัวอักษร`,
          v => new RegExp(`[0-9]{${len}}`, 'gm').test(v) || 'กรุณากรอกข้อมูลเป็นตัวเลข',
        ]
      },
    }
  },
  methods: {
    ...mapActions('event_lottery', ['updateLottery']),
    async submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true
        await this.updateLottery(this.current)
        this.isLoading = false
      }
    },
    dateTh(d) {
      return moment(d).format('DD-MM-YYYY HH:mm')
    }
  },
  computed: {
    ...mapGetters('event_lottery', ['current']),
  },
}
</script>

<style scoped>
.v-input::v-deep input {
  text-align: center;
}
</style>

