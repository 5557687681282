export default [
  { value: 'scb',  text: 'ธนาคารไทยพาณิชย์' },
  { value: 'bay' , text:  'กรุงศรีอยุธยา' },
  { value: 'bbl' , text:  'กรุงเทพ' },
  { value: 'cimb' , text:  'ซีไอเอมบีไทย' },
  { value: 'gsb' , text:  'ออมสิน' },
  { value: 'kbank' , text:  'กสิกรไทย' },
  { value: 'kk' , text:  'เกียรตินาคิน' },
  { value: 'ktb' , text:  'กรุงไทย' },
  { value: 'lhbank' , text:  'แลนด์แอนด์เฮ้าส์' },
  { value: 'tbank' , text:  'ธนชาต' },
  { value: 'tmb' , text:  'ทหารไทย' },
  { value: 'uob' , text:  'ยูโอบี' },
  { value: 'baac' , text:  'ธ.ก.ส.' },
  { value: 'ghb' , text:  'ธนาคารอาคารสงเคราะห์' },
  { value: 'ttb' , text:  'ทหารไทยธนชาต' },
  { value: 'tisco' , text:  'ธนาคารทิสโก้' }
]
