var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('input',{staticClass:"hide",attrs:{"id":"input"}}),_c('date-picker',{attrs:{"editable":true,"popover":false,"show":_vm.show,"min":_vm.minDate,"max":_vm.maxDate,"format":"YYYY-MM-DD HH:mm","auto-submit":"","custom-input":"#input","locale":"th","type":"datetime"},on:{"close":function($event){return _vm.$emit('close')},"change":_vm.onChange},scopedSlots:_vm._u([{key:"submit-btn",fn:function(ref){
var canSubmit = ref.canSubmit;
var color = ref.color;
var submit = ref.submit;
return [_c('button',{style:({ color: color }),attrs:{"type":"button","disabled":!canSubmit},domProps:{"textContent":_vm._s('ตกลง')},on:{"click":submit}})]}},{key:"cancel-btn",fn:function(ref){
var vm = ref.vm;
var color = ref.color;
return [_c('button',{style:({ color: color }),attrs:{"type":"button"},domProps:{"textContent":_vm._s('ยกเลิก')},on:{"click":function($event){vm.visible = false}}})]}},{key:"now-btn",fn:function(ref){
var color = ref.color;
var goToday = ref.goToday;
return [_c('button',{style:({ color: color }),attrs:{"type":"button"},domProps:{"textContent":_vm._s('เวลาปัจจุบัน')},on:{"click":goToday}})]}},{key:"header-year",fn:function(ref){
var selectedDate = ref.selectedDate;
return [_vm._v(" พ.ศ. "+_vm._s(selectedDate.xYear() + 543)+" ")]}},{key:"year-item",fn:function(ref){
var year = ref.year;
return [_vm._v(" "+_vm._s(year.year() + 543)+" ")]}}]),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }