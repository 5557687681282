<template>
  <v-card v-if="isLoading">
    <v-card-title class="text-h5 grey lighten-2">
      แก้ไขเงือนไขลำดับที่ {{ value.id || "-" }}
    </v-card-title>
    <v-card-text class="d-flex justify-center py-2" style="height: 100px">
      <v-progress-circular :size="70" :width="7" color="warning" indeterminate
        >loading</v-progress-circular
      >
    </v-card-text>
  </v-card>
  <v-card v-else>
    <v-card-title> แก้ไขเงือนไขลำดับที่ {{ value.id || "-" }} </v-card-title>
    <v-card-text class="py-3">
      <v-form ref="form" :disabled="isLoading || submiting">
        <input-or-text
          v-model="valueProp.bank_id"
          :label="$t('bank.text')"
          :items="bank_options"
          :rules="[(v) => !!v || 'bank_id is required']"
          input
        />
        <input-or-text
          v-model="valueProp.order"
          :label="$t('common.order')"
          type="number"
          input
        />
        <input-or-text
          v-model="valueProp.amount_counter_maximum"
          :label="$t('bank_deposit.amount_counter_maximum')"
          type="number"
          input
        />
        <v-checkbox
          v-model="valueProp.first_deposit"
          :label="$t('bank_deposit.first_deposit')"
          :false-value="0"
          :true-value="1"
          hide-details
        />
        <v-checkbox
          v-model="valueProp.cdm_mode"
          :label="$t('bank_deposit.cdm_mode')"
          :false-value="0"
          :true-value="1"
          hide-details
        />
        <v-checkbox
          v-model="valueProp.active"
          :label="$t('bank_deposit.active')"
          :false-value="0"
          :true-value="1"
          hide-details
        />
        <div v-if="canEdit" class="d-flex justify-end">
          <v-btn class="primary" @click="submit" :loading="submiting">submit</v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import InputOrText from "../../components/common/InputOrText.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    InputOrText,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    agent_id: {
      type: [Number, null],
      default: null,
    },
    agent_name: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      submiting: false,
    };
  },
  async created() {
    this.isLoading = true;
    if (!this.$store.getters["bank/banks"].length) await this.get_banks({});
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  },
  mounted() {},
  methods: {
    ...mapActions("bank", ["get_banks", "update_bank_deposit"]),
    async submit() {
      this.submiting = true;
      await this.update_bank_deposit({
        id: this.valueProp.id,
        bank_id: this.valueProp.bank_id,
        order: this.valueProp.order,
        amount_counter_maximum: this.valueProp.amount_counter_maximum,
        first_deposit: this.valueProp.first_deposit,
        cdm_mode: this.valueProp.cdm_mode,
        active: this.valueProp.active,
      })
      .then((data) =>{
        if (!data.error) this.$emit('close', true)
      })
      this.submiting = false
    },
  },
  computed: {
    ...mapGetters("bank", ["banks"]),
    ...mapGetters("user", ['profile']),
    canEdit () {
      if (!this.profile) return false
      return this.profile.chkAction([
        'bank_deposit_action_all',
        'bank_deposit_action_update'
      ])
    },
    bank_options() {
      if (!this.banks) return [];
      return this.banks
        .filter((r) => r.active == 1 && r.withdraw_only == 0)
        .map((r) => ({
          text: `${r.bank_name} ${r.name} ${r.number}`,
          value: r.id,
        }));
    },
    valueProp: {
      get() {
        return JSON.parse(JSON.stringify(this.value));
      },
      set() {
        return
      },
    },
  },
};
</script>

<style>
</style>
