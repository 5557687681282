<template>
  <page-blank-layout :title="$t(`statement.${$route.name}`)" :breadcrumbs="breadcrumbs">
    <template slot="btn-right"> </template>

    <!-- filter -->
    <v-form ref="filter" @submit="search" :disabled="isLoading">
      <v-card>
        <v-card-text>
          <div class="d-flex">
            <input-or-text v-model="filter.bank_deposit" :label="$t('bank.bank_deposit')" clearable dense hide-details
              input outlined :items="bankOptions" />
            <input-or-text v-model="filter.bank_player" :label="$t('bank.bank_player')" clearable dense hide-details
              input outlined :items="bankOptions" />
            <input-or-text v-model="filter.amount" :label="$t('statement.amount')" clearable dense hide-details input
              outlined type="number" />
            <input-or-text v-model="filter.date" :label="$t('statement.date')" dense hide-details input outlined
              type="date" />
            <input-or-text v-model="filter.start_time" :label="$t('statement.start_time')" dense hide-details input
              outlined step="360000" type="time" />
            <input-or-text v-model="filter.end_time" :label="$t('statement.end_time')" dense hide-details input outlined
              step="360000" type="time" />
            <v-btn :loading="isLoading" class="ml-2" color="primary" @click="search">ค้นหา</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-form>

    <!-- table -->
    <v-card class="mt-2" :loading="isLoading">
      <v-card-title>{{ $t("statement.text") }}</v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="statements" :server-items-length="statementCount"
          :options.sync="options" :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }">
          <template v-slot:[`item.bank_deposit`]="{ item }">
            {{ bankName(item.bank.bank_name) }}
          </template>

          <template v-slot:[`item.bank_player`]="{ item }">
            {{ bankName(item.bank_name) }}
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            {{
                (item.amount || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
            }}
          </template>

          <template v-slot:[`item.tool`]="{ item }">
            <group-tool :value="item" />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </page-blank-layout>
</template>

<script>
import GroupTool from "../../components/statement/GroupTool.vue";
import PageBlankLayout from "../../layouts/PageBlankLayout.vue";
import banks from "../../configs/banks"
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import InputOrText from "../../components/common/InputOrText.vue";

export default {
  components: {
    InputOrText,
    PageBlankLayout,
    // StatementPage,
    GroupTool
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      breadcrumbs: [{ text: "main" }, { text: "list" }],
      ...this.$createReset(this, "filter", {
        bank_deposit: "",
        bank_player: "",
        amount: 0,
        date: "",
        start_time: "",
        end_time: "",
      }),
      bankOptions: banks,
      options: {},
      headers: [
        { text: this.$t("statement.id"), value: "id", sortable: false },
        {
          text: this.$t("bank.bank_deposit"), value: "bank_deposit", sortable: false
        },
        {
          text: this.$t("statement.bank_number"),
          value: "bank.number",
          sortable: false,
        },
        {
          text: this.$t("bank.bank_player"), value: "bank_player", sortable: false
        },
        { text: this.$t("statement.bank_number"), value: "bank_no" },
        { text: this.$t("statement.amount"), value: "amount" },
        { text: this.$t("statement.remark"), value: "remark", sortable: false },
        { text: this.$t("statement.bank_time"), value: "bank_time" },
        { text: this.$t("statement.comment"), value: "comment" },
        { text: this.$t("statement.created_at"), value: "created_at" },
        { text: this.$t("statement.updated_at"), value: "updated_at" },
        { text: this.$t("common.tool"), value: "tool", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters("statement", ["statements", "statementCount"]),
  },
  watch: {
    options(val) {
      this.search(val)
    },
    ["$route.name"]() {
      this.options.sortBy = []
      this.options.page = 1
      this.$$reset['filter']()
      this.search()
    }
  },
  created() { },
  async mounted() {
    this.isLoading = true
    await this.search()
    this.isLoading = false
  },
  methods: {
    ...mapActions("statement", ["getStatements"]),
    async search(options) {
      this.dialog = false
      this.isLoading = true;
      const filter = { filter: {
        ...this.filter,
        action: this.$route.meta.type,
        match: typeof this.$route.meta.match !== 'undefined' ? (this.$route.meta.match ? 'true': 'false') : null,
      }, ...options }
      await this.getStatements(filter);
      this.isLoading = false;
    },
    date_format(text) {
      return moment(text).format("YYYY-MM-DD HH:mm");
    },
    bankName(value) {
      return value  // banks.find(bank => bank.value == value).text;
    }
  },
};
</script>

<style>
</style>
