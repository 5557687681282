<template>
  <page-form-layout
    ref="page"
    :loading="isLoading"
    :title="$t('user.text')"
    :breadcrumbs="breadcrumbs"
    :hide-btn-reset="canEdit && show != 'input'"
    :hide-btn-submit="canEdit && show != 'input'"
    @reset="reset"
    @submit="submit"
  >
    <input-or-text
      v-if="current.member_prefix"
      :value="`0${ current.member_prefix.toString(36)}`.slice(-2).toUpperCase()"
      :label="$t('user.reference')"
      show="text"
    />
    <input-or-text
      v-model="current.name"
      :label="$t('user.name') + ' *'"
      :error-messages="errors.name"
      :rules="[ validate.required() ]"
      :show="show"
    />
    <input-or-text
      v-model="current.username"
      :label="$t('user.username') + ' *'"
      :error-messages="errors.username"
      :rules="[ validate.required(), validate.text(5, 64) ]"
      :show="show"
    />
    <input-or-text
      v-if="['create', 'update'].includes(type)"
      v-model="current.password"
      :label="$t('user.password') + (type=='create'? ' *':'')"
      :error-messages="errors.password"
      :rules="[ ...(type=='create'? [validate.required()]: []), validate.text(6, 32) ]"
      :show="show"
    />
    <input-or-text
      v-model="current.active"
      :label="$t('user.active')"
      :error-messages="errors.active"
      :items="active_options"
      :show="canToggleActive ? show : 'text'"
    />
    <input-or-text
      v-model="current.role"
      :value="userRole"
      :label="$t('user.role')"
      :error-messages="errors.role"
      :items="options"
      :show="options.length ? show : 'text'"
    />

    <select-permissions
      v-model="current.permissions"
      :disabled="!profile || profile.role !== 'owner'"
      @change="changePermissions"
    />

  </page-form-layout>
</template>

<script>
import validate from '../../helper/validates.js'
import PageFormLayout from '../../layouts/PageFormLayout.vue'
import InputOrText from '../../components/common/InputOrText.vue'
import SelectPermissions from '../../components/common/SelectPermissions.vue'
// mport permissions from '../../configs/permissions.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    SelectPermissions,
    PageFormLayout,
    InputOrText
  },
  data () {
    return {
      test: [],
      default: '',
      show: 'text',
      isLoading: false,
      type: 'detail',
      validate: validate,
      breadcrumbs: [
        { text: this.$t('user.text') },
        {
          text: this.$t('common.list'),
          href: this.$router.resolve({name: 'users'}).href
        },
        { text: this.$t('user.text') }
      ],

      role_options: [
        { text: this.$t('role.owner'), value: 'owner' },
        { text: this.$t('role.admin'), value: 'admin' },
        { text: this.$t('role.agent'), value: 'agent' },
      ],

      active_options: [
        { text: this.$t('active.0'), value: 0 },
        { text: this.$t('active.1'), value: 1 }
      ],
      permissions: [],
      errors: {},
      form: null
  }},
  created() {},
  beforeMount () {
    this.clear_current()
  },
  async mounted() {
    this.form = this.$refs.page.$refs.form
    this.type = this.$route.meta.type

    if (['create', 'update'].includes(this.$route.meta.type)) this.show = 'input'
    else this.show = 'text'

    if (this.$route.params.id) {
      this.isLoading = true
      await this.get_user(this.$route.params.id)
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions('user', ['get_user', 'create_user', 'update_user', 'clear_current']),
    ...mapActions('app', ['showSuccess', 'showError']),
    changePermissions(permissions) {
      this.permissions = permissions
      console.log(this.permissions)
    },
    async reset() {
      this.isLoading = true
      if (this.$route.params.id) await this.get_user(this.$route.params.id)
      else this.clear_current()
      this.isLoading = false
    },
    async submit() {
      if (!['create', 'update'].includes(this.type)) return

      this.errors =  {}
      if (this.form.validate()) {
        this.isLoading = true
        let action = this.type == 'create'? this.create_user: this.update_user
        await action({...this.current, permissions: this.permissions})
          .then((data)=>{
            if (data.error) this.$set(this, 'errors', data.errors || {})
            else {
              this.showSuccess(this.$t(`message.${ this.type }_success`))
              this.$router.push({ name: 'users' })
            }
          })
        this.isLoading = false
      }
      else this.showError({ error: { message: 'message.validate' } })
    }
  },
  computed: {
    ...mapGetters('user', ['profile', 'current']),
    canEdit () {
      if (!this.profile) return false
      return this.profile.chkAction(['user_action_all', 'user_action_update'])
    },
    canToggleActive () {
      if (!this.profile) return false
      return this.profile.chkAction(['user_action_all', 'user_action_toggle_active'])
    },
    options () {
      if (!this.profile) return []
      if (this.profile.role == 'owner') return [
        { text: this.$t('role.admin'), value: 'admin' },
        { text: this.$t('role.agent'), value: 'agent' },
      ]
      if (this.profile.role == 'admin') return [
        { text: this.$t('role.agent'), value: 'agent' },
      ]
      return []
    },
    userRole () {
      if (!this.current || !this.current.role) return ''
      if (!this.current.role && this.current.role.length == 0) {
        if (this.profile.role === 'owner') return 'admin'
        return 'agent'
      }
      return this.current.role
    }
  }
}
</script>

<style>

</style>
