<template>
  <div class="d-flex justify-center align-center blue-grey lighten-5" style="height: 100%">
    <v-card
      elevation="2"
      style="min-width: 400px;"
      class="blue-grey lighten-1"
      dark
    >
      <div style="padding: 20px 70px;">
        <v-img src="/logo.png" />
      </div>

      <v-card-text>
        <v-form :disabled="isLoading">
          <v-text-field :label="$t('user.username')" v-model="form.user" outlined dense/>
          <v-text-field
            :label="$t('user.password')"
            v-model="form.password"
            outlined
            :type="isPassword ? 'password': 'text'"
            :append-icon="isPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="isPassword = !isPassword"

            dense/>
        </v-form>

        <v-btn @click="submit" :loading="isLoading" color="primary" style="width: 100%">{{ $t('common.login') }}</v-btn>
      </v-card-text>
    </v-card>

  </div>

</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      isLoading: false,
      isPassword: true,
      form: {
        user: '',
        password: ''
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions('auth', ['login']),
    async submit() {
      this.isLoading = true
      await this.login(this.form)
        .then(()=>{
          this.$router.push({ name: 'home' })
        })
      this.isLoading = false
    }
  },
  computed: {}
}
</script>

<style>

</style>
