<template>
  <v-main>
    <router-view></router-view>
  </v-main>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>

<style lang="scss" scoped>

</style>5
