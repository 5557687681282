import { call } from '../plugins/axios'
export default {
  namespaced: true,
  state: () => ({
    configs: []
  }),
  getters: {
    configs: (state) => state.configs
  },
  mutations: {
    setConfigs: (state, value) => state.configs = value,
  },
  actions: {
    async getConfigs({ commit }) {
      return await call('config/list', null)
        .then(({data}) => {
          if (data.error) commit('setConfigs', [])
          else if(data.configs) commit('setConfigs', data.configs)

          return data
        })
    },

    async updateConfigs({ dispatch }, values) {
      return await call('config/update', values)
        .then(({data}) => {
          if (data.errro) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          // else if() commit('setConfigs', data.configs)

          return data
        })
      }
    }
}
