export default [
  // parent menu
  { name: 'menu_withdraw', order: 6,
    menu: {
      text: 'withdraw.text',
      icon: 'mdi-cash-minus',
    }
  },
  { name: 'menu_deposit', order:7,
    menu: {
      text: 'deposit.text',
      icon: 'mdi-cash-plus',
    }
  },
  { name: 'menu_sms', order:8,
    menu: {
      text: 'sms.text',
      icon: 'mdi-cellphone-message',
    }
  },
  // routes
  { path: '/login',
    name: 'login',
    component: () => import('@/pages/SigninPage.vue'),
    meta: { layout: 'blank' }
  },
  { path: '/',
    name: 'home',
    component: () => import('@/pages/Home.vue'),
    meta: { auth: true }
  },
  { path: '/banks', order: 0,
    name: 'banks',
    component: () => import('@/pages/banks/BankListPage.vue'),
    meta: {
      auth: true,
      // role: ['owner']
      permissions: ['menu_bank']
    },
    menu: {
      text: 'bank.text',
      icon: 'mdi-bank'
    },
  },
  { path: '/statements', order: 1,
    name: 'statements',
    component: () => import('@/pages/statements/StatementListPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_statement']
    },
    menu: {
      text: 'statement.text',
      icon: 'mdi-view-list'
    }
  },
  { path: '/bank_deposits', order: 2,
    name: 'bank_deposits',
    component: () => import('@/pages/bank_deposits/BankDepositListPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_bank_deposit']
    },
    menu: {
      text: 'bank_deposit.text',
      icon: 'mdi-bank-check'
    }
  },
  { path: '/users', order: 3,
    name: 'users',
    component: () => import('@/pages/user/UserListPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_user']
    },
    menu: {
      text: 'user.text',
      icon: 'mdi-account-tie'
    }
  },
  { path: '/users/create',
    name: 'user-create',
    component: () => import('@/pages/user/UserPage.vue'),
    meta: {
      type: 'create',
      auth: true,
      permissions: ['menu_user', 'user_action_all', 'user_action_create']
    }
  },
  { path: '/users/:id',
    name: 'user-show',
    component: () => import('@/pages/user/UserPage.vue'),
    meta: {
      type: 'show',
      auth: true,
      permissions: ['menu_user']
    }
  },
  { path: '/users/:id/edit',
    name: 'user-edit',
    component: () => import('@/pages/user/UserPage.vue'),
    meta: {
      type: 'update',
      auth: true,
      permissions: ['menu_user', 'user_action_all', 'user_action_update']
    }
  },
  { path: '/members', order: 4,
    name: 'members',
    component: () => import('@/pages/members/MemberListPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_member']
    },
    menu: {
      text: 'member.text',
      icon: 'mdi-account'
    }
  },
  { path: '/winlost', order: 5,
    name: 'winlost-list',
    component: () => import('@/pages/winlost/WinAndLostPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_winlost']
    },
    menu: {
      text: 'winlost.text',
      icon: 'mdi-chess-queen'
    }
  },
  { path: '/members/:id',
    name: 'member-show',
    component: () => import('@/pages/members/MemberPage.vue'),
    meta: {
      type: 'show',
      auth: true,
      permissions: ['menu_member']
    }
  },
  { path: '/members/:id/edit',
    name: 'member-edit',
    component: () => import('@/pages/members/MemberPage.vue'),
    meta: {
      type: 'update',
      auth: true,
      permissions: ['menu_member']
    }
  },
  { path: '/waiting/deposits',
    name: 'deposits-waiting',
    component: () => import('@/pages/deposits/DepositListPage.vue'),
    meta: {
      waiting:true,
      auth: true,
      permissions: ['menu_deposit_waiting']
    },
    menu: {
      text: 'deposit.waiting',
      icon: 'mdi-cash-plus',
      parent: 'menu_deposit'
    }
  },
  { path: '/deposits',
    name: 'deposits',
    component: () => import('@/pages/deposits/DepositListPage.vue'),
    meta: {
      waiting: false,
      auth: true,
      permissions: ['menu_deposit_all']
    },
    menu: {
      text: 'deposit.text_all',
      icon: 'mdi-cash-plus',
      parent: 'menu_deposit'
    }
  },
  { path: '/deposits/:id',
    name: 'deposits-member',
    component: () => import('@/pages/deposits/DepositListPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_deposit_all', 'menu_deposit_waiting']
    },
  },
  { path: '/withdraws',
    name: 'withdraws',
    component: () => import('@/pages/withdraw/WithdrawListPage.vue'),
    meta: {
      waiting: 'all',
      auth: true,
      permissions: ['menu_withdraw_all']
    },
    menu: {
      text: 'withdraw.text_all',
      icon: 'mdi-cash-minus',
      parent: 'menu_withdraw'
    }
  },
  { path: '/withdraws/:id',
    name: 'withdraws-member',
    component: () => import('@/pages/withdraw/WithdrawListPage.vue'),
    meta: {
      auth: true,
      permissions: [
        'menu_withdraw_all',
        'withdraw_action_all',
        'withdraw_action_confirmed',
        'withdraw_action_setConfirm',
        'withdraw_action_completed',
        'withdraw_action_transfer_and_completed'
      ]
    }
  },
  { path: '/waiting/withdraws/confirm',
    name: 'withdraws-confirm',
    component: () => import('@/pages/withdraw/WithdrawListPage.vue'),
    meta: {
      waiting: 'confirm',
      auth: true,
      permissions: ['menu_withdraw_confirm_waiting']
    },
    menu: {
      text: 'withdraw.waiting_confirm',
      icon: 'mdi-cash-minus',
      parent: 'menu_withdraw'
    }
  },
  { path: '/waiting/withdraws/complete',
    name: 'withdraws-complete',
    component: () => import('@/pages/withdraw/WithdrawListPage.vue'),
    meta: {
      auth: true,
      waiting: 'complete',
      permissions: ['menu_withdraw_complete_waiting']
    },
    menu: {
      text: 'withdraw.waiting_complete',
      icon: 'mdi-cash-minus',
      parent: 'menu_withdraw'
    }
  },
  { path: '/configs', order: 9,
    name: 'configs',
    component: () => import('@/pages/configs/ConfigListPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_config']
    },
    menu: {
      text: 'config',
      icon: 'mdi-application-cog'
    }
  },
  { path: '/sms', order: 8.1,
    name: 'sms',
    component: () => import('@/pages/sms/SmsListPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_sms']
    },
    menu: {
      text: 'sms.text_all',
      icon: 'mdi-cellphone-message',
      parent: 'menu_sms'
    }
  },
  { path: '/waiting/sms', order: 8.2,
    name: 'sms_waiting',
    component: () => import('@/pages/sms/SmsListPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_sms'],
      match: true
    },
    menu: {
      text: 'sms.waiting',
      icon: 'mdi-cellphone-message',
      parent: 'menu_sms'
    },
  },
  { path: '/statements/deposits/free', order: 9,
    name: 'statements_deposits_free',
    component: () => import('@/pages/statements/StatementListPage.vue'),
    meta: {
      auth: true,
      type: 'deposit',
      match: false
    },
    menu: {
      text: 'รายการฝาก ( internet banking )',
      icon: 'mdi-cash-plus',
      parent: 'menu_deposit'
    }
  },
  { path: '/statements/deposits/all', order: 9,
    name: 'statements_deposits_all',
    component: () => import('@/pages/statements/StatementListPage.vue'),
    meta: {
      auth: true,
      type: 'deposit'
    },
    menu: {
      text: 'รายการฝาก ทั้งหมด ( internet banking )',
      icon: 'mdi-cash-plus',
      parent: 'menu_deposit'
    }
  },
  { path: '/statements/withdraws/free', order: 9,
    name: 'statements_withdraws_free',
    component: () => import('@/pages/statements/StatementListPage.vue'),
    meta: {
      auth: true,
      type: 'withdraw',
    },
    menu: {
      text: 'รายการถอน ( internet banking )',
      icon: 'mdi-cash-minus',
      parent: 'menu_withdraw'
    }
  },
  { path: '/sms/withdraws/all', order: 9,
    name: 'statements_withdraws_all',
    component: () => import('@/pages/statements/StatementListPage.vue'),
    meta: {
      auth: true,
      type: 'withdraw',
    },
    menu: {
      text: 'รายการถอน ทั้งหมด ( internet banking )',
      icon: 'mdi-cash-minus',
      parent: 'menu_withdraw'
    }
  },
  { path: '/sms/deposit/free', order: 9,
    name: 'sms_deposit_free',
    component: () => import('@/pages/sms/SmsListPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_sms_deposit_free'],
      type: 'deposit',
      match: false
    },
    menu: {
      text: 'รายการฝาก sms',
      icon: 'mdi-cellphone-message',
      parent: 'menu_deposit'
    }
  },
  { path: '/sms/deposit/all', order: 9,
    name: 'sms_deposit_all',
    component: () => import('@/pages/sms/SmsListPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_sms_deposit_all'],
      type: 'deposit',
    },
    menu: {
      text: 'รายการฝาก sms ทั้งหมด',
      icon: 'mdi-cellphone-message',
      parent: 'menu_deposit'
    }
  },
  { path: '/sms/withdraw/free', order: 9,
    name: 'sms_withdraw_free',
    component: () => import('@/pages/sms/SmsListPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_sms_withdraw_free'],
      type: 'withdraw',
      match: false
    },
    menu: {
      text: 'รายการถอน sms',
      icon: 'mdi-cellphone-message',
      parent: 'menu_withdraw'
    }
  },
  { path: '/sms/withdraw/all', order: 9,
    name: 'sms_withdraw_all',
    component: () => import('@/pages/sms/SmsListPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_sms_withdraw_all'],
      type: 'withdraw',
    },
    menu: {
      text: 'รายการถอน sms ทั้งหมด',
      icon: 'mdi-cellphone-message',
      parent: 'menu_withdraw'
    }
  },
  { path: '/coupon/all', order: 9,
    name: 'coupon_all',
    component: () => import('@/pages/coupons/CouponListPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_coupon_all'],
    },
    menu: {
      text: 'รายการคูปอง',
      icon: 'mdi-ticket',
    }
  },
  { path: '/coupon_member/all', order: 9,
    name: 'coupon_member_all',
    component: () => import('@/pages/coupon_members/CouponMemberListPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_coupon_member_all'],
    },
    menu: {
      text: 'รายการคูปองสมาชิก',
      icon: 'mdi-ticket-account',
    }
  },
  { path: '/history/transfer',
    name: 'history_transfer',
    component: () => import('@/pages/histories/TransferPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_member_transfer_history'],
    },
    menu: {
      text: 'โอนยอด',
      icon: 'mdi-code-tags',
    }
  },
  { path: '/outstand/list',
    name: 'outstand_list',
    component: () => import('@/pages/outstands/OutStandListPage.vue'),
    meta: {
      auth: true,
      // permissions: [],
    },
    menu: {
      text: 'ยอดค้างพนัน',
      icon: 'mdi-currency-btc',
    }
  },
  { path: '/event/sport/list',
    name: 'sport_list',
    component: () => import('@/pages/events/sport/SportListPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_sport'],
    },
    menu: {
      text: 'กิจกรรมกีฬา',
      icon: 'mdi-soccer',
    }
  },
  { path: '/event/lottery/list',
    name: 'lottery_list',
    component: () => import('@/pages/events/lottery/LotteryListPage.vue'),
    meta: {
      auth: true,
      permissions: ['menu_lottery'],
    },
    menu: {
      text: 'กิจกรรมหวย',
      icon: 'mdi-ticket-confirmation-outline',
    }
  },
]
