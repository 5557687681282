<template>
  <page
    :title="$t('coupon_member.text')"
    :breadcrumbs="breadcrumbs"
    :loading="isLoading"
    :headers="headers"
    :options.sync="options"
    :items="coupon_members"
    :total="total"
    :filter="filter"
    @update:options="onSearch"
    class="flex-grow-1"
  >
    <template slot="filters">
      <v-text-field
        v-model="filter['name']"
        :label="$t('coupon_member.name')"
        class="mx-1"
      />
      <v-text-field
        v-model="filter['ufa_user']"
        :label="$t('coupon_member.ufa_user')"
        class="mx-1"
      />
      <v-text-field
        :value="dateTh"
        :label="$t('coupon_member.date')"
        class="mx-1"
        readonly
        @click="datepickerShow = true"
      />
      <v-text-field
        v-model="filter['start_time']"
        :label="$t('coupon_member.start_time')"
        class="mx-1"
        readonly
        @click="timepickerStartTimeShow = true"
      />
      <v-text-field
        v-model="filter['end_time']"
        :label="$t('coupon_member.end_time')"
        class="mx-1"
        readonly
        @click="timepickerEndTimeShow = true"
      />
    </template>
    <template v-slot:outside>
      <date-picker
        type="date"
        locale="th"
        format="YYYY-MM-DD"
        custom-input="#input"
        :show="datepickerShow"
        v-model="filter['date']"
        :editable="true"
        @close="datepickerShow = false"
        auto-submit
      >
        <template #submit-btn="{ canSubmit, color, submit }">
          <button
            type="button"
            :disabled="!canSubmit"
            :style="{ color }"
            @click="submit"
            v-text="'เลือกวันเวลา'"
          />
        </template>
        <template #cancel-btn="{ color }">
          <button
            type="button"
            :style="{ color }"
            @click="vm.visible = false"
            v-text="'ยกเลิก'"
          />
        </template>
        <template v-slot:header-year="{ selectedDate }">
          พ.ศ. {{ selectedDate.xYear() + 543 }}
        </template>
        <template v-slot:year-item="{ year }">
          {{ year.year() + 543 }}
        </template>
      </date-picker>

      <time-picker
        v-model="filter['start_time']"
        :show="timepickerStartTimeShow"
        @close="timepickerStartTimeShow = false"
      />

      <time-picker
        v-model="filter['end_time']"
        :show="timepickerEndTimeShow"
        @close="timepickerEndTimeShow = false"
      />
    </template>
  </page>
</template>

<script>
import Page from '../../layouts/PageLayout.vue'
import { mapGetters, mapActions } from 'vuex'
import TimePicker from '../../components/common/TimePicker.vue';
import moment from 'moment';
export default {
  components: { Page, TimePicker },
  data() {
    return {
      datepickerShow: false,
      timepickerStartTimeShow: false,
      timepickerEndTimeShow: false,
      isLoading: false,
      breadcrumbs: [
        {
          text: this.$t('coupon_member.text'),
        },
        {
          text: this.$t('common.list'),
        },
      ],
      headers: [
        { text: this.$t('coupon_member.id'), value: 'id' },
        { text: this.$t('coupon_member.name'), value: 'coupon.name' },
        { text: this.$t('coupon_member.ufa_user'), value: 'member.ufa_user' },
        { text: this.$t('coupon_member.comment'), value: 'comment' },
        { text: this.$t('coupon_member.created_at'), value: 'created_at', type: 'date' },
        { text: this.$t('coupon_member.updated_at'), value: 'updated_at', type: 'date' }
      ],
      options: {},
      filter: {
        name: '',
        ufa_user: '',
        date: '',
        start_time: '',
        end_time: '',
      }
    }
  },
  created() { },
  mounted() {
    this.onSearch()
  },
  methods: {
    ...mapActions('coupon_member', ['getCouponMembers']),
    async onSearch(options) {
      this.isLoading = true
      await this.getCouponMembers({
        ...options,
        ...this.filter
      })
      this.isLoading = false
    },
  },
  computed: {
    ...mapGetters('coupon_member', ['coupon_members', 'total']),
    dateTh(){
      if (!this.filter['date']) return ''
      return moment(this.filter['date']).format('DD/MM/YYYY')
    }
  }
}
</script>

<style>
</style>
