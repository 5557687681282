import { call } from '../plugins/axios'

export default {
  namespaced: true,
  state: () => ({
    list: [],
    total: 0,
    current: null
  }),
  getters: {
    list: state => state.list,
    total: state => state.total,
    current: state => state.current
  },
  mutations: {
    setList: (state, data) => state.list = data,
    setTotal: (state, data) => state.total = data,
    setCurrent: (state, data) => state.current = data
  },
  actions: {
    setCurrent({ commit }, data) {
      commit('setCurrent', data)
    },
    async getList({ commit, dispatch }, data = {}) {
      return await call('event/sport/list', data)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          if (data.rows) commit('setList', data.rows)
          if (data.count) commit('setTotal', data.count)

          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
        })
    },
    async create({ dispatch }, data) {
      return await call('event/sport/create', data)
        .then(({data}) => {
          if (!data.error) dispatch('app/showSuccess', { success: { message: 'สร้างข้อมูลสำเร็จ' } }, { root: true })
          return data
        })
        .catch((data) => {
          if (data.message) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else dispatch('app/showError', { error: { message: data.message || 'พบข้อผิดพลาดในระบบ' } }, { root: true })
          return data
        })
    },
    async update({ dispatch }, data) {
      return await call('event/sport/update', data)
        .then(({ data }) => {
          if (!data.error) dispatch('app/showSuccess', { success: { message: 'อัพเดทข้อมูลสำเร็จ' } }, { root: true })
          return data
        })
        .catch((data) => {
          if (data.message) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else dispatch('app/showError', { error: { message: data.message || 'พบข้อผิดพลาดในระบบ' } }, { root: true })
          return data
        })
    },
    async finish({ dispatch }, id) {
      return await call('event/sport/finish', id)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
        })
    },
    async cancel({ dispatch }, id) {
      return await call('event/sport/cancel', id)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
        })
    },
    async clear({ commit }) {
      commit('setList', [])
      commit('setTotal', 0)
    }
  }
}
