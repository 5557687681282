<template>
  <v-dialog v-model="value" :width="isLoading ? '300px' : '70%'">
    <v-card v-if="isLoading">
      <v-card-title class="text-h5 grey lighten-2">

      </v-card-title>
      <v-card-text class="d-flex justify-center py-2" style="height: 100px">
        <v-progress-circular :size="70" :width="7" color="warning" indeterminate
          >loading</v-progress-circular
        >
      </v-card-text>
    </v-card>
    <v-card id="casino" v-else>
      <v-card-title class="text-h5 grey lighten-2">
        ตั้งค่าคาสิโน
      </v-card-title>
      <v-card-text>
        <form ref="form" :loading="isSubmitting" @submit.prevent="onSubmit" @click="onClick">
          <div v-html="html"></div>
          <div class="d-flex justify-end pt-2">
            <v-btn :loading="isSubmitting" @click="submit">{{ $t('common.submit') }}</v-btn>
          </div>
        </form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props:{
    value: {
      type: Boolean,
      default: false
    },
    ufa_user: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      html: '',
      referer: '',
    }
  },
  created() {},
  async mounted() {

  },
  methods: {
    ...mapActions('member', ['getFormCasino', 'submitFormCasino']),
    onClick(e) {
      const temp0 = e.target
      if (temp0.classList.toString().includes('box-title')) {
        const box = temp0.parentNode.parentNode
        box.querySelector('.box-body').classList.toggle('display')
      }
    },
    async submit() {
      const data = Object.fromEntries(new FormData(this.$refs.form).entries())
      this.isSubmitting = true
      await this.submitFormCasino({data, referer: this.referer})
        .then(data => {
          if(!data.error) {
            this.value = false
            this.$emit('input', false)
          }
        })
      this.isSubmitting = false
    }
  },
  computed: {},
  watch: {
    async value(val) {
      if (val) {
        this.isLoading = true
        await this.getFormCasino(this.ufa_user)
          .then(data => {
            this.html = data.html.replace(/<br>/g, '')
            this.referer = data.referer
          })
        this.isLoading = false
      } else this.$emit('input', false)
    }
  }
}
</script>

<style>
  #casino select, #casino input {
    padding: 0.2rem 0.5rem;
    -moz-appearance: auto !important;
    -webkit-appearance: auto !important;
    background-color: #fff;
    border-style: solid !important;
    margin: 0.2rem;
    border-radius: 0.2rem;
  }
  #casino .box-title {
    cursor: pointer;
  }
  #casino form .box-info > .box-header,
  #casino form .box-body > div:nth-child(1),
  #casino form .box-footer {
    display: none;
  }

  /* #casino .box-body {
    display: none;
  }
  #casino .box-body.display {
    display: block;
  } */
</style>
