<template>
  <v-dialog v-model="value" width="500px">
    <v-card>
      <v-card-title> commnet </v-card-title>
      <v-card-text>
        <v-text-field v-model="txtComment" :label="label" />
        <div class="d-flex align-end justify-end">
          <v-btn @click="$emit('change', txtComment)" :loading="loading">แก้ไข</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    comment: {
      type: [String, null],
    },
    label: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      txtComment: ''
    }
  },
  created() { },
  mounted() { },
  methods: {},
  computed: {},
  watch: {
    value(v) {
      if (v) {
        this.txtComment = ''
      }
    },
    comment(text) {
      this.txtComment = text
    }
  }
}
</script>

<style>
</style>
