import i18n from '../plugins/vue-i18n'
export default {
  namespaced: true,
  state: () => ({
    locale: 'th',
    page_loading: false,
    toast: {
      show: false,
      color: 'black',
      message: '',
      timeout: 3000
    },
    config: {}
  }),
  getters: {
    locale: (state) => state.locale,
    page_loading: (state) => state.page_loading,
    config: (state) => state.config
  },
  mutations: {
    setLocale: (state, value) => {
      //state.locale = value
      i18n.locale = value
    },
    showToast: (state, toast) => {
      const { color, timeout, message } = toast

      state.toast = {
        message,
        color,
        timeout,
        show: true
      }
    },
    hideToast: (state) => {
      state.toast.show = false
    },
    resetToast: (state) => {
      state.toast = {
        show: false,
        color: 'black',
        message: '',
        timeout: 3000
      }
    },
    setPageLoading: (state, value) => state.page_loading = value,
    setConfig: (state, value) => {
      state.config = value
    }
  },
  actions: {
    showToast: ({ state, commit }, message) => {
      if (state.toast.show) commit('hideToast')

      setTimeout(() => {
        commit('showToast', {
          color: 'black',
          message,
          timeout: 3000
        })
      })
    },

    showError: ({ state, commit }, { message = 'Failed!', error }) => {
      if (state.toast.show) commit('hideToast')

      setTimeout(() => {
        commit('showToast', {
          color: 'error',
          label: message,
          message: error.message,
          timeout: 10000
        })
      })
    },

    showSuccess: ({ state, commit }, message) => {
      if (state.toast.show) commit('hideToast')

      setTimeout(() => {
        commit('showToast', {
          color: 'success',
          message,
          timeout: 3000
        })
      })
    },
    page_loading: ({commit}, loading) => {
      commit('setPageLoading', loading)
    },
    setConfig: ({commit}, config) => {
      commit('setConfig', config)
    }
  }
}
