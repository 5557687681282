<template>
  <page
    :title="$t(`sms.${$route.name}`)"
    :breadcrumbs="breadcrumbs"
    :loading="isLoading"
    :headers="headers"
    :options.sync="options"
    :items="sms"
    :total="count"
    :filter="filter"
    @update:options="onSearch"
    class="flex-grow-1"
  >
    <template v-slot:filters>
      <input-or-text
          v-model="filter.amount"
          type="number"
          :label="$t('sms.amount')"
          clearable
          dense
          hide-details
          input
          outlined
          @click:clear="filter.amount = ''; onSearch()"
        />
        <input-or-text
          :value="dateTh"
          :label="$t('sms.date')"
          readonly
          clearable
          dense
          hide-details
          input
          outlined
          @click="datepickerShow = true"
          @click:clear="filter.date = ''; onSearch()"
        />
        <input-or-text
          v-model="filter.start_time"
          :label="$t('sms.start_time')"
          :maxTime="filter.end_time || '23:59'"
          readonly
          clearable
          dense
          hide-details
          input
          outlined
          @click="timepickerStartTimeShow = true"
        />
        <input-or-text
          v-model="filter.end_time"
          :label="$t('sms.end_time')"
          :minTime="filter.start_time || '00:00'"
          readonly
          clearable
          dense
          hide-details
          input
          outlined
          @click="timepickerEndTimeShow = true"
        />
      <v-spacer></v-spacer>
    </template>
    <template v-slot:[`item.detail`]="{ item }">
      {{ `${item.bank} [${item.bank_number_customer || '-'}] ${item.date} ${item.time} `}}
    </template>
    <template v-slot:[`item.date`]="{ item }">
      {{ dateThai(item.date)  }}
    </template>
    <template v-slot:[`item.tool`]="{ item }">
      <group-tool :value="item"></group-tool>
    </template>

    <template v-slot:outside>
      <date-picker
        type="date"
        locale="th"
        format="YYYY-MM-DD"
        custom-input="#input"
        :show="datepickerShow"
        v-model="filter.date"
        :editable="true"
        @close="datepickerShow = false"
        auto-submit
      >
        <template #submit-btn="{ canSubmit, color, submit }">
          <button
            type="button"
            :disabled="!canSubmit"
            :style="{ color }"
            @click="submit"
            v-text="'เลือกวันเวลา'"
          />
        </template>
        <template #cancel-btn="{ color }">
          <button
            type="button"
            :style="{ color }"
            @click="vm.visible = false"
            v-text="'ยกเลิก'"
          />
        </template>
        <template v-slot:header-year="{ selectedDate }">
          พ.ศ. {{ selectedDate.xYear() + 543 }}
        </template>
        <template v-slot:year-item="{ year }">
          {{ year.year() + 543 }}
        </template>
      </date-picker>

      <time-picker
        v-model="filter.start_time"
        :show="timepickerStartTimeShow"
        @close="timepickerStartTimeShow = false"
      />

      <time-picker
        v-model="filter.end_time"
        :show="timepickerEndTimeShow"
        @close="timepickerEndTimeShow = false"
      />
    </template>
  </page>
</template>

<script>
import InputOrText from "../../components/common/InputOrText.vue";
import Page from '../../layouts/PageLayout.vue';
import TimePicker from '../../components/common/TimePicker.vue';
import GroupTool from '../../components/sms/GroupTool.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    Page,
    InputOrText,
    GroupTool,
    TimePicker
  },
  data() {
    return {
      dialog: false,
      datepickerShow: false,
      timepickerShow: false,
      timepickerStartTimeShow: false,
      timepickerEndTimeShow: false,
      isLoading: false,
      isWaiting: false,
      breadcrumbs: [
        { text: this.$t("sms.text") },
        { text: this.$t("common.list") },
      ],
      current: {},
      options: {},
      headers: [
        { text: this.$t("sms.id"), value: 'id', sortable: false },
        { text: this.$t("sms.message"), value: 'message', sortable: false },
        { text: this.$t("sms.amount"), value: 'amount' },
        { text: this.$t("sms.date"), value: 'date' },
        { text: this.$t("sms.time"), value: 'time' },
        { text: this.$t("sms.detail"), value: 'detail', sortable: false },
        { text: this.$t("sms.comment"), value: 'comment', sortable: false },
        { text: this.$t("sms.tool"), value: 'tool', sortable: false },
      ],
      ...this.$createReset(this, "filter", {
        amount: null,
        date: '',
        end_time: null,
        start_time: null,
      }),
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions('sms', ['getSMS', 'setComment']),
    openDialog(item) {
      this.current = item
      this.form.id = item.id
      this.form.comment = ''
      this.dialog = true
    },
    async onSearch() {
      let sort = {};
      if (!this.options.sortBy || this.options.sortBy.length == 0) {
        sort["sortBy"] = ['id'];
        sort["sortDesc"] = [true];
      }
      this.isLoading = true;
      const data = {
        ...this.options,
        ...sort,
        filter: { ...this.filter, match: this.$route.meta.match,
        type: this.$route.meta.type }
      }
      await this.getSMS(data);
      this.isLoading = false;
    },
    dateThai(value) {
      if (!value) return ''
      const [year, month, day] = value.split('-')
      return `${day}/${month}/${parseInt(year)+543}`
    }
  },
  computed: {
    ...mapGetters('sms', ['sms', 'count']),
    dateTh () {
      return this.dateThai(this.filter.date)
    },
  },
  watch: {
    async ["$route.name"]() {
      this.options.sortBy = []
      this.options.page = 1
      this.$$reset['filter']()
      // await this.onSearch()
    }
  }
}
</script>

<style>

</style>
