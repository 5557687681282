import { call } from '../plugins/axios'
export default {
  namespaced: true,
  state: () => ({
    withdraws: [],
    total: 0,
    current: {}
  }),
  getters: {
    withdraws: (state) => state.withdraws,
    total: (state) => state.total,
    current: (state) => state.current
  },
  mutations: {
    setWithdraws: (state, value) => state.withdraws = value,
    setWithdrawsTotal: (state, value) => state.total = value,
    setCurrent: (state, value) => state.current = value,
    updateWithdraw: (state, withdraw) => {
      let index = state.withdraws.findIndex(r => withdraw.id === r.id)
      if (index >= 0) state.withdraws[index] = Object.assign(state.withdraws[index], withdraw)
    },
    updateCurrent: (state, withdraw) => {
      state.current = Object.assign(state.current, withdraw)
    }
  },
  actions: {
    load: async ({ commit, dispatch }, search = {}) => {
      return await call('withdraw/list', search)
        .then(({data})=>{
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          commit('setWithdraws', data.withdrawals	|| [])
          commit('setWithdrawsTotal', data.total || 0)

          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
          return { error: 500 }
        })
    },
    toggleActive: async ({ commit, dispatch }, id) => {
      return await call('withdraw/toggle-active', id)
        .then(({data})=>{
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else if (data.withdraw) commit('updateWithdraw', data.withdraw)
          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
          return { error: 500 }
        })
    },
    getWithdraw: async ({ commit, dispatch }, id) => {
      return await call('withdraw/get',id)
      .then(({data})=>{
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
        commit('setCurrent', data.withdrawal || {})

        return data
      })
      .catch((error) => {
        dispatch('app/showError', { error }, { root: true })
        return { error: 500 }
      })
    },
    confirmWithdraw: async ({ commit, dispatch }, id) => {
      return await call('withdraw/confirmed', id)
      .then(({data})=>{
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
        if (data.withdrawal) {
          commit('setCurrent', data.withdrawal)
          commit('updateWithdraw', data.withdrawal)
        }
        if (data.chnage) {
          commit('updateWithdraw', data.change)
        }

        return data
      })
      .catch((error) => {
        dispatch('app/showError', { error }, { root: true })
        return { error: 500 }
      })
    },
    setConfirmWithdraw: async ({ commit, dispatch }, id) => {
      return await call ('withdraw/set-confirm', id, commit)
        .then(({data}) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          if (data.change) {
            commit('setCurrent', data.change)
            commit('updateWithdraw', data.change)
          }
          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
          return { error: 500 }
        })
    },
    completeWithdraw: async ({ commit, dispatch }, id) => {
      return await call('/withdraw/completed', id)
      .then(({data})=>{
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
        if (data.withdrawal) {
          commit('setCurrent', data.withdrawal)
          commit('updateWithdraw', data.withdrawal)
        }

        return data
      })
      .catch((error) => {
        dispatch('app/showError', { error }, { root: true })
        return { error: 500 }
      })
    },
    transferAndCompleteWithdraw: async ({ commit, dispatch }, data) => {
      return await call('/withdraw/transfer-and-complete', data)
      .then(({data})=>{
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
        if (data.change){
          commit('updateWithdraw', data.change)
          commit('updateCurrent', data.change)
        }

        return data
      })
      .catch((error) => {
        dispatch('app/showError', { error }, { root: true })
        return { error: 500 }
      })
    },
    cancelWithdraw: async ({ commit, dispatch }, id) => {
      return await call('withdraw/cancel', id)
      .then(({data})=>{
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
        if (data.withdrawal) {
          commit('setCurrent', data.withdrawal)
          commit('updateWithdraw', data.withdrawal)
        }

        return data
      })
      .catch((error) => {
        dispatch('app/showError', { error }, { root: true })
        return { error: 500 }
      })
    },
    clearWithdraws: ({ commit }) => {
      commit('setWithdraws', [])
      commit('setWithdrawsTotal', 0)
    },
    clearMemberWithdraws: ({ commit }) => {
      commit('setMemberWithdraws', [])
      commit('setMemberWithdrawsTotal', 0)
    },
    clearCurrent: ({ commit }) => {
      commit('setCurrent', {})
    }
  }
}
