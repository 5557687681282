<template>
  <div v-if="showInputOrText === 'input'">

    <v-text-field v-if="!$attrs['items'] && !checkbox" v-on="$listeners" v-bind="$attrs">
      <template v-if="$slots['append-outer']" v-slot:append-outer>
        <slot name="append-outer"></slot>
      </template>
    </v-text-field>

    <div v-else-if="checkbox">
     <v-checkbox v-model="attrs['value']" v-on="listeners" v-bind="attrs" @change="check" ></v-checkbox>
    </div>

    <v-select v-else v-on="$listeners" v-bind="$attrs"></v-select>
  </div>

  <div v-else-if="showInputOrText === 'text'" class="py-1">
    <b :style="{ 'min-width': labelWidth +'px'}">
      {{ $attrs['label'] || 'no label' }}
    </b>
    <v-sheet
      rounded
      color="grey lighten-4"
      class="px-1 py-1"
      :style="{'min-height': valueHeight + 'px'}"
    >
      <div class="mx-1">
        <b v-if="!Array.isArray(value_text)" :class="text_class">
          {{ value_text }}
        </b>
        <div v-else>
          <v-chip v-for="text in value_text" :key="text" :class="`mx-1 ${text_class}`">
            {{ text }}
          </v-chip>
        </div>
      </div>
    </v-sheet>
  </div>
</template>

<script>
export default {
  inheritsAttrs: false,
  props: {
    labelWidth:{
      type: Number,
      default: 100
    },
    valueHeight:{
      type: Number,
      default: 36.6
    },
    show: {
      type: String,
      default: 'text'
    },
    input_type: {
      type: String,
      default: 'text'
    },
    text: {
      type: Boolean,
      default: false
    },
    text_class: {
      type: String,
      default: 'black--text'
    },
    input: {
      type: Boolean,
      default: false
    },
    checkbox: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      attrs: this.$attrs,
      listeners: this.$listeners,
    }
  },
  created() {},
  mounted() {},
  methods: {
    check (v) {
      this.$emit('input', v? 1 : 0);
    }
  },
  computed: {
    value_text () {
      // if (this.$attrs['items']) {
      //   const values = Array.isArray(this.$attrs['value']) ? this.$attrs['value'] : [this.$attrs['value']];
      //   let founds = values.map(v => {
      //     const found = this.$attrs['items'].find(i => i.value === v);
      //     return found? found.text : '';
      //   }).filter(r => r.length)
      //   // this.$attrs['items'].find(r => r.value === this.$attrs['value'])
      //   if (founds) return typeof this.$attrs['multiple'] !== 'undefined'? founds : founds.join(', ');
      //   return ''
      // }
      return this.$attrs['value']
    },
    showInputOrText () {
      if (this.text) return 'text'
      else if (this.input) return 'input'
      return this.show
    }
  }
}
</script>
