<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex align-center py-3">
      <div>
        <div class="display-1">{{ title }}</div>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0 py-2"></v-breadcrumbs>
      </div>
      <v-spacer></v-spacer>
      <slot name="btn-right"/>
    </div>

    <v-card>
      <v-form ref="form" :disabled="loading">
        <v-card-text>
          <slot></slot>
        </v-card-text>
      </v-form>
      <v-card-actions class="d-flex justify-end">
        <v-btn v-if="!hideBtnReset" color="red" @click="reset" :disabled="loading" text>{{ $t('form.reset') }}</v-btn>
        <v-btn v-if="!hideBtnSubmit" color="primary" @click="submit" :loading="loading">{{ $t('form.submit') }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    breadcrumbs: {
      type: Array,
      default: () => ([])
    },
    hideBtnSubmit: {
      type: Boolean,
      default: false
    },
    hideBtnReset: {
      type: Boolean,
      default: false
    },

  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {
    reset () {
      this.$emit('reset')
    },
    submit () {
      this.$emit('submit')
    }
  },
  computed: {}
}
</script>

<style>

</style>
