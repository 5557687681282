<template>
  <v-hover
    v-slot="{ hover }"
    close-delay="100"
  >

    <tr :class="item_class(item, hover)">
    <template v-if="item">
      <td
        v-for="header in headers"
        :key="`${header.value} ${new Date()}`"
        :set="(value = header.value.split('.').reduce((o, i) => o[i], item))"
      >
        <template v-if="$slots[`item.${header.value}`]">
          <slot :name="`item.${header.value}`" :item="item"></slot>
        </template>

        <template v-else-if="header.currency">
          {{ (parseFloat(value) || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") }}
        </template>

        <template v-else-if="header.enum">
          {{ header.enum[String(value)] || value }}
        </template>

        <template v-else-if="header.value == 'tool'">
          <slot></slot>
        </template>

        <template v-else>{{ value }}</template>
      </td>
    </template>
  </tr>
  </v-hover>

</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    item_class(item, hover) {
      if (!item) return ""

      if (!item.bank.active) return `red ${hover ? 'lighten-3' : 'grey--text lighten-5'}`
      if (!item.active) return `orange grey--text ${hover ? 'lighten-3' : 'lighten-5'}`
      if (item.amount_counter >= item.amount_counter_maximum) return `yellow ${hover ? 'lighten-3' : 'lighten-5'}`

      return ''
      //return `green ${hover ? 'lighten-3' : 'lighten-5'}`
    },
  },
  computed: {},
};
</script>

<style>
</style>
