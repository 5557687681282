<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $t("manual.manual_credit") }}</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
      <div class="d-flex align-center">
        <v-select
          v-model="form.type"
          :items="typeOptions"
          :label="$t('manual.type')"
          style="width: 10px"
          class="mx-1"
        />
        <v-text-field
        type="number"
          v-model="form.amount"
          :label="$t('manual.amount')"
          :rules="rules.amount"
          input
          class="flex-grow-1 mx-1"
        />
      </div>
      <div>
        <v-textarea
          v-model="form.comment"
          :label="$t('manual.comment')"
          :rules="rules.comment"
        />
      </div>
    </v-form>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
       <v-btn @click="onSubmit" :loading="isLoading"> {{ $t(`manual.${form.type}`) }} </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    member_id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoading: false,
      form: {
        type: 'add',
        amount: 0,
        comment: ''
      },
      rules: {
        amount: [
          v => !!v || 'จำนวนเงินต้องไม่ว่าง',
          v => v > 0 || 'จำนวนเงินต้องมากกว่า 0',
        ],
        comment: [
          v => !!v || 'ความคิดเห็นต้องไม่ว่าง',
        ]
      },
      typeOptions: [
        { text: 'เพิ่ม', value: 'add' },
        { text: 'ลด', value: 'subtract' }
      ]
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions('member', ['manual_credit']),
    onSubmit () {
      if (this.$refs.form.validate()) {
        this.isLoading = true
        this.manual_credit({...this.form, member_id: this.member_id })
          .then((data) => {
            this.isLoading = false
            if(!data.error) {
              this.$refs.form.reset()
              this.form.type = 'add'
              this.$emit('complete', true)
            }
          })
          .catch(() => {
            this.isLoading = false
          })
      }
    }
  },
  computed: {}
}
</script>

<style>

</style>
