import { signInWithToken, signout } from '../plugins/firebase'
import { call } from '../plugins/axios'

export default {
  namespaced: true,
  state: () => ({
    ip: '',
    authenticated: false,
    token: '',
    hash: ''
  }),
  getters: {
    authenticated: (state) => state.authenticated,
    hash: (state) => state.hash,
    ip: (state) => state.ip,
  },
  mutations: {
    setAuthenticated: (state, value) => state.authenticated = value,
    setToken: (state, value) => state.token = value,
    setIp: (state, value) => state.ip = value,
    setHash: (state, value) => state.hash = value
  },
  actions: {
    async login ({ dispatch, commit }, data) {
      return await call('/login', data)
        .then(async ({ data }) => {
          console.log('login', data)
          if (data.error) return dispatch('app/showError', { error: { message: data.message } }, { root: true })
          if (data.hash) commit('setHash', data.hash)
          if (data.token) {
            await signInWithToken(data.token)
            commit('setToken', data.token)
          }
          return data
        })
        .catch((data) => {
          console.log('error', data)
          if (data.error) return dispatch('app/showError', { error: { message: data.message } }, { root: true })
        })
    },
    async loginByToken ({ state, commit, dispatch }) {
      if (state.token.length == 0) return
      await signInWithToken(state.token)
        .then(async () => {
          commit('setAuthenticated', true)
        })
        .catch((error)=> dispatch('app/showError', { error: { message: error.message } }, { root: true }))
    },
    async logout ({ commit }) {
      await signout()
        .then(()=>{
          commit('setAuthenticated', false)
          commit('setToken', '')
          commit('setHash', '')
          commit('user/setProfile', {}, { root: true })
        })
    },
    async checkOnline ({ dispatch }) {
      return await call('/check-online')
        .catch(async (data) => {
          if (data.error && data.message === 'message.authorization') {
            dispatch('logout')
            return data
          }
        })
    }
  }
}
