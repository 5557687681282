<template>
  <div>
    <v-card>
      <v-card-title>{{ $t("statement.text") }}</v-card-title>
      <v-card-text>
        <s-label-text :value="current.id" :label_text="$t('statement.id')" />
        <s-label-text
          :value="current.bank.bank_name"
          :label_text="$t('bank.bank_deposit')"
        />
        <s-label-text
          :value="current.bank.number"
          :label_text="$t('bank.bank_number')"
        />
        <hr />
        <s-label-text
          :value="current.bank_name"
          :label_text="$t('bank.bank_player')"
        />
        <s-label-text
          :value="current.bank_no"
          :label_text="$t('bank.bank_number')"
        />
        <s-label-text
          :value="current.amount"
          :label_text="$t('statement.amount')"
        />
        <s-label-text
          :value="current.date"
          :label_text="$t('statement.bank_time')"
        />

        <s-label-text
          :value="current.comment"
          :label_text="$t('statement.comment')"
        /><v-row>
          <v-col
            ><s-label-text
              :value="current.is_checked"
              :label_text="$t('statement.is_checked')"
            />
          </v-col>
          <v-col
            ><s-label-text
              :value="current.is_done"
              :label_text="$t('statement.is_done')"
          /></v-col>
        </v-row>
        <s-label-text
          :value="current.created_at"
          :label_text="$t('statement.created_at')"
        />
        <s-label-text
          :value="current.updated_at"
          :label_text="$t('statement.updated_at')"
        />
        <div v-if="isEdit" class="d-flex justify-end mt-3">
          <v-btn @click="dialog = true" class="primary">
            {{ $t("statement.comment") }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" width="400px">
      <v-card>
        <v-card-title>commnet</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="comment"
            :counter="250"
            :rules="rules.comment"
            label="comment"
          ></v-textarea>
          <v-btn
            :loading="isLoading"
            class="mt-3"
            color="primary"
            style="width: 100%"
            @click="submit"
          >
            submit
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    current: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      comment: "",
      rules: {
        comment: [
          (v) => !!v || this.$t("message.required"),
          (v) => v.length <= 250 || this.$t("message.text_max", 250),
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions("statement", ["setComment"]),
    async submit() {
      this.isLoading = true;
      await this.setComment({
        id: this.current.id,
        comment: this.comment,
      }).then((data) => {
        if (!data.error) {
          this.reset();
          this.$emit("change");
        }
      });
      this.isLoading = false;
    },
    reset() {
      this.isLoading = false;
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters('user', ['profile']),
    canCommnet () {
      if (!this.profile) return false
      return this.profile.chkAction(['statement_action_all', 'statement_action_set_comment'])
    }
  },
};
</script>

<style>
</style>
