<template>
  <v-btn icon :disabled="!item.active" :loading="loading.active" @click="setActive">
    <v-icon>mdi-bank{{ !item.active ? "-off" : "" }}</v-icon>
  </v-btn>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props:{
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: {
        active: false,
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions('bank', ['bank_active']),
    async setActive() {
      this.loading.active = true
      await this.bank_active(this.item.id)
      this.loading.active = false
    },
  },
  computed: {},
};
</script>

<style>
</style>
