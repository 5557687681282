<template>
<div>
  <div class="d-flex">
    <v-btn icon @click="openDialog(value.id, false)">
      <v-icon>mdi-eye</v-icon>
    </v-btn>
    <v-btn v-if="canEdit && value.type !== 'ฝากด่วน'" :disabled="!!value.complete" icon @click="openDialog(value.id, true)">
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-btn v-if="canActive && value.type !== 'ฝากด่วน' && !value.active" :disabled="loading.cancel || !!value.complete" :loading="loading.cancel" icon @click="active(value.id)">
      <v-icon>mdi-check</v-icon>
    </v-btn>
    <v-btn v-if="canCancel && value.type !== 'ฝากด่วน' && value.active" :disabled="loading.cancel || !!value.complete" :loading="loading.cancel" icon @click="cancel(value.id)">
      <v-icon>mdi-cancel</v-icon>
    </v-btn>
  </div>
  <deposit-dialog
    v-model="dialog"
    :key="value.id"
    :deposit_id="value.id"
    :is_edit="isEdit"
    @current_change="$emit('change')"
  />
</div>

</template>

<script>
import DepositDialog from './DepositDialog.vue'
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    DepositDialog,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      isEdit: false,
      loading: {
        cancel: false,
      }
    }
  },
  computed: {
    ...mapGetters('user', ['profile']),
    canEdit() {
      if(!this.profile) return false
      return this.profile.chkAction([
        'deposit_action_all',
        'deposit_action_set_complete',
        'deposit_action_set_active',
        'deposit_action_set_can_upload',
        'deposit_action_set_complete',
        'deposit_action_set_cancel'
      ])
    },
    canActive () {
      if (!this.profile) return false
      return this.profile.chkAction([
        'deposit_action_all',
        'deposit_action_set_active'
      ])
    },
    canCancel () {
      if (!this.profile) return false
      return this.profile.chkAction([
        'deposit_action_all',
        'deposit_action_set_cancel'
      ])
    },
    canToggleActive() {
      if(!this.profile) return false
      return this.profile.chkAction(['deposit_action_all', 'deposit_action_set_active', 'deposit_action_set_cancel'])
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions("deposit", [
      "load",
      "clearDeposits",
      "cancelDeposit",
      "activeDeposit",
    ]),
    async cancel(id) {
      this.loading.cancel = true;
      await this.cancelDeposit({ id, comment: "รายกาถูกยกเลิก" }).then(() =>
        this.$emit('change')
      );
      this.loading.cancel = false;
    },
    async active(id) {
      this.loading.cancel = true;
      await this.activeDeposit(id).then(() => this.$emit('change'));
      this.loading.cancel = false;
    },
    openDialog(id, isEdit) {
      this.dialog = true;
      this.isEdit = isEdit;
    }
  },
  watch: {
    value(val) {
      this.current = val
    }
  }
};
</script>

<style>
</style>
