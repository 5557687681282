<template>
  <table-page
    :title="$t('sport.text')"
    :breadcrumbs="breadcrumbs"
    :loading="isLoading"
    :headers="headers"
    :items="list"
    :total="total"
    :options.sync="options"
    :filter="filter"
    @update:options="onSearch"
  >
    <template v-if="canCreate" slot="btn-right">
      <v-btn @click="dialogCreate()">สร้าง</v-btn>
    </template>

    <v-dialog v-model="dialog">
      <sport-page :actionType="dialogType" @success="closeAndReload()"></sport-page>
    </v-dialog>

    <template v-slot:[`item.action`]="{ item }">
      <v-btn  @click="dialogShow(item)" icon>
        <v-icon>mdi-eye</v-icon>
      </v-btn>
      <v-btn v-if="canEdit(item)" @click="dialogUpdate(item)" icon>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>

     <template v-slot:[`item.pending`]="{ item }">
      <span v-if="item.pending">กำลังดำเนินการ</span>
      <span v-else-if="!item.active">เสร็จสิ้น</span>
      <span v-else>รอ</span>
    </template>
  </table-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SportPage from './SportPage';
import moment from 'moment';
export default {
  components: {
    SportPage,
  },
  data() {
    return {
      isLoading: false,
      dialogType: 'create',
      dialog: false,
      options: {},
      breadcrumbs: [{
        text: this.$t('member.text')
      }, {
        text: this.$t('common.list')
      }],
      headers: [
        { text: 'ชื่อ', value: 'name' },
        { text: 'ราคา', value: 'price' },
        { text: 'รางวัล', value: 'reword' },
        { text: 'เริ่มต้น', value: 'start_at', type: 'date' },
        { text: 'สิ้นสุด', value: 'exp_at', type: 'date' },
        { text: 'สร้างเมื่อ',  value: 'created_at', type: 'date' },
        { text: 'สถานะ', value: 'pending' },
        { text: 'จัดการ', value: 'action' }
      ],
      filter: {}
    }
  },
  methods: {
    ...mapActions('event_sport', ['getList',  'setCurrent']),
    async onSearch() {
      this.isLoading = true
      //await new Promise(resolve => setTimeout(resolve, 1000))
      await this.getList({
        ...this.options,
        ...(this.options.sortBy.length? { sortBy: this.options.sortBy } : { sortBy: ['id'] }),
        ...(this.options.sortDesc.length? { sortDesc: this.options.sortDesc } : { sortDesc: [true] }),
      })
      this.isLoading = false
    },
    closeAndReload () {
      this.dialog = false
    },
    dialogCreate () {
      this.dialogType = 'create'
      this.setCurrent(null)
      this.dialog = true
    },
    dialogUpdate (item) {
      this.dialogType = 'update'
      this.setCurrent(item)
      this.dialog = true
    },
    dialogShow (item) {
      this.dialogType = 'show'
      this.setCurrent(item)
      this.dialog = true
    },
    canEdit(item) {
      if (!this.profile) return false
      return item.active && this.profile.chkAction([
        'sport_action_all',
        'sport_action_update',
      ])
    },
    exp(date) {
      return !moment().isBefore(date)
    }
  },
  computed: {
    ...mapGetters('event_sport', ['list', 'total']),
    ...mapGetters('user', ['profile']),
    canCreate () {
      if (!this.profile) return false
      return this.profile.chkAction([
        'sport_action_all',
        'sport_action_create',
      ])
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.onSearch()
      }
    }
  },
}
</script>
