import i18n from '../plugins/vue-i18n'
const translate = (row) => { return {...row, text: i18n.t(row['text']) }}
export default {
  role: [
    { text: 'role.owner', value: 'owner' },
    { text: 'role.admin', value: 'admin' },
    { text: 'role.agent', value: 'agent' },
    { text: 'role.guest', value: 'guest' }
  ].map(translate),
  active: [
    { text: 'active.active', value: 1 },
    { text: 'active.inactive', value: 0 }
  ].map(translate),
}
