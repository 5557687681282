import { call } from '../plugins/axios'
import { Profile } from '../helper/permission'

export default {
  namespaced: true,
  state: () => ({
    current: {},
    users: [],
    total: 0,
    profile: new Profile({})
  }),
  getters: {
    users: (state) => state.users,
    total: (state) => state.total,
    current: (state) => state.current,
    profile: (state) => state.profile
  },
  mutations: {
    setUsers: (state, value) => state.users = value,
    updateUser: (state, user) => {
      let index = state.users.findIndex(r => user.id === r.id)
      if (index >= 0) state.users[index] = Object.assign(state.users[index], user)
    },
    setUsersTotal: (state, value) => state.total = value,
    setProfile: (state, value) => state.profile.clearAndUpdate({ permissions: [], ...value }),
    setCurrent: (state, value) => state.current = { permissions: [], ...value }
  },
  actions: {
    refresh: async () => {
      return await call('user/refresh', null)
    },
    get_profile: ({commit}) => {
      return call('user/profile')
        .then(({data}) => {
          if (data.error) commit('app/showError', { error: { message: data.message } }, { root: true })
          if (data.profile) commit('setProfile', data.profile)

          return data.profile
        })
    },
    load: async ({commit, dispatch}, search) => {
      return await call('user/list', search)
        .then(({data}) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          commit('setUsers', data.users || [])
          commit('setUsersTotal', data.total || 0)

          return data
        })
        .catch((error) => dispatch('app/showError', { error }, { root: true }))
    },
    get_user: async ({commit, dispatch}, id) => {
      return await call('user/get', id)
      .then(({data}) => {
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
        if (data.user) commit('setCurrent', data.user || {})

        return data
      })
      .catch((error) => dispatch('app/showError', { error }, { root: true }))
    },
    toggle_active: async ({commit, dispatch}, id) => {
      return await call('user/toggle-active', id)
      .then(({data}) => {
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
        if (data.user) commit('updateUser', data.user)
        if (data.change) commit('updateUser', data.change)

        return data
      })
      .catch((error) => dispatch('app/showError', { error }, { root: true }))
    },
    update_user: async ({commit, dispatch}, data) => {
      return await call('user/update', data)
      .then(({data}) => {
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
        if (data.user) commit('updateUser', data.user)
        if (data.change) commit('updateUser', data.change)

        return data
      })
      .catch((error) => dispatch('app/showError', { error }, { root: true }))
    },
    create_user: async ({dispatch}, user) => {
      return await call('user/create', user)
      .then(({data}) => {
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })

        return data
      })
      .catch((error) => dispatch('app/showError', { error }, { root: true }))
    },
    clear_current: ({ commit }) => commit('setCurrent', { permissions: [] })
  }
}
