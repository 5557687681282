import { initializeApp, getApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions'
import { getAuth, connectAuthEmulator, onAuthStateChanged, signInWithCustomToken,  signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { getDatabase, connectDatabaseEmulator, ref, onValue } from "firebase/database";
// eslint-disable-next-line
const firebaseConfig = {
  apiKey: 'AIzaSyAPtXnUfwmldpsTjBig87QXEVG89Padyc4',
  authDomain: 'ak55bet-e3959.firebaseapp.com',
  databaseURL: 'https://ak55bet-e3959-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'ak55bet-e3959',
  storageBucket: 'ak55bet-e3959.appspot.com',
  messagingSenderId: '954101667178',
  appId: '1:954101667178:web:ee3740f4200d53df41c113',
  measurementId: 'G-MVYKZ3WW1X'
}

const { emulators } = require('../../../firebase.json')

initializeApp(firebaseConfig)

const app = getApp()
const functions = getFunctions(app, 'asia-southeast1')
export const auth = getAuth(app)
const database = getDatabase(app)

if (process.env.NODE_ENV !== 'production') {
  connectFunctionsEmulator(functions, 'localhost', emulators.functions.port)
  if (location.port !== '8084') connectAuthEmulator(auth, 'http://localhost:' + emulators.auth.port)
  if (location.port !== '8084') connectDatabaseEmulator(database, 'localhost', 19000)
}

export const call = (name, param) => httpsCallable(functions, name)(param)
export const signInWithToken = (token) => signInWithCustomToken(auth, token)
export const signin = (user, pass) => signInWithEmailAndPassword(auth, `${user}@admin.ak55bet.com`, pass)
export const signout = () => signOut(auth)

export const check = () => new Promise((resolve) => onAuthStateChanged(auth, (user) => {
  resolve(user)
}))

export const initCheckAuth = (callback) => onAuthStateChanged(auth, async (user) => callback(user))

export const snapAdmin = (adminId, callback) => {
  onValue(ref(database, `admins/${adminId}/profile`), (snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.val()
      callback('profile', data)
    }else console.log('profile not found')
  })
}

export const snapConfig = (callback) => {
  onValue(ref(database, 'appAdmin'), (snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.val()
      callback(data)
    }else console.log('config not found')
  })
}

export default app
