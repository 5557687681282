<template>
  <page-blank-layout :title="title">
    <template v-slot:btn-right>
      <v-btn icon @click="refresh" :loading="isLoading">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-form ref="form" class="d-flex align-center">
          <v-text-field v-if="profile.auth !== 'agent'" v-model="filter['ufa_user']" label="ชื่อผู้ใช้" class="mx-1" />
          <v-text-field v-model="startDateTh" label="วันที่เริ่มต้น" readonly class="mx-1"
            @click="startDatePickerShow = true" />
          <v-text-field v-model="endDateTh" label="วันที่สิ้นสุด" readonly class="mx-1"
            @click="endDatePickerShow = true" />

          <v-btn @click="$refs.form.reset()" text>
            ล้างค่า
          </v-btn>
          <v-btn @click="onSearch" color="primary">
            ค้นหา
          </v-btn>
        </v-form>
        <v-simple-table :loading="isLoading">
          <template v-slot:default>
            <thead>
              <tr class="text-center">
                <th rowspan="2" class="text-center black--text">บัญชี</th>
                <th rowspan="2" class="text-center black--text">ติดต่อ</th>
                <th rowspan="2" class="text-center black--text">สกุลเงิน</th>
                <th rowspan="2" class="text-center black--text">เงินหมุนเวียน</th>
                <th rowspan="2" class="text-center black--text">จำนวนถูกต้อง</th>
                <th rowspan="2" class="text-center black--text">จำนวนสมาชิก</th>
                <th rowspan="2" class="text-center black--text">การนับเดิมพัน</th>
                <th rowspan="2" class="text-center black--text">ยอดรวมของคอมมิชชั่น</th>
                <th colspan="3" class="text-center black--text">สมาชิก</th>
                <th colspan="4" class="text-center green black--text">เอเย่นต์</th>
                <th colspan="4" class="text-center black--text">บริษัท</th>
              </tr>
              <tr class="text-center">
                <!-- Member -->
                <th class="black--text">ชนะ/แพ้</th>
                <th class="black--text">คอมมิชชั่น</th>
                <th class="black--text">ยอดรวม</th>
                <!-- Agent -->
                <th class="black--text lighten-1">จำนวนถูกต้อง</th>
                <th class="black--text lighten-1">ชนะ/แพ้</th>
                <th class="black--text lighten-1">คอมมิชชั่น</th>
                <th class="black--text lighten-1">ยอดรวม</th>
                <!-- Company -->
                <th class="black--text">จำนวนถูกต้อง</th>
                <th class="black--text">ชนะ/แพ้</th>
                <th class="black--text">คอมมิชชั่น</th>
                <th class="black--text">ยอดรวม</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isLoading">
                <td colspan="19" class="text-center">
                  <v-progress-linear indeterminate></v-progress-linear>
                </td>
              </tr>
              <tr v-else v-for="(item, index) in groupLists" :key="item[0]">
                <td v-for="(td, i) in item" :colspan="index === groupLists.length - 1 && i === 0 ? 3 : 1"
                  :key="item[0] + '-' + i" class="text-right">
                  <span v-if="index !== groupLists.length - 1 && i === 0">
                    <v-btn @click="showDetail(td)" text>
                      {{ td }}
                    </v-btn>
                  </span>
                  <span v-else v-html="td"></span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-dialog v-if="openDialog" v-model="openDialog" width="70%">
      <v-card>
        <v-card-text height="70%">
          <member-win-lost-page :member_id="ufa_user" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <date-picker v-model="filter['start_date']" :show="startDatePickerShow"
      :minDate="new Date(Date.now() - 1000 * 60 * 60 * 24 * 60).toJSON()" @close="startDatePickerShow = false"
      @date_th="(th) => startDateTh = th" />

    <date-picker v-model="filter['end_date']" :show="endDatePickerShow" :maxDate="new Date(Date.now()).toJSON()"
      @close="endDatePickerShow = false" @date_th="(th) => endDateTh = th" />

  </page-blank-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PageBlankLayout from '../../layouts/PageBlankLayout.vue'
import MemberWinLostPage from '../members/MemberWinLostPage.vue'
import DatePicker from '../../components/common/DatePicker.vue'
export default {
  components: {
    PageBlankLayout,
    MemberWinLostPage,
    DatePicker
  },
  data() {
    return {
      title: this.$t('winlost.text'),
      ufa_user: '',
      openDialog: false,
      isLoading: false,
      startDatePickerShow: false,
      endDatePickerShow: false,
      startDateTh: '',
      endDateTh: '',
      filter: {}
    }
  },
  created() { },
  async mounted() {
    this.isLoading = true
    await this.onSearch({})
    this.isLoading = false
  },
  methods: {
    ...mapActions('winlost', ['loadGroupList']),
    showDetail(account) {
      this.ufa_user = account.replace(/\*/g, '')
      this.openDialog = true
    },
    async onSearch(){
      this.isLoading = true
      await this.loadGroupList(this.filter)
      this.isLoading = false
    },
    async refresh() {
      this.isLoading = true
      await this.loadGroupList()
      this.isLoading = false
    }
  },
  computed: {
    ...mapGetters('winlost', ['groupLists', 'groupTotal']),
    ...mapGetters('user', ['profile'])
  },
  watch: {
    startDateTh(val) {
      if (!val) this.filter['start_date'] = ''
    },
    endDateTh(val) {
      if (!val) this.filter['end_date'] = ''
    }
  }
}
</script>

<style>
</style>
