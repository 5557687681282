var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('date-picker',{attrs:{"editable":true,"popover":false,"show":_vm.show,"min":_vm.minTime,"max":_vm.maxTime,"auto-submit":"","custom-input":"#input","format":"HH:mm","locale":"th","type":"time"},on:{"close":function($event){return _vm.$emit('close')},"change":function($event){return _vm.$emit('input', _vm.time)}},scopedSlots:_vm._u([{key:"submit-btn",fn:function(ref){
var canSubmit = ref.canSubmit;
var color = ref.color;
var submit = ref.submit;
return [_c('button',{style:({ color: color }),attrs:{"type":"button","disabled":!canSubmit},domProps:{"textContent":_vm._s('ตกลง')},on:{"click":submit}})]}},{key:"cancel-btn",fn:function(ref){
var color = ref.color;
return [_c('button',{style:({ color: color }),attrs:{"type":"button"},domProps:{"textContent":_vm._s('ยกเลิก')},on:{"click":function($event){_vm.vm.visible = false}}})]}},{key:"now-btn",fn:function(ref){
var color = ref.color;
var goToday = ref.goToday;
return [_c('button',{style:({ color: color }),attrs:{"type":"button"},domProps:{"textContent":_vm._s('เวลาปัจจุบัน')},on:{"click":goToday}})]}}]),model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})}
var staticRenderFns = []

export { render, staticRenderFns }