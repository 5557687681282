export default {
  common: {
    cancel: 'ยกเลิก',
    submit: 'ตกลง',
    main_wallet: 'กระเป๋าหลัก',
    back: 'กลับ',
    bath: 'บาท',
    list: 'รายการ',
    tool: 'เครื่องมือ',
    date: 'วันที่',
    time: 'เวลา',
    create: 'สร้าง',
    login: 'เข้าสู่ระบบ',
    comment: 'หมายเหตุ',
    order: 'ลำดับ',
    no: 'ไม่',
    yes: 'ใช่',
    edit: 'แก้ไข',
    auto: 'อัตโนมัติ',
    byAdmin: 'โดย admin แล้ว'
  },

  form: {
    reset: 'ล้างค่า',
    filter: 'กรอง',
    submit: 'ตกลง',
  },

  message: {
    permissions: 'ท่านไม่สามารถทำกิจกรรมนี้ได้',
    update_success: 'อัพเดทสำเร็จ',
    accountant: 'ผลิตภัณฑ์ประเภทบัญชี',
    auth_fail: 'ไม่สามารถเข้าสู่ระบบได้ กรุณาเข้าสู่ระบบอีกครั้ง',
    casino: 'ผลิตภัณฑ์ประเภทคาสิโน',
    casino_text: 'คาสิโนออนไลน์ที่ดี',
    deposit_send_success: 'เพิ่มรายการ ฝาก เรียบร้อย',
    game: 'ผลิตภัณฑ์ประเภทเกมส์',
    history: 'ประวัติ',
    invalid: 'กรุณากรอกข้อมูลไหม่',
    logout_confirm: 'ต้องการออกจากระบบหรือไม่ ?',
    member_not_found: 'ไม่พบบัญชีผู้ใช้ในระบบ',
    not_found: 'พบข้อผิดพลาดในระบบกรุณาตรวจสอบอีกครั้ง',
    not_visit: 'ไม่สามารถเข้าหน้าดังกล่าวได้ กรูณาเข้าระบบอีกครั้ง',
    phone_already_exist: 'ข้อมูลดังกล่าวมีผู้ใช้อยู่',
    spots: 'ผลิตภัณฑ์ประเภทกีฬา',
    system_fail: 'พบข้อผิดพลาดในระบบ',
    unauthorized: 'กรุณาเข้าสู่ระบบอีกครั้ง',
    withdraw_send_success: 'เพิ่มรายการ ถอน เรียบร้อย',
    otp_mismatch: 'กรุณากรอก otp ให้ถูกต้อง',
    text_max: 'กรุณากรอกไม่เกิน {0} ตัวอักษร',
    waiting_again: 'กรุณาทำรายการใหม่ หลังจากนี้ {time} นาที',
    otp_waiting_again: 'กรุณาทำรายการใหม่ หลังจากนี้ {time} นาที',
    can_withdraw: 'สามารถถอนได้ {amount} บาท',
    withdraw_waiting_queue_free: 'ไม่สามารถถอนได้เนื่องจากยังมีรายการค้าง',
    required: 'กรุณากรอกข้อมูลให้ครบ',
    withdraw_confirm_message: 'ทำการถอนเคดิตจาก สมาชิก แล้วใช่หรือไม่',
    withdraw_complete_confirm_message: 'ทำการโอนเงินเข้าบัญชีของ สมาชิก แล้วใช่หรือไม่',
    deposit_confirm: 'ต้องการฝากเงินเข้า สมาชิก นี้ใช่หรือไม่',
    cancel_confirm: 'ต้องการยกเลิกรายการนี้ใช่หรือไม่',
    not_update: 'ไม่มีการเปลี่ยนแปลงข้อมูล'
  },
  spots: {
    text: 'กีฬา'
  },
  casino: {
    text: 'คาสิโน'
  },
  accountant: {
    text: 'บัญชี'
  },
  history: {
    text: 'ประวัติ'
  },

  login: {
    text: 'เข้าสู่ระบบ',
    phone: 'เบอร์โทรศัพท์',
    password: 'รหัสผ่าน'
  },

  register: {
    text: 'สมัครสมาชิก'
  },

  home: {
    msg: 'คาสิโนออนไลน์ที่ดี'
  },
  user: {
    reference: 'อ้างอิง',
    permissions: 'สิทธิ์การใช้งาน',
    text: 'ผู้ดูแลระบบ',
    id: 'ลำดับ',
    name: 'ชื่อ',
    email: 'อีเมล',
    username: 'ชื่อผู้ใช้',
    active: 'ใช้งาน',
    role: 'สิทธิ์',
    password: 'รหัสผ่าน'
  },
  statement: {
    text: 'ประวัติธนาคาร',
    id: 'ลำดับ',
    bank_number: 'เลขที่บัญชี',
    bank_deposit: 'เข้าบัญชีธนาคาร',
    bank_player: 'จากบัญชีธนาคาร',
    bank_no: 'จากบัญชี',
    amount: 'จำนวนเงิน',
    remark: 'หมายเหตุ',
    date: 'วันที่',
    start_time: 'เวลาเริ่ม',
    end_time: 'เวลาสิ้นสุด',
    bank_time: 'เวลาที่โอน',
    comment: 'คอมเม้นท์',
    is_checked: 'ตรวจสอบ',
    is_done: 'ทำรายการเสร็จสิ้น',
    created_at: 'สร้างเมื่อวันที่',
    updated_at: 'แก้ไขเมื่อวันที่',
    statements_withdraws_free: 'รายการถอน  (internet banking)',
    statements_withdraws_all: 'รายการถอน ทั้งหมด (internet banking)',
    statements_deposits_free: 'รายการฝาก  (internet banking)',
    statements_deposits_all: 'รายการฝาก ทั้งหมด (internet banking)',
  },
  deposit: {
    waiting: 'ฝากเงิน (รอ)',
    text_all: 'ฝากเงิน (ทั้งหมด)',
    before: 'เครดิตก่อนเติม',
    after: 'เครดิตหลังเติม',
    can_upload: 'สามารถอัพโหลดได้',
    active: 'ใช้งาน',
    type: 'ประเภทการฝาก',
    text: 'ฝากเงิน',
    history: 'ประวัติการฝาก',
    id: 'ลำดับ',
    date: 'วันเวลา',
    amount: 'จำนวนเงิน',
    comment: 'คอมเม้นต์',
    status: 'สถานะ',
    confirm: 'ตรวจสอบ',
    complete: 'โอนเคดิตไปยังบัญชี',
    agent_bank_name: 'ธนาคาร',
    agent_bank_number: 'หมายเลขบัญชีสำหรับฝาก',
    created_at: 'สร้างเมื่อวันที่',
    updated_at: 'อัพเดทล่าสุด',
    confirmBy: 'ตรวจสอบ โดย',
    completeBy: 'โอนเคดิตไปยังบัญชี โดย',

    balance_now: 'ยอดเคดิต ณ ปัจจุบัน',
    deposit_bank: 'ธนาคารฝาก',

    deposit_date: 'ฝากเงินวันเวลา',
    slip_bank: 'สลิปฝากเงิน',
    notification: 'แจ้งฝากเงิน',
    not_confirm: 'กำลังตรวจสอบข้อมูล',
    lockedBy: 'ล็อคโดย',
    lockedAt: 'ล็อคเมื่อ',

    status_cancel: 'ยกเลิก',
    status_success: 'สำเร็จ',
    status_confirm: 'อยู่ระหว่างดำเนินการ',
    status_waiting: 'รอดำเนินการ',
    status_waiting_checking: 'รอตรวจสอบ',
    status_waiting_tranfer: 'รอโอนเงิน',
  },
  withdraw: {
    text: 'ถอนเงิน',
    active: 'ใช้งาน',
    text_all: 'ถอนเงิน (ทั้งหมด)',
    waiting_all: 'ถอนเงิน (ทั้งหมด)',
    waiting_confirm: 'ถอนเงิน (รอตรวจสอบ)',
    waiting_complete: 'ถอนเงิน (รอโอนเงิน)',
    history: 'ประวัติการถอน',
    id: 'ลำดับ',
    date: 'วันเวลา',
    amount: 'ยอดถอนเงิน',
    comment: 'คอมเม้นต์',
    status: 'สถานะ',
    fee: 'ค่าธรรมเนียม',
    total: 'รวม',
    created_at: 'สร้างเมื่อวันที่',
    updated_at: 'อัพเดทล่าสุด',
    before: 'ก่อนถอน',
    after: 'หลังถอน',

    confirm: 'หักเคดิตจากบัญชี',
    confirm_auto: 'หักเคดิตจากบัญชี (อัตโนมัติ)',
    confirm_manual: 'หักเคดิตจากบัญชีโดย admin แล้ว',
    complete: 'โอนเงินเข้าบัญชีธนาคาร',
    complete_manual: 'โอนเงินโดย admin แล้ว',
    complete_auto: 'โอนเงิน (อัตโนมัติ)',
    procession_complete: 'กำลังดำเนินการ',
    already_complete: 'ถอนเงินเรียบร้อยแล้ว',

    confirmBy: 'หักเคดิตจากบัญชี โดย',
    completeBy: 'โอนเงินเข้าบัญชีธนาคาร โดย',

    status_cancel: 'ยกเลิก',
    status_success: 'สำเร็จ',
    status_confirm: 'อยู่ระหว่างดำเนินการ',
    status_waiting: 'รอดำเนินการ',
    status_waiting_checking: 'รอตรวจสอบ',
    status_waiting_tranfer: 'รอโอนเงิน',
  },
  sms: {
    text: 'SMS',
    text_all: 'SMS (ทั้งหมด)',
    waiting: 'SMS (รอดำเนินการ)',

    id: 'ลำดับ',
    deposit_id: 'เลขอ้างอิงฝาก',
    message: 'ข้อความ',
    amount: 'จำนวนเงิน',
    date: 'วันที่',
    time: 'เวลา',
    start_time: 'เวลาเริ่ม',
    end_time: 'เวลาสิ้นสุด',
    detail: 'รายละเอียด',
    comment: 'คอมเม้นต์',
    tool: '',
    sms_withdraw_free: 'รายการถอน sms',
    sms_withdraw_all: 'รายการถอน sms (ทั้งหมด)',
    sms_deposit_free: 'รายการฝาก sms',
    sms_deposit_all: 'รายการฝาก sms (ทั้งหมด)',
    sms_waiting: 'SMS (รอดำเนินการ)',
    sms: 'SMS (ทั้งหมด)',
  },
  game: {
    text: 'เกมส์',
    balance: 'ในเกมส์คงเหลือ'
  },
  member: {
    text: 'สมาชิก',
    id: 'ลำดับ',
    ufa_user: 'ชื่อผู้ใช้',
    bank_name: 'ธนาคาร',
    bank_number: 'หมายเลขบัญชี',
    bank_user: 'ชื่อบัญชี',
    tel: 'เบอร์โทรศัพท์',
    line: 'line',
    reference: 'อ้างอิง',
    otp: 'otp',
    level: 'ชั้น',
    vip: 'vip',
    bank_id: 'รหัสบัญชี',
    active: 'ใช้งาน',
    password: 'รหัสผ่าน',
    bank_deposit_id: 'รหัสบัญชีฝาก',
    first_deposit_id: 'รหัสฝากเงินครั้งแรก',
    deposit_remark_en: 'หมายเหตุการฝากเงิน (ภาษาอังกฤษ)',
    deposit_remark_th: 'หมายเหตุการฝากเงิน (ภาษาไทย)',
  },
  winlost: {
    text: 'รายการชนะหรือแพ้',
  },
  bank: {
    text: 'ธนาคาร',
    scb: 'ธนาคารไทยพาณิชย์',
    bay:'กรุงศรีอยุธยา',
    bbl:'กรุงเทพ',
    cimb:'ซีไอเอมบีไทย',
    gsb:'ออมสิน',
    kbank:'กสิกรไทย',
    kk:'เกียรตินาคิน',
    ktb:'กรุงไทย',
    lhbank:'แลนด์แอนด์เฮ้าส์',
    tbank:'ธนชาต',
    tmb:'ทหารไทย',
    uob:'ยูโอบี',
    baac:'ธ.ก.ส.',
    ghb:'ธนาคารอาคารสงเคราะห์',
    ttb:'ทหารไทยธนชาต',
    tisco:'ธนาคารทิสโก้',
    bank_name: 'ธนาคาร',
    name: 'ชื่อบัญชี',
    number: 'หมายเลขบัญชี',
    amount_maximum: 'จำนวนเงินสูงสุดในการถอน',
    amount_current: 'จำนวนเงินคงเหลือ',
    bank_deposit: 'เข้าธนาคาร',
    bank_player: 'จากธนาคาร',
    amount: 'จำนวนเงิน',
    active: 'ใช้งาน',
    withdraw_only: 'ใช้ถอนเงินเท่านั้น',
    id: 'ลำดับ',
  },
  bank_deposit: {
    text: 'ธนาคาร ฝากเงิน',
    id: 'ลำดับ',

    amount: 'ยอดเงิน',
    amount_counter: 'ยอดเงิน',
    amount_counter_maximum: 'ยอดเงินสูงสุด',
    active: 'ใช้งาน',

    first_deposit: 'ฝากเงินครั้งแรก',
    agent_id: 'ตัวแทน',
    cdm_mode: 'cdm mode',
  },
  active:{
    0: 'ไม่ใช้งาน',
    1: 'ใช้งาน',
    'false': 'ไม่ใช้งาน',
    'true': 'ใช้งาน'
  },
  role: {
    owner: 'เจ้าของ',
    admin: 'ผู้ดูแลระบบ',
    agent: 'ตัวแทน',
  },
  coupon: {
    text: 'คูปอง',
    code: 'โค้ดคูปอง',
    amount: 'จำนวนเงิน',
    turnover: 'เทิร์นโอเวอร์',
    comment: 'คอมเม้นต์',
    limited: 'จำกัดจำนวน',
    unlimited: 'ไม่จำกัดจำนวน',
    id: 'รหัส',
    name: 'รายการ',
    status: 'สถานะ',
    history: 'ประวัติการใช้คูปอง',
    send: 'ส่งคูปอง',
    used: 'คูปองถูกใช้แล้ว',
  },
  coupon_member: {
    id: 'ลำดับ',
    text: 'คูปองสมาชิก',
    code: 'โค้ดคูปอง',
    name: 'ชื่อคูปอง',
    amount: 'จำนวนเงิน',
    turnover: 'เทิร์นโอเวอร์',
    ufa_user: 'ผู้ใช้',
    status: 'สถานะ',
    comment: 'คอมเม้นต์',
    start_time: 'เวลาเริ่ม',
    end_time: 'เวลาสิ้นสุด',
    date: 'วันที่',
    created_at: 'สร้างเมื่อวันที่',
    updated_at: 'แก้ไขเมื่อวันที่',
  },
  transfer_history: {
    text: 'โอนยอด',
    ufa_user: 'ผู้ใช้',
    id: 'ลำดับ',
    tel: 'เบอร์โทร',
    amount: 'จำนวนเงิน',
    comment: 'คอมเม้นต์',
    created_at: 'สร้างเมื่อวันที่',
    updated_at: 'แก้ไขเมื่อวันที่',
    deposit: 'ฝากเงิน',
    withdrawal: 'ถอนเงิน',
    wallet: 'กระเป๋า',
    main_wallet: 'กระเป๋าหลัก',
    game_wallet: 'กระเป๋าเกม',
    before: 'ก่อน',
    after: 'หลัง',
    description: 'รายละเอียด',
    type: 'ประเภท',
    from: 'จาก',
    to: 'ถึง',
    by: 'โดย',
    unknown_deposit: 'การฝากเงินที่ไม่ทราบสาเหตุ',
    unknown_withdraw: 'การถอนเงินที่ไม่ทราบสาเหตุ',
    manual: 'เพิ่ม ลดเครดิต จากผู้ดูแลระบบ',
  },
  manual: {
    text: 'เพิ่ม ลดเครดิต จากผู้ดูแลระบบ',
    type: 'ประเภท',
    amount: 'จำนวนเงิน',
    comment: 'คอมเม้นต์',
    add: 'เพิ่ม',
    subtract: 'ลด',
  },

  permission: {
    all: 'ทั้งหมด',
    bank: 'ธนาคาร',
    bank_deposit: 'ธนาคารฝาก',
    config: 'config',
    deposit: 'ฝาก',
    member: 'สมาชิก',
    sms: 'SMS',
    statement: 'ประวัติธนาคาร',
    user: 'สมาชิก',
    withdraw: 'ถอน',
    statement_deposit: 'รายการฝาก',
    statement_withdraw: 'รายการถอน',
    sms_deposit: 'SMS ฝาก',
    coupon: 'รายการคูปอง',
    coupon_member: 'รายการคูปองสมาชิก',
  },
  sport: {
    text: 'กีฬา',
  },
  lottery: {
    text: 'หวย',
    textshow: 'หวย',
  }
}
