<template>
  <div>
    <div class="d-flex">
      <v-btn icon @click="openDialog(false)">
        <v-icon>mdi-eye</v-icon>
      </v-btn>
      <v-btn v-if="canEdit && value.is_checked && !value.is_done && !value.comment" icon @click="openDialog(true)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn v-else-if="value.action_id" @click="goto" icon>
        <v-icon>mdi-page-next-outline</v-icon>
      </v-btn>
    </div>
    <deposit-dialog v-model="depositDialog" :key="value.id" :deposit_id="value.action_id" :is_edit="isEdit"
      @current_change="$emit('change')" />
    <withdraw-dialog v-model="withdrawDialog" :withdraw_id="value.action_id" waiting="waiting" :is_edit="isEdit"
      @current_change="$emit('chnage')" />
    <v-dialog v-model="statementDialog" width="500">
      <statement-page :current="value" :isEdit="isEdit" @change="$emit('change')" />
    </v-dialog>
  </div>
</template>

<script>
import DepositDialog from '../deposit/DepositDialog.vue'
import WithdrawDialog from '../withdraw/WithdrawDialog.vue'
import StatementPage from '../../pages/statements/StatementPage.vue'
import { mapGetters } from "vuex";

export default {
  components: {
    DepositDialog,
    WithdrawDialog,
    StatementPage
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      depositDialog: false,
      statementDialog: false,
      withdrawDialog: false,
      isEdit: false,
      waiting: false,
    }
  },
  created() { },
  mounted() { },
  methods: {
    openDialog(isEdit) {
      this.statementDialog = true;
      this.isEdit = isEdit;
    },
    goto() {
      if (this.value.action === 'deposit') this.depositDialog = true;
      else this.withdrawDialog = true;
    }
  },
  computed: {
    ...mapGetters('user', ['profile']),
    canEdit() {
      if (!this.profile) return false
      return this.profile.chkAction(['statement_action_all', 'statement_action_update'])
    }
  }
}
</script>

<style>
</style>
