<template>
  <date-picker
    v-model="time"
    :editable="true"
    :popover="false"
    :show="show"
    :min="minTime"
    :max="maxTime"
    @close="$emit('close')"
    @change="$emit('input', time)"
    auto-submit
    custom-input="#input"
    format="HH:mm"
    locale="th"
    type="time"
  >
    <template #submit-btn="{ canSubmit, color, submit }">
      <button
        type="button"
        :disabled="!canSubmit"
        :style="{ color }"
        @click="submit"
        v-text="'ตกลง'"
      />
    </template>
    <template #cancel-btn="{ color }">
      <button
        type="button"
        :style="{ color }"
        @click="vm.visible = false"
        v-text="'ยกเลิก'"
      />
    </template>
    <template #now-btn="{ color, goToday }">
      <button
        type="button"
        :style="{ color }"
        @click="goToday"
        v-text="'เวลาปัจจุบัน'"
      />
    </template>
  </date-picker>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, null],
      default: "",
    },
    show: {
      type: [Boolean, null],
      default: false,
    },
    minTime: {
      type: [String, null],
      default: "00:00",
    },
    maxTime: {
      type: [String, null],
      default: "23:59",
    },
  },
  data() {
    return {
      time: "",
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
  watch: {
    show(val) {
      if (!val) this.$emit('input', this.time)
      this.time = this.value;
    },
  },
};
</script>

<style>
</style>
