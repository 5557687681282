<template>
  <v-app >

    <!-- Layout component -->
    <component :is="currentLayout" v-if="isRouterLoaded">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </component>


    <v-snackbar v-model="toast.show" :timeout="toast.timeout" :color="toast.color" style="margin-bottom: 30px;" bottom>
      {{toast.label? $t(toast.label): ''}}{{ $t(toast.message) }}
      <v-btn v-if="toast.timeout === 0" color="white" text @click="toast.show = false">{{ $t('common.close') }}</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

//import config from './configs'

// Layouts
import defaultLayout from './layouts/DefaultLayout'
import blankLayout from './layouts/BlankLayout'
//import errorLayout from './layouts/ErrorLayout.vue'
import { initCheckAuth, snapAdmin, snapConfig  } from './plugins/firebase'
import { axios, api } from './plugins/axios'
import { mapActions } from 'vuex'

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export default {
  components: {
    defaultLayout,
    blankLayout,
    //errorLayout
  },
  data () {
    return { ip: '' }
  },
  methods: {
    ...mapActions('auth', {'member_logout': 'logout', 'checkOnline': 'checkOnline' }),
    async logout () {
      await this.member_logout()
        .then(() => {
          this.$router.push({ name: 'home' })
        })
    },
    async windowFocus () {
      if(this.token) await this.checkOnline()
    },
  },
  created () {
    window.addEventListener('focus', this.windowFocus)

    initCheckAuth( async (user) => {
      if (user) {
        let profile = parseJwt(await user.getIdToken(true))

        snapAdmin(profile.sub, (typeData, d) => {
          if (typeData === 'profile') {
            this.$store.commit('user/setProfile', d)
          }
        })
        snapConfig((d) => {
          this.$store.commit('app/setConfig', d)
        })
        this.ip = await axios.get(`https://ak55bet-e3959.as.r.appspot.com/ip?t=${Date.now()}`)
          .then(res => {
            return res.data.ip_address || ''
          })
          .catch(() => {
            return ''
          })
        this.$store.commit('auth/setIp', this.ip)
        this.$store.commit('auth/setAuthenticated', true, { root: true })
      }
      else {
        this.$store.commit('auth/setAuthenticated', false, { root: true })
      }
      api.defaults.headers.common['hash'] = this.hash
      this.windowFocus()
    })
  },
  mounted () {
    api.defaults.headers.common['hash'] = this.hash
  },
  computed: {
    ...mapState('app', ['toast',  'config']),
    ...mapState('user', ['profile']),
    ...mapState('auth', ['hash', 'token']),
    isRouterLoaded: function() {
      if (this.$route.name !== null) return true

      return false
    },
    currentLayout: function() {
      const layout = this.$route.meta.layout || 'default'

      return layout + 'Layout'
    },
    canOpen () {
      if (this.profile.getPermissions().includes('ip_unlock')) {
        return true
      }
      if (this.config['access_ip']){
        return this.config['access_ip'].split(',').includes(this.ip)
      } else return true
    }
  },
  watch: {
    hash(value) {
      if (value) {
        api.defaults.headers.common['hash'] = this.hash
      }
      else this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style>
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
