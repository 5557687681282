<template>
  <div>
    <div class="d-flex">
      <v-btn v-if="canEdit && !value.action_id && !value.comment" icon @click="commentDialog = true">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn v-else-if="value.action_id" @click="goto" icon>
        <v-icon>mdi-page-next-outline</v-icon>
      </v-btn>
    </div>
    <comment-dialog v-model="commentDialog" :comment="value.comment" label="comment" @change="onCommentChange" :loading="commentLoading"/>
    <deposit-dialog v-model="depositDialog" :key="value.id" :deposit_id="value.action_id" :is_edit="isEdit"
      @current_change="$emit('change')" />
    <withdraw-dialog v-model="withdrawDialog" :withdraw_id="value.action_id" waiting="waiting" :is_edit="isEdit"
      @current_change="$emit('chnage')" />
  </div>
</template>

<script>
import CommentDialog from '../common/CommentDialog.vue'
import DepositDialog from '../deposit/DepositDialog.vue'
import WithdrawDialog from '../withdraw/WithdrawDialog.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    CommentDialog,
    DepositDialog,
    WithdrawDialog,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEdit: false,
      commentDialog: false,
      commentLoading: false,
      depositDialog: false,
      withdrawDialog: false,
    }
  },
  created() { },
  mounted() { },
  methods: {
    ...mapActions('sms', ['setComment']),
    async onCommentChange(comment){
      this.commentLoading = true;
      const change = await this.setComment({id: this.value.id, comment});
      console.log(change)
      this.commentLoading = false;
      this.commentDialog = false;
    },
    goto () {
      if (this.value.action === 'deposit') this.depositDialog = true;
      else this.withdrawDialog = true;
    }
  },
  computed: {
    ...mapGetters('user', ['profile']),
    canEdit() {
      if (!this.profile) return false
      return this.profile.chkAction(['sms_action_all', 'sms_action_update'])
    }
  }
}
</script>

<style>
</style>
