import { call } from '../plugins/axios'

export default {
  namespaced: true,
  state: () => ({
    userDeposits: [],
    userDepositsTotal: 0,
    deposits: [],
    total: 0,
    current: {}
  }),
  getters: {
    userDeposits: (state) => state.userDeposits,
    userDepositsTotal: (state) => state.userDepositsTotal,
    deposits: (state) => state.deposits,
    total: (state) => state.total,
    current: (state) => state.current,
  },
  mutations: {
    setMemberDeposits: (state, value) => state.userDeposits = value,
    setMemberDepositsTotal: (state, value) => state.userDepositsTotal = value,
    setDeposits: (state, value) => state.deposits = value,
    setDepositsTotal: (state, value) => state.total = value,
    setCurrent: (state, value) => state.current = value,
    updateCurrent: (state, value) => state.current = { ...state.current, ...value },
    updateDeposits: (state, value) => {
      const found = state.deposits.findIndex(d => d.id == value.id)
      if (found!=-1) state.deposits[found] = Object.assign(state.deposits[found], value)
    }
  },
  actions: {
    load: async ({ commit, dispatch }, search = {}) => {
      return await call('deposit/list', search)
        .then(({ data }) => {
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else {
            commit('setDeposits', data.deposits || [])
            commit('setDepositsTotal', data.total || 0)
          }
          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
          return { error: 500 }
        })
    },
    getDeposit: async ({ commit, dispatch }, id) => {
      return await call('deposit/get', id)
        .then(({data})=>{
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          else commit('setCurrent', data.deposit || {})

          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
          return { error: 500 }
        })
    },
    completeDeposit: async ({ commit, dispatch }, id) => {
      return await call('deposit/set-completed', id)
        .then(({data})=>{
          if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
          if (data.deposit) commit('setCurrent', data.deposit)
          if (data.change) commit('updateCurrent', data.change)
          return data
        })
        .catch((error) => {
          dispatch('app/showError', { error }, { root: true })
          return { error: 500 }
        })
    },
    cancelDeposit: async ({ commit, dispatch }, { id, comment }) => {
      return await call('deposit/set-cancel', { id, comment })
      .then(({data})=>{
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
        if (data.deposit)  commit('setCurrent', data.deposit)
        if (data.change) commit('updateCurrent', data.change)
        return data
      })
      .catch((error) => {
        dispatch('app/showError', { error }, { root: true })
        return { error: 500 }
      })
    },
    activeDeposit: async ({ commit, dispatch }, id) => {
      return await call('/deposit/set-active', id)
      .then(({data})=>{
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
        if (data.deposit) commit('setCurrent', data.deposit)
        if (data.change) commit('updateCurrent', data.change)

        return data
      })
      .catch((error) => {
        dispatch('app/showError', { error }, { root: true })
        return { error: 500 }
      })
    },
    canUploadDeposit: async ({ commit, dispatch }, id) => {
      return await call('/deposit/set-can-upload', id)
      .then(({data})=>{
        if (data.error) dispatch('app/showError', { error: { message: data.message } }, { root: true })
        if (data.deposit) commit('setCurrent', data.deposit)
        if (data.change) commit('updateCurrent', data.change)

        return data
      })
      .catch((error) => {
        dispatch('app/showError', { error }, { root: true })
        return { error: 500 }
      })
    },
    clearDeposits: ({ commit }) => {
      commit('setDeposits', [])
      commit('setDepositsTotal', 0)
    },
    clearMemberDeposits: ({ commit }) => {
      commit('setMemberDeposits', [])
      commit('setMemberDepositsTotal', 0)
    },
    clearCurrent: ({ commit }) => {
      commit('setCurrent', {})
    }
  }
}
