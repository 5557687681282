<template>
  <div>
    <div class="d-flex">
      <v-btn icon @click="openDialog(false)">
        <v-icon>mdi-eye</v-icon>
      </v-btn>
      <v-btn v-if="canEdit" icon @click="openDialog(true)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </div>
     <v-dialog
        v-model="dialog"
        width="500px"
      >
        <coupon-form :edit="isEdit"/>
      </v-dialog>
  </div>

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CouponForm from "../../pages/coupons/CouponForm.vue";
export default {
  components: {
    CouponForm,
  },
  props: {
    value: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      dialog: false,
      isEdit: false,
    }
  },
  created() { },
  mounted() { },

  methods: {
    ...mapActions('coupon', ['getCoupons', 'setCurrentCoupon']),
    openDialog(isEdit) {
      this.setCurrentCoupon(this.value)
      this.isEdit = isEdit
      console.log(this.isEdit)
      this.dialog = true
    },
  },

  computed: {
    ...mapGetters('user', ['profile']),
    canEdit() {
      if (!this.profile) return false
      return this.profile.chkAction([
        'coupon_action_all',
        'coupon_action_update',
      ])
    },
  }
}
</script>

<style>
</style>
